import * as AuthService from '../../../services/auth.service';
import Input from '../../../components/Forms/Input';
import NormalButton from '../../../components/Buttons/NormalButton';
import React from 'react';
import SHA1 from '../../../services/crypto.service';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getPasswordWeakness, validatePassword } from '../../../utils/useValidation';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  passwordResetPage: {
    marginTop: '55px',
    marginBottom: '55px',
  },
  passwordResetWrapper: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  passwordResetCard: {
    maxWidth: 'calc(400px - 96px)',
    width: '100%',
    padding: '24px 48px 32px 48px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    '& + div': {
      marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '24px',
      padding: '16px 24px 24px 24px',
      '& + div': {
        marginLeft: '0px',
      },
    },
  },
  customLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '24px',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
      textDecoration: 'underline',
      fontSize: '14px',
      cursor: 'pointer',
    },
  },
  passwordResetCardTitle: {
    textAlign: 'center',
    marginTop: '16px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  passwordResetCardForm: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > button': {
      marginTop: '16px',
      width: '100%',
    },
  },
  spinner: {
    display: 'flex',
    marginRight: '5px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  infoBar: {
    width: '100%',
    maxWidth: 'calc(100% - 48px)',
    padding: '16px 24px',
    backgroundColor: theme.colors.grey[250],
    color: theme.colors.primaryBlue[500],
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    fontSize: '14px',
  },
  failedBar: {
    width: '100%',
    maxWidth: 'calc(100% - 48px)',
    padding: '16px 24px',
    backgroundColor: '#FFF1F3',
    color: '#EE576C',
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    fontSize: '14px',
  },
  error: {
    color: '#EE576C',
    fontSize: '14px',
    marginTop: '5px',
  }, 
}));

const ResetPassword: React.FunctionComponent = () => {
  
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector((state: any) => state.user);
  const { token }: any = useParams();
   
  const [state, setState] = useStates({
    isChanged: false,
    isResetFailed: false,
    isInvalidLink: false,
    newPassword: '',
    confirmPassword: '',
  });
  
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if(state.newPassword && state.confirmPassword) {
      if(validatePassword(state.newPassword)) {
        if(state.newPassword === state.confirmPassword) {
          const encrypt = SHA1(state.newPassword);
          AuthService && AuthService.resetPassword(token, encrypt).then((result: any) => {
            if(result) {
              if(result.status === 201) {
                setState("isChanged", true);
                if(userData.userStatus !== "loggedIn") {
                  setTimeout(function() {
                    navigate('/auth/login');
                  }, 1500);  
                }
              }
            } else {
              setState("isResetFailed", true);
              setState("isChanged", false);
            }
          }).catch((error: any) => {
            if(error.response.status === 401) {
              setState("isInvalidLink", true);
              setState("isChanged", true);
            } else {
              setState("isResetFailed", true);
              setState("isChanged", false);
            }
         });
        }
      } else {
        const weakness = getPasswordWeakness(state.newPassword);
        if(weakness[0] === "length") {
          createNotification(t('password_required_length'), 'error');
        } else if(weakness[0] === "lowercase") {
          createNotification(t('password_required_lowercase'), 'error');
        } else if(weakness[0] === "uppercase") {
          createNotification(t('password_required_uppercase'), 'error');
        } else if(weakness[0] === "number") {
          createNotification(t('password_required_number'), 'error');
        }
      }
    } else {
      createNotification(t('please_fill_fields') + '!!!', 'error');
    }
  };
  
  const handleInput = (name: any, value: any) => {
    setState(name, value);
  };
  
  const handleTryAgain = () => {
    window.location.reload();
  };

  const handleLogin = () => {
    navigate('/auth/login');
  };
  
  return (
    <div className={classes.passwordResetPage}>
      <div className={classes.passwordResetWrapper}>
        <div className={classes.passwordResetCard}>
          {
            (state.isChanged && !state.isResetFailed && !state.isInvalidLink) ? (
              <div className={classes.infoBar} data-clarity-unmask="true">{userData.userStatus === "loggedIn" ? t("password_changed_redirect") : t("password_changed")}</div>
            ) : null
          }
          {
            state.isResetFailed ? (
              <div className={classes.failedBar} data-clarity-unmask="true">{t("password_failed")}</div>
            ) : null
          }
          {
            state.isInvalidLink ? (
              <div className={classes.failedBar} data-clarity-unmask="true">{t("password_already_changed")}</div>
            ) : null
          }
          {
            !state.isChanged ? (
              <div className={classes.passwordResetCardTitle} data-clarity-unmask="true">
                {t('forgotten_password')}
              </div>
            ) : null
          }
          {
            !state.isChanged ? (
              <form className={classes.passwordResetCardForm} onSubmit={handleSubmit}>
                <Input useForm={false} placeholder={t('password_new')} type="password" label={t('password_new') + '*'} name="newPassword" onChange={handleInput} tabIndex="1" autoFocus={true} autoComplete={false}/>
                <Input useForm={false} placeholder={t('password_confirm')} type="password" label={t('password_confirm') + '*'} name="confirmPassword" onChange={handleInput} tabIndex="3" autoComplete={false}/>
                <NormalButton type="submit" buttonType="primary" tabIndex="5" dataClarityUnmask='true'>
                  {t("save")}
                </NormalButton>
              </form>
            ) : null
          }
          {
            state.isResetFailed ? (
              <div className={classes.customLink}>
                <span onClick={handleTryAgain}>{t('try_again')}</span>
              </div>
            ) : null
          }
          {
            state.isInvalidLink ? (
              <div className={classes.customLink}>
                <span onClick={handleLogin}>{t('return_login')}</span>
              </div>
            ) : null
          }
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;