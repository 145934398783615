import * as AuthService from '../../../services/auth.service';
import Input from '../../../components/Forms/Input';
import NormalButton from '../../../components/Buttons/NormalButton';
import React from 'react';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  passwordResetPage: {
    marginTop: '55px',
    marginBottom: '55px',
  },
  passwordResetWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  passwordResetCard: {
    maxWidth: 'calc(400px - 96px)',
    width: '100%',
    padding: '24px 48px 32px 48px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    '& + div': {
      marginLeft: '20px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '24px',
      padding: '16px 24px 24px 24px',
      '& + div': {
        marginLeft: '0px',
      },
    },  
  },
  passwordResetCardTitle: {
    textAlign: 'center',
    marginTop: '16px',
    fontWeight: 'bold',
    fontSize: '16px',
  },
  passwordResetCardDescription: {
    marginTop: '14px',
    fontSize: '14px',
    marginBottom: '0px',
  },
  passwordResetCardForm: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center', 
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& > button': {
      width: '100%',
      fontSize: '14px',
      '& svg': {
        width: '20px',
        height: '20px',
        marginRight: '5px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      order: '1',
      justifyContent: 'center',
    }, 
  },
  passwordReset: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '24px',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
      textDecoration: 'underline',
      fontSize: '14px',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      order: '2',
      justifyContent: 'center',
      marginTop: '24px',
    }, 
  },
  spinner: {
    display: 'flex',
    marginRight: '5px',
    '& svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  infoBar: {
    width: '100%',
    maxWidth: 'calc(100% - 48px)',
    padding: '16px 24px',
    backgroundColor: theme.colors.grey[250],
    color: theme.colors.primaryBlue[500],
    borderRadius: '24px',
    boxShadow: "0px 3px 20px rgba(0,0,0,0.08)",
    fontSize: '14px',
  }, 
}));

const ResetPassword: React.FunctionComponent = () => {
  
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [state, setState] = useStates({
    isLoading: false,
    isSent: false,
    email: null,
  });
  
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const redirectUri = window.location.origin + '/auth/reset-password';
    if (state.email) {
      setState("isLoading", true);
      AuthService && AuthService.resetPasswordRequest(state.email, redirectUri).then(() => {
        setState("isSent", true);
        setState("isLoading", false);
      });
    } else {
      createNotification(t("please_fill_fields"),"error");
    }
  };
  
  const onInputChange = (name: any, value: string) => {
    setState(name, value);
  };
  
  const handleLogin = () => {
    navigate('/auth/login');
  }
  
  return (
    <div className={classes.passwordResetPage}>
      <div className={classes.passwordResetWrapper}>
        <div className={classes.passwordResetCard}>
          {
            state.isSent ? (
              <div className={classes.infoBar} data-clarity-unmask="true">{t("password_reset_accepted")}</div>
            ) : null
          }
          <div className={classes.passwordResetCardTitle} data-clarity-unmask="true">
            {t('forgotten_password')}
          </div>
          <p className={classes.passwordResetCardDescription} data-clarity-unmask="true">
            {t('forgot_password_info')}
          </p>
          <form className={classes.passwordResetCardForm} onSubmit={handleSubmit} >
            <Input useForm={false} placeholder={t('email')} type="text" label={t('email') + '*'} name="email" onChange={onInputChange} disabled={state.isSent || state.isLoading} tabIndex="1" autoFocus={true} autoComplete={false}/>
            <div className={classes.footer}>
              <div className={classes.buttonWrapper}>
                <NormalButton type="submit" buttonType={state.isSent ? "disabled" : "primary"} disabled={state.isSent} tabIndex="2" dataClarityUnmask="true">
                  {t("submit")}
                </NormalButton>
              </div>
              <div className={classes.passwordReset}>
                <span onClick={handleLogin}>{t('login')}</span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;