import htmlParse from 'html-react-parser';
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useTranslation } from 'react-i18next';
import SVG from 'src/components/Images/SvgRenderer';
import Sidebar from '../Sidebar';

interface Props {
  isInCenter?: any;
  useBackdrop?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: (props: Props) => {
      if(props.isInCenter) return 'absolute';
      else return '';
    },
    top: (props: Props) => {
      if(props.isInCenter) return '0';
      else return '';
    },
    left: (props: Props) => {
      if(props.isInCenter) return '0';
      else return '';
    },
    right: (props: Props) => {
      if(props.isInCenter) return '0';
      else return '';
    },
    height: (props: Props) => {
      if(props.isInCenter) return '100%';
      else return '';
    },
    maxWidth: (props: Props) => {
      if(props.isInCenter) return '100vw';
      else return '';
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: (props: Props) => {
      if(props.useBackdrop) return 'rgba(0,0,0,0.2)';
      else return 'transparent';
    },
    backdropFilter: (props: Props) => {
      if(props.useBackdrop) return 'saturate(150%) blur(2px)';
      else return '';
    },
    zIndex: (props: Props) => {
      if(props.isInCenter) return theme.zIndex.banner;
      else return '';
    },
  },
  subscriptionBanner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.colors.white,
    padding: '32px 40px',
    borderRadius: '10px',
    width: '600px',
    maxWidth: 'calc(100% - 80px)',
    gap: '10px',
    boxShadow: theme.shadows[2],
    '& > p': {
      fontSize: '14px',
      '& > b': {
        color: theme.colors.primaryBlue[300],
        fontWeight: 'bold',
      },
      '& > span': {
        color: theme.colors.primaryBlue[500],
        fontWeight: 'bold',
      },
    },
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize: '36px',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
    },
    '& > svg': {
      color: theme.colors.yellow[500],
      width: '32px',
      height: '32px',
    },
  },
  subTitle: {
    fontSize: '16px',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
    },
  },
  service: {
    fontSize: '24px',
    marginTop: '24px',
  },
}));

type SubscriptionBannerType = {
  service: any;
  isInCenter?: any;
  useBackdrop?: any;
};

const SubscriptionBanner: React.FunctionComponent<SubscriptionBannerType> = ({service, isInCenter, useBackdrop = true}) => {

  const { t } = useTranslation();
  const classes = useStyles({
    isInCenter: isInCenter,
    useBackdrop: useBackdrop,
  });
  
  return (
    <div className={classes.root}>
      <div className={classes.subscriptionBanner}>
        <span className={classes.title}>
          {t(service)} <SVG src="lock"/>
        </span>
        <span className={classes.subTitle}>
          {htmlParse(t('function_limited_subscription', {section: `<b>${t(service)}</b>`, license_premium: `<span>${t('license_premium')}</span>`}))}
        </span>
        <p>
          {htmlParse(t(`function_limited_${service}`, {license_start: `<b>${t('license_start')}</b>`, license_premium: `<span>${t('license_premium')}</span>`}))}
        </p>
      </div>
      {
        (isInCenter && !useBackdrop) ? (
          <Sidebar/>
        ) : null
      }
    </div>
  );
}

export default SubscriptionBanner;