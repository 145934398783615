import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import DataTable from 'src/components/Layouts/DataTable';
import DateFormat from 'src/utils/dateFormat';
import htmlParse from 'html-react-parser';
import moment from 'src/utils/moment';
import React, { memo, useCallback, useRef } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { ContextMenu } from '@firefox-devtools/react-contextmenu';
import { ContextMenuItem, ContextMenuTrigger } from 'src/utils/useContextMenu';
import { createUseStyles } from 'react-jss';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { getUserRole } from 'src/utils/useUser';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { setChildDetailModal } from 'src/store/actions/modals.actions';
import { setFilterParams } from 'src/store/actions/filters.actions';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useNavigate } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  child: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    cursor: 'pointer',
    height: '100%',
  },
  childImage: {
    width: '24px',
    height: '24px',
    minWidth: '24px',
    minHeight: '24px',
    maxWidth: '24px',
    maxHeight: '24px',
    backgroundColor: theme.colors.white,
    borderRadius: '100%',
  },
  nickname: {
    display: 'block',
    maxWidth: '150px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: '500',
  },
  classList: {
    display: 'flex',
    maxWidth: '400px',
    padding: '10px 0',
    overflow: 'auto',
    gap: '8px',
  },
  smallButton: {
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    '& > svg': {
      color: '#5AB8FF',
      width: '24px !important',
      height: '24px !important',
      transition: 'color 0.25s',
    },
    '&:hover':{
      '& > svg': {
        color: theme.colors.primaryBlue[500],
      },
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
    height: '100%',
    overflow: 'auto',
    maxWidth: '100%',
  },
}));

type ItemsType = {
  childrenID: any;
};

const ChildrenTable: React.FunctionComponent<ItemsType> = ({ childrenID }) => {

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dataData = useAppSelector((state: any) => state.data);
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);

  const contextMenuTriggerRef: any = useRef(null);

  const [state, setState] = useStates({
    contextMenuChildID: null,
  });

  const schoolSettings = userData.schoolSettings;

  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const getClassData = (classID: any) => {
    return dataData.classes.filter((item: any) => item.classID === classID).length === 0 ? [] : dataData.classes.find((item: any) => item.classID === classID);
  };

  const getSchoolData = (schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  };
  
  const contextMenuChildData = state.contextMenuChildID ? getChildData(state.contextMenuChildID) : {};
  const contextMenuChildIsSchoolSubstitutionsEnabled = (state.contextMenuChildID && contextMenuChildData) ? getSchoolSettings(contextMenuChildData.schoolID, 'modules', schoolSettings).substitutions ? getSchoolSettings(contextMenuChildData.schoolID, 'modules', schoolSettings).substitutions.enable : false : false;

  const handleChildDetail = useCallback((childID: any) => {
    const settings = {
      isOpen: true,
      childID: childID,
    };
    dispatch(setChildDetailModal(settings));
  }, [dispatch]);

  const handleEditLink = (childID: any) => {
    const childData = getChildData(childID);
    window.open(childData.editLink); 
  };
  
  const handleClickExcuseNotes = (childID: any) => {
    const childData = getChildData(childID);
    navigate('/excuse-notes');
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleClickSubstitutions = (childID: any) => {
    const childData = getChildData(childID);
    navigate('/substitutions');
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleClickFinance = (childID: any) => {
    const childData = getChildData(childID);
    navigate('/finance');
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };

  const handleClickPosts = (childID: any) => {
    const childData = getChildData(childID);
    navigate('/timeline');
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
      dispatch(setIsFiltersVisible(true));
    }, 100);
  };
  
  const handleClickCalendar = (childID: any) => {
    const childData = getChildData(childID);
    navigate('/calendar');
    dispatch(setFilterParams({childID: [childData.childID]}));
    setTimeout(() => {
      dispatch(setFilterParams({childID: [childData.childID]}));
    }, 100);
  };

  const handleRowContextMenu = (e: any) => {
    e.preventDefault();
    if(!e.currentTarget) {
      return;
    }
    const rowId = Number((e.currentTarget as HTMLDivElement).getAttribute('data-id'));
    const record = data.find((row: any) => row.id === rowId);
    if(!record) {
      return;
    }
    setState("contextMenuChildID", record.id);
    contextMenuTriggerRef.current.handleContextClick(e);
  };

  const handleResetContextMenu = () => {
    setState("contextMenuChildID", null);
  };

  const NicknameCell = memo(
    ({ row, handleChildDetail }: { row: any; handleChildDetail: (id: any) => void }) => (
      <div onClick={() => handleChildDetail(row.id)} className={classes.child}>
        <AuthenticatedImage
          className={classes.childImage}
          thumbLink={row.photo.thumbLink}
          fullsizeLink={row.photo.fullsizeLink}
          preferQuality="thumb"
          ignoreInView={true}
          ignoreIsLoaded={true}
        />
        <span className={classes.nickname}>{row.nickname}</span>
      </div>
    ),
    (prevProps, nextProps) => {
      return prevProps.row.nickname === nextProps.row.nickname && prevProps.row.photo.thumbLink === nextProps.row.photo.thumbLink;
    }
  );
  
  const columns: any = [
    { field: 'nickname', headerName: t('children_nickname'), renderCell: (params: any) => (
      <NicknameCell 
        row={params.row} 
        handleChildDetail={handleChildDetail} 
      />
    ), display: 'flex' },
    { field: 'firstname', headerName: t('children_firstname') },
    { field: 'surname', headerName: t('children_surname'), },
    { field: 'gdpr', headerName: t('children_gdpr'), valueGetter: (_: any, row: any) => `${row.gdpr ? t('yes') : t('no')}`, sortable: false },
    { field: 'birthname', headerName: t('children_birthday'), valueGetter: (_: any, row: any) => `${DateFormat(row.birthday, "default", languageData, t) !== "Invalid Date" ? DateFormat(row.birthday, "default", languageData, t) : ''}`, },
    { field: 'age', headerName: t('children_age'), valueGetter: (_: any, row: any) => `${row.age ? row.age : 0} ${row.age === 1 ? (t('year')).toLowerCase() : (row.age === 2 || row.age === 3 || row.age === 4) ? (t('years')).toLowerCase() : (t('years_more')).toLowerCase()}`, },
    { field: 'classesID', headerName: t('children_class'), valueGetter: (_: any, row: any) => `${row.classesID.filter((classID: any) => dataData.classes.filter((classData: any) => classData.classID === classID).length !== 0).map((classID: any) => { return getClassData(classID).name; }).join(", ")}`, sortable: false, },
    dataData.schools.length > 1 ? { field: 'schoolID', headerName: t('children_school'), valueGetter: (_: any, row: any) => `${htmlParse(getSchoolData(row.schoolID).name)}`, sortable: false, } : null,
    { field: 'action', type: 'actions', headerName: t('children_action'), sortable: false, filterable: false, display: 'flex', hideable: false, getActions: (params: any) => [
      params.row.editLink && params.row.editLink !== "" ? (
        <GridActionsCellItem
          icon={<SVG src="edit"/>}
          label={t('edit_child')}
          onClick={() => handleEditLink(params.row.id)}
        />
      ) : null,
      (
        <GridActionsCellItem
          icon={<SVG src="info-circle-outlined"/>}
          label={t('detail_child')}
          onClick={() => handleChildDetail(params.row.id)}
          showInMenu={params.row.editLink && params.row.editLink !== ""}
        />
      ),
      (!params.row.isArchived && params.row.isInAnyActiveClass) ? (
        <GridActionsCellItem
          icon={<SVG src="calendar-excuse-notes"/>}
          label={t('excuse_notes')}
          onClick={() => handleClickExcuseNotes(params.row.id)}
          showInMenu
        />
      ) : null,
      (!params.row.attendanceProgram && params.row.attendanceProgram !== null && !params.row.isArchived && params.row.isInAnyActiveClass && params.row.isSchoolSubstitutionsEnabled) ? (
        <GridActionsCellItem
          icon={<SVG src="calendar-substitutions"/>}
          label={t('substitutions')}
          onClick={() => handleClickSubstitutions(params.row.id)}
          showInMenu
        />
      ) : null,
      (getUserRole(userData.userObject.roleType) === "parent" && params.row.payment && params.row.payment.totalCount > 0) ? (
        <GridActionsCellItem
          icon={<SVG src="finance"/>}
          label={t('finance')}
          onClick={() => handleClickFinance(params.row.id)}
          showInMenu
        />
      ) : null,
      (
        <GridActionsCellItem
          icon={<SVG src="timeline"/>}
          label={t('posts')}
          onClick={() => handleClickPosts(params.row.id)}
          showInMenu
        />
      ),
      (!params.row.isArchived && params.row.isInAnyActiveClass) ? (
        <GridActionsCellItem
          icon={<SVG src="calendar"/>}
          label={t('calendar')}
          onClick={() => handleClickCalendar(params.row.id)}
          showInMenu
        />
      ) : null,
    ].filter((item: any) => item !== null) },
  ];

  const data = childrenID.map((childID: any) => {

    const childData = getChildData(childID);
    const isSchoolSubstitutionsEnabled = getSchoolSettings(childData.schoolID, 'modules', schoolSettings).substitutions ? getSchoolSettings(childData.schoolID, 'modules', schoolSettings).substitutions.enable : false;
    
    return {
      id: childData.childID,
      nickname: childData.nickname,
      firstname: childData.firstname,
      surname: childData.surname,
      gdpr: childData.hasGDPR,
      age: childData.age,
      birthday: moment(childData.birthday),
      classesID: childData.classesID,
      schoolID: childData.schoolID,
      photo: childData.photo,
      editLink: childData.editLink,
      isArchived: childData.isArchived,
      isInAnyActiveClass: childData.isInAnyActiveClass,
      attendanceProgram: childData.attendanceProgram,
      isSchoolSubstitutionsEnabled: isSchoolSubstitutionsEnabled,
      payment: childData.payment,
    };
  });

  return (
    <>
      <DataTable columns={columns} data={data} page={0} defaultSorting={[{ field: 'surname', sort: 'asc' }]} visibleRows={[8, 16]} onRowContextMenu={handleRowContextMenu} isColumnFilterable={true} isColumnMenuEnabled={true} isColumnSortable={true} isColumnSelectable={true} isFooterEnabled={true} isFooterPaginationEnabled={true} noResultsText={t('no_children_found')}/>
      <ContextMenuTrigger id='childContextMenu' customRef={contextMenuTriggerRef}/>
      <ContextMenu id='childContextMenu' preventHideOnScroll={false} hideOnLeave={true} onHide={handleResetContextMenu}>
        <ContextMenuItem onClick={() => handleChildDetail(state.contextMenuChildID)}>
          <SVG src="info-circle-outlined"/>
          {t('detail_child')}
        </ContextMenuItem>
        {
          (contextMenuChildData.editLink && contextMenuChildData.editLink !== "") ? (
            <ContextMenuItem onClick={() => handleEditLink(state.contextMenuChildID)}>
              <SVG src="pencil"/>
              {t('edit_child')}
            </ContextMenuItem>
          ) : null
        }
        {
          (!contextMenuChildData.isArchived && contextMenuChildData.isInAnyActiveClass) ? (
            <ContextMenuItem onClick={() => handleClickExcuseNotes(state.contextMenuChildID)}>
              <SVG src="calendar-excuse-notes"/>
              {t('excuse_notes')}
            </ContextMenuItem>
          ) : null
        }
        {
          (contextMenuChildData.attendanceProgram && contextMenuChildData.attendanceProgram !== null && !contextMenuChildData.isArchived && contextMenuChildData.isInAnyActiveClass && contextMenuChildIsSchoolSubstitutionsEnabled) ? (
            <ContextMenuItem onClick={() => handleClickSubstitutions(state.contextMenuChildID)}>
              <SVG src="calendar-substitutions"/>
              {t('substitutions')}
            </ContextMenuItem>
          ) : null
        }
        {
          (getUserRole(userData.userObject.roleType) === "parent" && contextMenuChildData.payment && contextMenuChildData.payment.totalCount > 0) ? (
            <ContextMenuItem onClick={() => handleClickFinance(state.contextMenuChildID)}>
              <SVG src="finance"/>
              {t('finance')}
              <small>({contextMenuChildData.payment.totalCountUnpaid !== 0 ? (<em>{contextMenuChildData.payment.totalCountUnpaid}</em>) : null})</small>
            </ContextMenuItem>
          ) : null 
        }
        <ContextMenuItem onClick={() => handleClickPosts(state.contextMenuChildID)}>
          <SVG src="timeline"/>
          {t('posts')}
        </ContextMenuItem>
        {
          (!contextMenuChildData.isArchived && contextMenuChildData.isInAnyActiveClass) ? (
            <ContextMenuItem onClick={() => handleClickCalendar(state.contextMenuChildID)}>
              <SVG src="calendar"/>
              {t('calendar')}
            </ContextMenuItem>
          ) : null
        }
      </ContextMenu>
    </>
  );
}

export default ChildrenTable;