import CloseButton from 'src/components/Buttons/CloseButton';
import htmlParse from 'html-react-parser';
import Input from 'src/components/Forms/Input';
import Label from 'src/components/Forms/Label';
import Modal from '../../../utils/modal';
import NormalButton from 'src/components/Buttons/NormalButton';
import React from 'react';
import Switch from 'src/components/Forms/Switch';
import theme from '../../../ui/theme';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserRole } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { setPressReportTemplateHandleModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    margin: "20px 0 0px 0",
    position: 'relative',
    maxHeight: 'calc(100vh - 260px)',
    overflow: 'auto',
    height: '100%',
  },
  footer: {
    display: "flex",
    gap: '15px',
    padding: "20px",
    justifyContent: "flex-end",
    flexWrap: 'wrap',
    width: 'calc(100% - 40px)',
    '& > button': {
      width: 'fit-content',
    },
  },
  inputWrapper: {
    width: '80%',
    '& label': {
      marginTop: '0',
    },
  },
  columns: {
    width: '80%',
    '& label': {
      marginTop: '0',
    },
  },
  columnsInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px',
    '& > span': {
      fontWeight: '500',
      fontSize: '15px',
    },
    '& > p': {
      fontSize: '13px',
    },
  },
  switch: {
    width: '100%',
  },
});


const PressReportTemplateHandleModal: React.FunctionComponent = () => {

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const exportService = useAppSelector((state: any) => state.services).exportService;

  const templateID = modalsData.pressReportTemplateHandleModal.templateID;
  const schoolID = modalsData.pressReportTemplateHandleModal.schoolID;
  const allDefaultColumns = modalsData.pressReportTemplateHandleModal.allColumns;
  const columns = modalsData.pressReportTemplateHandleModal.columns;
  const title = modalsData.pressReportTemplateHandleModal.title;
  const name = modalsData.pressReportTemplateHandleModal.name;
  const isPublic = modalsData.pressReportTemplateHandleModal.public;
  const isEdit = templateID !== null;

  const allColumns = allDefaultColumns ? allDefaultColumns.map((item: any) => item.columns.map((subItem: any) => { return {...subItem, groupName: item.groupName}; }).flat()).flat() : [];
 
  const [state, setState] = useStates({
    title: title,
    name: name ? name : "",
    public: isPublic ? isPublic : false,
  });

  const onCloseModal = () => {
    const settings = {
      isOpen: false,
      templateID: null,
      columns: [],
      title: null,
      public: null,
      name: null,
    };
    dispatch(setPressReportTemplateHandleModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleInputChange = (name: any, value: any) => {
    setState(name, value);
  };
  
  const handleCreate = (e: any) => {
    e.stopPropagation();
    const updatedColumns = columns.map((item: any) => { 
      if(!item.key || (item.key && item.key.includes("webAppCustomColumn"))) {
        return { name: item.name };
      } else {
        return { key: item.key };
      }
    });
    const payload = {
      title: state.title,
      name: state.name,
      type: "children",
      schoolID: schoolID,
      columns: updatedColumns,
      public: state.public
    };
    exportService && exportService.exportReportCreateTemplate(payload).then((result: any) => {
      if(result.status === 200) {
        createNotification(t("press_report_template_created"), "success");
        setState("canClick", true);
        onCloseModal();
      } else {
        createNotification(t("press_report_template_not_created"), "error");  
        setState("canClick", true);
      }
    }).catch(() => {
      createNotification(t("press_report_template_not_created"), "error");
      setState("canClick", true);
    });
  };

  const handleEdit = (e: any) => {
    e.stopPropagation();
    const updatedColumns = columns.map((item: any) => { 
      if(!item.key || (item.key && item.key.includes("webAppCustomColumn"))) {
        return { name: item.name };
      } else {
        return { key: item.key };
      }
    });
    const payload = {
      title: state.title,
      name: state.name,
      type: "children",
      schoolID: schoolID,
      columns: updatedColumns,
      public: state.public
    };
    exportService && exportService.exportReportEditTemplate(templateID, payload).then((result: any) => {
      if(result.status === 200) {
        createNotification(t("press_report_template_updated"), "success");
        setState("canClick", true);
        onCloseModal();
      } else {
        createNotification(t("press_report_template_not_updated"), "error");  
        setState("canClick", true);
      }
    }).catch(() => {
      createNotification(t("press_report_template_not_created"), "error");
      setState("canClick", true);
    });
  };

  const canCreate = state.title !== "" && state.name !== "";

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      <div className={classes.root} data-cy={isCypress() ? "pressReportTemplateHandleModal" : null}>
        <div className={classes.header}>
          <div className={classes.wrapper}>
            <p data-clarity-unmask="true">
              {isEdit ? t('press_report_edit_template') : t('press_report_create_template')}
            </p>
          </div>
          <CloseButton onClick={handleClose} dataCy="timesButton"/>
        </div>
        <div className={classes.body}>
          <div className={classes.inputWrapper}>
            <Input label={t('press_report_name')} placeholder={t('press_report_name')} name="name" value={state.name} onChange={handleInputChange} autoFocus/>
          </div>
          <div className={classes.inputWrapper}>
            <Input label={t('press_report_title')} placeholder={t('press_report_title')} name="title" value={state.title} onChange={handleInputChange}/>
          </div>
          <div className={classes.columns}>
            <Label>{t('press_report_columns')}</Label>
            <div className={classes.columnsInfo}>
              {
                htmlParse(Object.entries(columns.reduce((acc: any, item: any) => {
                  const groupName = !item.key ? t('press_report_custom_columns') : allColumns.find((subItem: any) => subItem.key === item.key)?.groupName || item.groupName;
                  const columnName = allColumns.find((subItem: any) => subItem.key === item.key)?.name || item.name || "";
                  if(!acc[groupName]) { acc[groupName] = []; }
                  acc[groupName].push(columnName);
                  return acc;
                }, {})).map(([groupName, columnsList]: any) => {
                  const columnsStr = columnsList.join(", ");
                  return `<span>${groupName}</span><p>${columnsStr}</p>`;
                }).join(""))
              }
            </div>
          </div>
          {
            getUserRole(userData.userObject.roleType) === "director" ? (
              <div className={classes.inputWrapper}>
                <Switch className={classes.switch} labelTop={t('press_report_public_template')} label={Boolean(state.public) ? t('yes') : t('no')} name="public" checked={Boolean(state.public)} onChange={(name: any, val: any) => handleInputChange(name, val)}/>
              </div>
            ) : null
          }
        </div>
        <div className={classes.footer}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="closeButton">
            {t('cancel')}
          </NormalButton>
          {
            isEdit ? (
              <NormalButton buttonType="primary" onClick={handleEdit} disabled={!canCreate} dataCy="editButton">
                {t('save')}
              </NormalButton>
            ) : (
              <NormalButton buttonType="primary" onClick={handleCreate} disabled={!canCreate} dataCy="createButton">
                {t('create')}
              </NormalButton>
            )
          }
        </div>
      </div>
    </Modal>
  );
};

export default PressReportTemplateHandleModal;