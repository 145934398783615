import React from 'react';
import SchoolsSelect from 'src/components/Selects/SchoolsSelect';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from 'src/hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  schoolSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '16px',
    height: '100%',
  },
  schoolsSelect: {
    maxHeight: '100%',
    overflow: 'auto',
    borderRadius: '24px',
    height: '100%',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      '& > div:last-of-type': {
        flex: '1 1 auto',
      },
    },
  },
  customContainer: {
    backgroundColor: theme.colors.grey[100],
    maxHeight: '100%',
    overflow: 'auto',
  },
  customTopWrapper: {
    position: 'sticky',
    top: '-0px',
    zIndex: '2',
  },
}));

type SchoolSelectType = {
  onSelect: any;
};

const SchoolSelect: React.FunctionComponent<SchoolSelectType> = ({ onSelect }) => {

  const classes = useStyles();
  const dataData = useAppSelector((state: any) => state.data);

  const listSchools = dataData.schools;
  const listClasses = dataData.classes;
  const listChildren = dataData.children;

  const getSchools = () => {
    return listSchools.map((theSchool: any) => {
      return theSchool;
    }).filter((value: any) => value !== null);
  };

  const getChildren = () => {
    return listChildren.map((theChild: any) => {
      return theChild;
    }).filter((value: any) => value !== null);
  };

  const getClasses = () => {
    return listClasses.map((theClass: any) => {
      if(getChildren().filter((theChild: any) => theChild.classID.indexOf(theClass.classID) !== -1).length > 0) {
        return theClass;
      } else {
        return null;
      }
    }).filter((value: any) => value !== null);
  };

  const schoolsList = getSchools().map((theSchool: any) => {
    if(getClasses().filter((theClass: any) => theClass.schoolID === theSchool.schoolID).length > 0) {
      return theSchool;
    } else {
      return null;
    }
  }).filter((value: any) => value !== null);

  const handleChange = (_: any, value: any) => {
    onSelect(value.school.schoolID);
  };

  return (
    <div className={classes.schoolSelectWrapper}>
      <SchoolsSelect defaultSchools={schoolsList.map((item: any) => { return { schoolID: item.schoolID }; })} onClickSchool={handleChange} isAllowOnlyOneSchoolAtOnce={true} customClasses={{schoolsSelect: classes.schoolsSelect, container: classes.customContainer, topWrapper: classes.customTopWrapper}}/>
    </div>
  );
};

export default SchoolSelect;