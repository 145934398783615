import BlockPicker from '../../Forms/BlockPicker';
import ButtonGroup from '../../Forms/ButtonGroup';
import ChildrenInput from '../../Forms/ChildrenInput';
import ChildrenSelect from '../../Selects/ChildrenSelect';
import CloseButton from 'src/components/Buttons/CloseButton';
import config from '../../../constants/config';
import DatePicker from '../../DatePickers/Poll';
import Dropdown from '../../Forms/Dropdown';
import FileInput from '../../Forms/FileInput';
import IconButton from 'src/components/Buttons/IconButton';
import Input from '../../Forms/Input';
import Label from 'src/components/Forms/Label';
import LeavePageBlocker from '../../Layouts/LeavePageBlocker';
import MinimizeIcon from '@mui/icons-material/Minimize';
import Modal from '../../../utils/modal';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import PollInputGroup from '../../Forms/PollInputGroup';
import React, { useCallback, useRef } from 'react';
import RichTextEditor from '../../Forms/RichTextEditor';
import SVG from '../../Images/SvgRenderer';
import Switch from '../../Forms/Switch';
import theme from '../../../ui/theme';
import { addTimelinePost, deleteTimelinePost, updateTimelinePost } from '../../../store/actions/timeline.actions';
import { base64ToArrayBuffer, filterNotExistingValues, getFileType, getSchoolSettings, hasObjectAnyValue, isKey, onlyUnique } from 'src/utils/useFunctions';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getAppData, setAppData } from 'src/utils/useApp';
import { getUserSetting, saveUserSettings, updateUserSetting } from 'src/utils/useUser';
import { isCypress } from '../../../utils/useCypress';
import { setConfirmModal, setDraftsModal, setInfoModal, setPostCreateModal } from '../../../store/actions/modals.actions';
import { setUserSettings } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMediaQuery } from '@mui/material';
import { useState, useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isFullscreen: any;
  isMinimalized: any;
  bodyHeight: any;
}

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: (props: Props) => {
      if(props.isMinimalized) return '400px';
      else return '800px';
    },
    maxWidth: '90vw',
    overflow: "hidden",
    margin: "20px",
    maxHeight: 'calc(80% - 40px)',
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px 10px 20px",
    maxWidth: 'calc(100% - 40px)',
    cursor: (props: Props) => {
      if(props.isMinimalized) return 'pointer';
      else return '';
    },
    boxShadow: (props: Props) => {
      if(props.isMinimalized) return theme.shadows[2];
      else return '';
    },
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flex: '1 1 100%',
    maxWidth: 'calc(100% - 40px)',
  },
  defaultTitle: {
    color: theme.colors.black,
  },
  customTitle: {
    color: '#666666',
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    flex: '0 0 fit-content',
  },
  button: {
    padding: '10px',
    width: '40px',
    height: '40px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
    '&.smallIcon': {
      '& > svg': {
        transform: 'scale(0.8)',
      },
    },
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    height: (props: Props) => {
      if(props.bodyHeight) return props.bodyHeight + 'px';
      else return '100%';
    },
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'auto',
  },
  bodyWrapper: {
    width: (props: Props) => {
      if(props.isFullscreen) return '80%';
      else return '100%';
    },
    padding: (props: Props) => {
      if(props.isFullscreen) return '20px 0';
      else return '20px';
    },
    flexDirection: 'column',
    gap: '20px',
    height: 'calc(100% - 40px)',
    '&::after': {
      content: `''`,
      display: 'flex',
      width: '100%',
      height: '20px',
    },
  },
  buttonGroup: {
    alignItems: 'center',
  },
  footer: {
    display: "flex",
    flexDirection: 'column',
    gap: '5px',
    padding: "10px 20px",
    width: 'calc(100% - 40px)',
  },
  footerButtons: {
    display: "flex",
    gap: '15px',
    width: '100%',
    justifyContent: "flex-end",
    flexWrap: 'wrap',
    '& > button': {
      width: 'fit-content',
    },
  },
  activityTypePlaceholder: {
    display: 'flex',
    alignItems: 'center',
    color: theme.colors.grey[560],
    fontSize: '14px',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    '& > svg': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      color: 'inherit',
    },
  },
  activityTypeValue: {
    display: "flex",
    alignItems: "center",
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    '& svg': {
      width: "20px",
      height: "20px",
    },
    '& img': {
      width: "20px",
      height: "20px",
    },
    '& p': {
      marginLeft: "8px",
      fontSize: "14px",
      marginBottom: "0",
    }
  },
  switches: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '16px',
  },
  disabled: {
    color: theme.colors.grey[540],
    fontWeight: '400',
    marginLeft: 'auto',
  },
  deleteButton: {
    marginRight: 'auto',
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoDetail: {
    display: 'flex',
    gap: '4px',
    fontSize: '14px',
    alignItems: 'center',
    '& > svg': {
      width: '20px',
      height: '20px',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      '& > svg': {
        width: '18px',
        height: '18px',
      },
    },
  },
}));

const PostCreateModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const browserData = useAppSelector((state: any) => state.browser);
  const dataData = useAppSelector((state: any) => state.data);
  const modalsData = useAppSelector((state: any) => state.modals);
  const timelineData = useAppSelector((state: any) => state.timeline);
  const userData = useAppSelector((state: any) => state.user);
  const timelineService = useAppSelector((state: any) => state.services).timelineService;
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const defaultAnswers = [
    {
      limit: 0,
      answer: '',
    },
    {
      limit: 0,
      answer: '',
    }
  ];

  const modalRef: any = useRef(null);
  const headerRef: any = useRef(null);
  const footerRef: any = useRef(null);

  const isSaving = useRef(false);

  const modalPosition = "right-bottom";
  const activityTypes = configurationData.configuration.activityTypes.filter((item: any) => item.visibleInPostForm);
  const defaultPostType = modalsData.postCreateModal.postType;
  const postTitle = modalsData.postCreateModal.title;
  const isRepost = modalsData.postCreateModal.repost;
  const schoolSettings = userData.schoolSettings;

  const isDraftEnabled = getUserSetting(userData.userSettings, "addons", ["timeline", "timeline_post_autosave"]);
  const drafts = getUserSetting(userData.userSettings, "drafts", ["timeline"]);
  const [draft, setDraft, setWholeDraft] = useStates(null);
  const [delayDebounceFn, setDelayDebounceFn] = useState(null);

  const [state, setState] = useStates({
    updateRefs: false,
    bodyHeight: null,
    modalIsFullscreen: smallDevice ? true : false,
    modalIsMinimalized: smallDevice ? false : false,
    isDropdownOpen: false,
    isChildrenSelectOpen: false,
    isReady: false,
    isLoading: false,
    isFilesLoading: false,
    isSaved: false,
    isDialogOpen: false,
    lastFocused: null,
    isDraftSaving: false,
    isKeepDraftAfterUse: false,
    draftID: isDraftEnabled ? drafts.length + 1 : null,
    uniqueID: modalsData.postCreateModal.uniqueID,
    postID: modalsData.postCreateModal.postID,
    title: postTitle ? postTitle : '',
    description: "",
    files: [],
    postType: defaultPostType ? defaultPostType : 1,
    activityType: null,
    selectedChildren: [],
    answers: defaultAnswers,
    multipleAnswers: false,
    limitAnswers: false,
    timelimitPoll: false,
    pollLimit: null,
    modules: {
      reactions: true,
      comments: true,
    },
    initialTitle: "",
    initialDescription: "",
    initialFiles: [],
    initialActivityType: null,
    initialSelectedChildren: [],
    initialAnswers: defaultAnswers,
    initialMultipleAnswers: false,
    initialPollLimit: null,
    initialModules: {
      reactions: true,
      comments: true,
    },
  });

  const isDraftSaved = drafts.filter((item: any) => item.draftID === state.draftID).length === 1;

  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const refFiles = useRef(state.files);
  const selectedSchools = state.selectedChildren.map((item: any) => { return getChildData(item.childID).schoolID; }).filter(onlyUnique);
  const schoolsWithAvailableReactions = dataData.schoolsID.filter((school: any) => getSchoolSettings(school, "modules", schoolSettings).reaction ? (getSchoolSettings(school, "modules", schoolSettings).reaction.enable && getSchoolSettings(school, "modules", schoolSettings).reaction.enableFor.posts) : false);
  const schoolsWithEnabledReactions = selectedSchools.filter((school: any) => getSchoolSettings(school, "modules", schoolSettings).reaction ? (getSchoolSettings(school, "modules", schoolSettings).reaction.enable && getSchoolSettings(school, "modules", schoolSettings).reaction.enableFor.posts) : false);
  const isAvailableReactions = selectedSchools.length > 0 && selectedSchools.length === schoolsWithEnabledReactions.length;
  const schoolsWithAvailableComments = dataData.schoolsID.filter((school: any) => getSchoolSettings(school, "modules", schoolSettings).comment ? getSchoolSettings(school, "modules", schoolSettings).comment.enable : false);
  const schoolsWithEnabledComments = selectedSchools.filter((school: any) => getSchoolSettings(school, "modules", schoolSettings).comment ? getSchoolSettings(school, "modules", schoolSettings).comment.enable : false);
  const isAvailableComments = selectedSchools.length > 0 && selectedSchools.length === schoolsWithEnabledComments.length;

  const isEdit = state.uniqueID !== null || state.postID !== null;

  const isChangedPost = state.title !== state.initialTitle || state.description !== state.initialDescription || (JSON.stringify(state.files) !== JSON.stringify(state.initialFiles) && state.files.filter((fileData: any) => fileData.status !== "canceled").length > 0) || state.activityType !== state.initialActivityType || JSON.stringify(state.selectedChildren) !== JSON.stringify(state.initialSelectedChildren) || JSON.stringify(state.modules) !== JSON.stringify(state.initialModules);
  const isChangedPoll = state.title !== state.initialTitle || state.description !== state.initialDescription || (JSON.stringify(state.files) !== JSON.stringify(state.initialFiles) && state.files.filter((fileData: any) => fileData.status !== "canceled").length > 0) || state.activityType !== state.initialActivityType || JSON.stringify(state.selectedChildren) !== JSON.stringify(state.initialSelectedChildren) || JSON.stringify(state.modules) !== JSON.stringify(state.initialModules) || JSON.stringify(state.answers) !== JSON.stringify(state.initialAnswers) || state.multipleAnswers !== state.initialMultipleAnswers || (state.pollLimit !== state.initialPollLimit && (!state.timelimitPoll || (state.pollLimit !== null && moment(state.pollLimit).isAfter(moment(), 'minute'))));
  const isChanged = (state.isLoading || state.isFilesLoading) ? false : (state.postType === 2 ? isChangedPoll : isChangedPost);

  const isCreatablePost = state.title !== "" && state.activityType !== null && state.selectedChildren.length !== 0;
  const isCreatablePoll = state.title !== "" && state.activityType !== null && state.selectedChildren.length !== 0 && state.answers.filter((item: any) => item.answer !== "").length !== 0 && (!state.timelimitPoll || (state.pollLimit !== null && moment(state.pollLimit).isAfter(moment(), 'minute')));
  const isCreatable = (state.isLoading || state.isFilesLoading) ? false : (state.postType === 2 ? isCreatablePoll : isCreatablePost);

  const modalTitle = t(`${isEdit ? (isRepost ? 'repost' : 'edit') : 'create'}_${state.postType === 1 ? 'post' : state.postType === 2 ? 'poll' : 'post_video'}`);

  const onCloseModal = useCallback(() => {
    setAppData({savedPost: null});
    const settings = {
      isOpen: false,
      uniqueID: null,
      postID: null,
      postType: null,
      title: null,
      repost: false,
    };
    dispatch(setPostCreateModal(settings));
  }, [dispatch]);

  const handleClose = (e: any) => {
    e.stopPropagation();
    if(isDraftSaved) {
      onCloseModal();
      return;
    }
    if(isChanged || state.isLoading) {
      const settings = {
        isOpen: true,
        title: t('unsaved_changes'),
        content: isEdit ? t('unsaved_existing_post_warning') : t('unsaved_post_warning'),
        onAccept: onCloseModal,
        onDecline: null,
      };
      dispatch(setConfirmModal(settings));
    } else {
      onCloseModal();
    }
  };

  const handleFullscreen = () => {
    setState("modalIsFullscreen", !state.modalIsFullscreen);
  };

  const handleMinimalization = () => {
    handleSaveDraft();
    setState("modalIsMinimalized", !state.modalIsMinimalized);
  };

  const handleHeaderClick = () => {
    if(state.modalIsMinimalized) {
      setAppData({savedPost: null});
      setState("modalIsMinimalized", false);
    }
  };

  const handleInfoModal = () => {
    const settings = {
      isOpen: true,
      title: state.postType === 3 ? t('about_create_post_video') : (state.postType === 2 ? t('about_create_poll') : t('about_create_post')),
      type: 'createpost',
      postType: state.postType,
    };
    dispatch(setInfoModal(settings));
  };

  const handleChildrenSelect = () => {
    setState("isChildrenSelectOpen", true);
  };
  
  const fileAllowedTypes: any = {
    "attachment": config.UPLOAD_FORMATS_FILES,
    "image": config.UPLOAD_FORMATS_PHOTOS,
    "video": config.UPLOAD_FORMATS_VIDEOS, 
  };

  const postTypes: any = [
    {
      name: t('post'),
      icon: 'post',
      value: 1,
      dataCy: 'postButton',
    },
    {
      name: t('poll'),
      icon: 'poll',
      value: 2,
      dataCy: 'pollButton',
    },
    {
      name: t('post_video'),
      icon: 'video',
      value: 3,
      dataCy: 'postVideoButton',
    },
  ];

  const allowedFiles: any = {
    1: ["image", "attachment", "photocamera"],
    2: ["image", "attachment", "photocamera"],
    3: ["video", "videocamera", "screen"],
  };

  const handleTitle = (name: any, value: any) => {
    setState(name ? name : "title", value);
  };

  const handleDescription = (name: any, value: any) => {
    setState(name ? name : "description", value);
  };

  const handleFiles = (value: any) => {
    setState("files", value);
    refFiles.current = value;
  };

  const handleDropdownOpen = (value: any) => {
    setState("isDropdownOpen", value);
  };

  const handleSelectedChildren = (value: any) => {
    setState("selectedChildren", value);
    handleSetDraft("selectedChildren", value);
  };

  const handleActivityType = (value: any) => {
    if(value === state.activityType) {
      setState("activityType", null);
      handleSetDraft("activityType", null);
    } else {
      setState("activityType", value);
      handleSetDraft("activityType", value);
      setState("isDropdownOpen", false);
    }
  };

  const handlePostType = (value: any) => {
    if(value === 1 || value === 2) {
      setState("files", state.files.filter((fileData: any) => fileAllowedTypes["image"].indexOf(fileData.type) !== -1 || fileAllowedTypes["attachment"].indexOf(fileData.type) !== -1));
    } else if(value === 3) {
      setState("files", state.files.filter((fileData: any) => fileAllowedTypes["video"].indexOf(fileData.type) !== -1));
    }
    setState("postType", value);
    handleSetDraft("postType", value);
  };
  
  const handleSaveChildrenModal = (value: any) => {
    const newChildren = value.map((child: any) => ({childID: child.childID, classID: child.classID}));
    setState("selectedChildren", newChildren);  
    handleSetDraft("selectedChildren", newChildren);
  };

  const handleMultipleAnswers = () => {
    setState("multipleAnswers", !state.multipleAnswers);
    handleSetDraft("multipleAnswers", !state.multipleAnswers);
  };

  const handleLimitAnswers = () => {
    if(state.limitAnswers) {
      const newAnswers = state.answers.map((answer: any) => {
        return {...answer, limit: 0};
      });
      setState("answers", newAnswers);
      handleSetDraft("answers", newAnswers);
    }
    setState("limitAnswers", !state.limitAnswers);
    handleSetDraft("limitAnswers", !state.limitAnswers);
  };

  const handleTimelimitPoll = () => {
    if(state.timelimitPoll) {
      setState("pollLimit", null);
      handleSetDraft("pollLimit", null);
    }
    setState("timelimitPoll", !state.timelimitPoll);
    handleSetDraft("timelimitPoll", !state.timelimitPoll);
  };

  const handleModulesReactions = () => {
    setState("modules", { reactions: !state.modules.reactions, comments: state.modules.comments });
    handleSetDraft("modules", { reactions: !state.modules.reactions, comments: state.modules.comments });
  };

  const handleModulesComments = () => {
    setState("modules", { reactions: state.modules.reactions, comments: !state.modules.comments });
    handleSetDraft("modules", { reactions: state.modules.reactions, comments: !state.modules.comments });
  };

  const handleAnswers = (value: any) => {
    setState("answers", value);
    handleSetDraft("answers", value);
  };

  const handlePollLimit = (date: any) => {
    if(date === null) {
      setState("pollLimit", null);
      handleSetDraft("pollLimit", null);
    }
    else {
      setState("pollLimit", moment(date));
      handleSetDraft("pollLimit", moment(date));
    }
  };

  const setDialog = (value: any) => {
    setState("isDialogOpen", value);
  };

  const handleOnFocus = (_: any, __: any, ___: any, ref: any) => {
    setState("lastFocused", ref);
  };

  const loadPostData = useCallback((postData: any) => {
    if(Object.keys(postData).length > 0) {
      setState("title", postData.title);
      setState("initialTitle", postData.title);
      setState("description", postData.descriptionRaw);
      setState("initialDescription", postData.descriptionRaw);
      let tempFiles: any = [];
      postData.files.forEach((file: any) => {
        const newFile = {
          ...file,
          type: 'application/pdf',
          status: "hosted",
          uid: file.mediaID,
        };
        tempFiles = [...tempFiles, newFile];
      });
      postData.photos.forEach((file: any) => {
        const newFile = {
          ...file,
          type: 'image/jpeg',
          status: "hosted",
          uid: file.mediaID,
        };
        tempFiles = [...tempFiles, newFile];
      });
      postData.videos.forEach((file: any) => {
        const newFile = {
          ...file,
          type: 'video/mp4',
          status: "hosted",
          uid: file.mediaID,
        };
        tempFiles = [...tempFiles, newFile];
      });
      setState("postType", postData.videos.length === 0 ? postData.postType : 3);
      if(postData.postType === 2) {
        const newAnswers = [...postData.poll.answers, {limit: 0, answer: ''}];
        setState("answers", newAnswers);
        setState("initialAnswers", newAnswers);
        setState("multipleAnswers", postData.poll.isMultipleChoice);
        setState("initialMultipleAnswers", postData.poll.isMultipleChoice);
        const isAnswersLimited = postData.poll.answers.filter((answer: any) => answer.limit !== 0).length > 0;
        setState("limitAnswers", isAnswersLimited);
        setState("timelimitPoll", postData.poll.votableTo === null ? false : true);
        setState("pollLimit", postData.poll.votableTo);
        setState("initialPollLimit", postData.poll.votableTo);
      }
      setState("modules", { reactions: postData.reactions ? Boolean(postData.reactions.enable) : false, comments: postData.comments ? Boolean(postData.comments.enable) : false });
      setState("initialModules", { reactions: postData.reactions ? Boolean(postData.reactions.enable) : false, comments: postData.comments ? Boolean(postData.comments.enable) : false });
      setState("files", tempFiles);
      refFiles.current = tempFiles;
      setState("initialFiles", tempFiles);
      setState("activityType", postData.activityTypeID);
      setState("initialActivityType", postData.activityTypeID);
      const newChildren = postData.children.map((item: any) => { return { childID: item.childID, classID: item.classID[0] }; });
      setState("selectedChildren", newChildren);
      setState("initialSelectedChildren", newChildren);
      setState("isReady", true);
    } else {
      createNotification(t("post_not_exist"),"error");
      onCloseModal();
    }
  }, [onCloseModal, setState, t]);

  const loadDraftData = useCallback(() => {
    setState("isModalMinimalized", true);
    const savedData = getAppData();
    const postData = savedData.savedPost;
    if(postData.uniqueID) {
      setState("uniqueID", postData.uniqueID);
    }
    if(postData.postID) {
      setState("postID", postData.postID);
    }
    if(postData.title) {
      setState("title", postData.title);
    }
    if(postData.description) {
      setState("description", postData.description);
    }
    if(postData.activityType) {
      setState("activityType", postData.activityType);
    }
    if(postData.children) {
      const newChildren = postData.children.map((item: any) => { return { childID: item.childID, classID: item.classID }; });
      setState("selectedChildren", newChildren);
    }
    if(postData.postType) {
      setState("postType", postData.postType);
    }
    if(postData.postType === 2) {
      if(postData.poll.multipleAnswers) {
        setState("multipleAnswers", postData.poll.multipleAnswers);
      }
      if(postData.poll.pollLimit !== null) {
        setState("timelimitPoll", postData.poll.pollLimit === null ? false : true);
        setState("pollLimit", postData.poll.pollLimit);
      }
      if(postData.poll.answers.length !== 0) {
        const newAnswers = [...postData.poll.answers, {limit: 0, answer: ''}];
        setState("answers", newAnswers);
        const isAnswersLimited = postData.poll.answers.filter((answer: any) => answer.limit !== 0).length > 0;
        setState("limitAnswers", isAnswersLimited);
      }
    }
    if(postData.modules) {
      setState("modules", { reactions: postData.modules.reactions, comments: postData.modules.comments });
    }
    if(postData.files) {
      setState("files", postData.files);
    }
    setState("isReady", true);
  }, [setState]);

  useEffect(() => {
    const savedData = getAppData();
    if(isEdit && state.uniqueID !== null) {
      if(timelineData.posts.filter((post: any) => post.uniqueID === state.uniqueID).length === 1) {
        const postData =  timelineData.posts.find((post: any) => post.uniqueID === state.uniqueID);
        loadPostData(postData);
      } else {
        createNotification(t("post_not_exist"),"error");
        onCloseModal();
      }
    } else if(isEdit && state.postID !== null) {
      timelineService && timelineService.listPosts({postID: state.postID}).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.posts) {
              if(result.data.posts.length === 1) {
                loadPostData(result.data.posts[0]);      
              } else {
                createNotification(t("post_not_exist"),"error");
                onCloseModal();
              }
            }
          }
        }
      });
    } else if(savedData.savedPost && savedData.savedPost !== null && typeof savedData.savedPost === "object") {
      loadDraftData();
    } else {
      setState("isReady", true);
    }    
  }, [isEdit, loadDraftData, loadPostData, onCloseModal, setState, state.postID, state.uniqueID, t, timelineData.posts, timelineService], []);

  useEffect(() => {
    if(!state.modalIsMinimalized) {
      if(modalRef.current && headerRef.current && footerRef.current) {
        const modalHeight = state.modalIsFullscreen ? browserData.height : modalRef.current.clientHeight;   
        const headerHeight = headerRef.current.clientHeight; 
        const footerHeight = footerRef.current.clientHeight; 
        const bodyHeight = modalHeight - headerHeight - footerHeight; 
        setState("bodyHeight", bodyHeight);
      } else {
        if(!state.updateRefs) {
          setState("updateRefs", true);
        }
      }
      if(state.updateRefs) {
        setState("updateRefs", false);
      }
    }
  }, [state.modalIsFullscreen, state.modalIsMinimalized, browserData.height, state.updateRefs, setState], [state.modalIsFullscreen, state.modalIsMinimalized, browserData.height, state.updateRefs]);

  useEffect(() => {
    setState("modalIsFullscreen", smallDevice ? true : false);
  }, [browserData.height, smallDevice, setState], [browserData.height]);

  const handleCheck = (e: any, gdprConfirm: any = false, videoConfirm: any = false) => {
    let isGDPRConfirm = true;
    let isVideoConfirm = true;
    if(gdprConfirm === false) {
      if(state.selectedChildren.filter((child: any) => !getChildData(child.childID).hasGDPR).length !== 0 && state.files.filter((fileData: any) => fileData.status === "ready" || fileData.status === "hosted").length !== 0) {
        isGDPRConfirm = false;  
      }
    }
    if(videoConfirm === false) {
      if(state.postType === 3) {
        if(state.files.filter((fileData: any) => fileData.status === "ready" || fileData.status === "hosted").length === 0) {
          isVideoConfirm = false; 
        }
      }
    }
    if(!isGDPRConfirm) {
      const onAccept = (e: any) => {
        setTimeout(function() {
          handleCheck(e, true, isVideoConfirm);
        }, 100);
      };
      const settings = {
        isOpen: true,
        title: t('missing_consent'),
        content: t('gdpr_warning'),
        onAccept: onAccept,
        onDecline: null,
      };
      dispatch(setConfirmModal(settings));
    } else if(!isVideoConfirm) {
      const onAccept = (e: any) => {
        setTimeout(function() {
          handleCheck(e, isGDPRConfirm, true);
        }, 100);
      };
      const settings = {
        isOpen: true,
        title: t('no_media'),
        content: t('video_post_warning'),
        onAccept: onAccept,
        onDecline: null,
      };
      dispatch(setConfirmModal(settings));
    } else {
      if(isEdit && !isRepost) {
        handleEdit(e);
      } else {
        handleCreate(e);
      }
    }
  };

  const handleSaveDraft = () => {
    if(!state.modalIsMinimalized) {
      let tempPostData: any = {};
      if(state.uniqueID !== null) {
        tempPostData.uniqueID = state.uniqueID;
      }
      if(state.postID !== null) {
        tempPostData.postID = state.postID;
      }
      if(state.title) {
        tempPostData.title = state.title;
      }
      if(state.description) {
        tempPostData.description = state.description;
      }
      if(state.activityType) {
        tempPostData.activityType = state.activityType;
      }
      if(state.selectedChildren.length !== 0) {
        tempPostData.children = state.selectedChildren;
      }
      if(state.postType) {
        tempPostData.postType = state.postType;
      }
      if(state.postType === 2) {
        tempPostData.poll = {};
        if(state.multipleAnswers) {
          tempPostData.poll.multipleAnswers = state.multipleAnswers;
        }
        if(state.pollLimit !== null) {
          tempPostData.poll.pollLimit = state.pollLimit === null ? null : moment(state.pollLimit).format("YYYY-MM-DD HH:mm:ss");
        }
        if(state.answers.filter((item: any) => item.answer !== "").length !== 0) {
          tempPostData.poll.answers = state.answers.filter((item: any) => item.answer !== "");
        }
      }
      if(state.modules) {
        tempPostData.modules = state.modules;
      }
      const existedFiles = state.files.filter((fileData: any) => fileData.status === "hosted" || fileData.status === "deleted");
      if(existedFiles.length !== 0) {
        tempPostData.files = existedFiles;
      }
      if(Object.keys(tempPostData).length === 1) {
        tempPostData = null;
      }
      setAppData({savedPost: tempPostData});
    }
  };
  
  const handleCreate = (e: any) => {
    e.stopPropagation();
    setState("isLoading", true);

    let postPayload: any = {
      title: state.title,
      description: state.description,
      activityTypeID: state.activityType,
      children: state.selectedChildren,
      postType: state.postType === 3 ? 1 : state.postType,
      modules: state.modules,
    };
    if(state.postType === 2) {
      const poll = {
        isMultipleChoice: state.multipleAnswers,
        votableTo: state.pollLimit === null ? null : moment(state.pollLimit).format("YYYY-MM-DD HH:mm:ss"),
        answers: state.answers.filter((item: any) => item.answer !== ""),
      };
      postPayload = {...postPayload, poll};
    }
    const newFiles = state.files.filter((fileData: any) => isRepost ? (fileData.status === "ready" || fileData.status === "hosted") : fileData.status === "ready").map((fileData: any, key: any) => {
      return {id: key, name: fileData.name, type: getFileType(fileData.type), size: fileData.size, status: fileData.status, mediaID: fileData.mediaID};
    });
    const payload = {
      post: postPayload,
      files: newFiles,
    };
    timelineService && timelineService.createPost(payload).then((result: any) => {
      if(result) {
        if(result.status === 201) {
          if(result.data) {
            if(result.data.postID) {
              if(result.data.files) {
                if(result.data.files.length > 0) {
                  const uploadFiles = state.files.filter((fileData: any) => isRepost ? (fileData.status === "ready" || fileData.status === "hosted") : fileData.status === "ready").map((file: any, key: any) => {
                    return {...file, uploadUrl: result.data.files.find((theFile: any) => theFile.id === key).uploadUrl};  
                  })
                  handleUploadFiles(result.data.postID, uploadFiles);
                } else {
                  handleFinish(result.data.postID);
                }
              }  
            }
          }
        }
      }
    });
  };

  const handleEdit = (e: any) => {
    e.stopPropagation();
    setState("isLoading", true);
    let postPayload: any = {
      title: state.title,
      description: state.description,
      activityTypeID: state.activityType,
      children: state.selectedChildren,
      modules: state.modules,
    };
    if(state.postType === 2) {
      const poll = {
        isMultipleChoice: state.multipleAnswers,
        votableTo: state.pollLimit === null ? null : moment(state.pollLimit).format("YYYY-MM-DD HH:mm:ss"),
        answers: state.answers.filter((item: any) => item.answer !== ""),
      };
      postPayload = {...postPayload, poll};
    }
    const newFiles = state.files.filter((fileData: any) => isRepost ? (fileData.status === "ready" || fileData.status === "hosted") : fileData.status === "ready").map((fileData: any, key: any) => {
      return {id: key, name: fileData.name, type: getFileType(fileData.type), size: fileData.size, status: fileData.status, mediaID: fileData.mediaID};
    });
    const deletedMedias = state.files.filter((fileData: any) => fileData.status === "deleted").map((fileData: any) => {
      return fileData.mediaID;
    });
    const payload = {
      post: postPayload,
      files: newFiles,
      deletedMediasID: deletedMedias,
    };
    timelineService && timelineService.updatePost(state.postID, payload).then((result: any) => {
      if(result) {
        if(result.status === 201) {
          if(result.data) {
            if(result.data.postID) {
              if(result.data.files) {
                if(result.data.files.length > 0) {
                  const uploadFiles = state.files.filter((fileData: any) => isRepost ? (fileData.status === "ready" || fileData.status === "hosted") : fileData.status === "ready").map((file: any, key: any) => {
                    return {...file, uploadUrl: result.data.files.find((theFile: any) => theFile.id === key).uploadUrl};  
                  });
                  handleUploadFiles(state.postID, uploadFiles);
                } else {
                  handleFinish(state.postID);
                }
              }  
            }
          }
        }
      }
    });
  };

  const handleFileLoading = (value: any) => {
    setState("isFilesLoading", value);
  };

  const classes = useStyles({
    isFullscreen: state.modalIsFullscreen,
    isMinimalized: state.modalIsMinimalized,
    bodyHeight: state.bodyHeight,
  });

  const handleUploadFiles = (newPostID: any, sentFiles: any) => {
    const file = sentFiles[0];
    const newFiles = sentFiles.filter((fileData: any) => fileData.uid !== file.uid);
    const updateFiles = refFiles.current.map((fileData: any) => {
      if(fileData.uid === file.uid) {
        return {...fileData, status: "uploading"};
      } else {
        return fileData;
      }
    });
    setState("files", updateFiles);
    refFiles.current = updateFiles;
    if(file.status === "ready") {
      const reader = new FileReader();
      reader.onload = function() {
          const dataUrl: any = reader.result;
          const base64 = dataUrl.split(',')[1];
          const arrayBuffer = base64ToArrayBuffer(base64);
          timelineService && timelineService.uploadFile(file.uploadUrl, arrayBuffer).then((result: any) => {
            if(result) {
              if(result.status === 201) {
                const updateFiles = refFiles.current.map((fileData: any) => {
                  if(fileData.uid === file.uid) {
                    return {...fileData, status: "uploaded"};
                  } else {
                    return fileData;
                  }
                });
                setState("files", updateFiles);
                refFiles.current = updateFiles;
                if(newFiles.length === 0) {
                  handleFinish(newPostID); 
                } else {
                  handleUploadFiles(newPostID, newFiles);  
                }
              }
            }
          });
      };
      reader.readAsDataURL(file.blob);
    } else {
      timelineService && timelineService.downloadFile(file.mediaID).then((result: any) => {
        if(result) {
          if(result.data) {
            const arrayBuffer = result.data;
            timelineService && timelineService.uploadFile(file.uploadUrl, arrayBuffer).then((result: any) => {
              if(result) {
                if(result.status === 201) {
                  const updateFiles = refFiles.current.map((fileData: any) => {
                    if(fileData.uid === file.uid) {
                      return {...fileData, status: "uploaded"};
                    } else {
                      return fileData;
                    }
                  });
                  setState("files", updateFiles);
                  refFiles.current = updateFiles;
                  if(newFiles.length === 0) {
                    handleFinish(newPostID); 
                  } else {
                    handleUploadFiles(newPostID, newFiles);  
                  }
                }
              }
            });
          }
        }
      });
    }
  };

  const handleFinish = (newPostID: any) => {
    timelineService && timelineService.listPosts({postID: newPostID}).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.posts) {
            if(result.data.posts.length === 1) {
              const resultPostData = result.data.posts[0];
              const childrenID = resultPostData.children.map((item: any) => { return item.childID; });
              const postData = Object.assign(resultPostData, {uniqueID: resultPostData.postID + "_" + childrenID.join()});
              if(isEdit) {
                dispatch(updateTimelinePost(postData));
                const message = state.postType === 1 ? t('post_updated') : (state.postType === 2 ? t('poll_updated') : t('post_video_updated'));
                createNotification(message, "success");
              } else {
                dispatch(addTimelinePost(postData));
                const message = state.postType === 1 ? t('post_added') : (state.postType === 2 ? t('poll_added') : t('post_video_added'));
                createNotification(message, "success");
              }       
              if(!state.isKeepDraftAfterUse) {
                handleDeleteDraft();
              }            
              setState("isSaved", true);
              setTimeout(function() {
                onCloseModal();
              }, 500);   
            } else {
              result.data.posts.forEach((resultPost: any) => {
                const resultPostData = resultPost;
                const childrenID = resultPostData.childnen.map((item: any) => { return item.childID; });
                const postData = Object.assign(resultPostData, {uniqueID: resultPostData.postID + "_" + childrenID.join()});
                if(isEdit) {
                  dispatch(updateTimelinePost(postData));
                } else {
                  dispatch(addTimelinePost(postData));
                }                   
              });
              if(isEdit) {
                const message = state.postType === 1 ? t('post_updated') : (state.postType === 2 ? t('poll_updated') : t('post_video_updated'));
                createNotification(message, "success");
              } else {
                const message = state.postType === 1 ? t('post_added') : (state.postType === 2 ? t('poll_added') : t('post_video_added'));
                createNotification(message, "success");
              }    
              if(!state.isKeepDraftAfterUse) {
                handleDeleteDraft();
              }
              setState("isSaved", true);
              setTimeout(function() {
                onCloseModal();
              }, 500);   
            }
          } else {
            setState("isLoading", false);
            createNotification(t("something_went_wrong"), "error");
            const updateFiles = refFiles.current.map((fileData: any) => {
              if(fileData.status === "uploaded" || fileData.status === "uploading") {
                return {...fileData, status: fileData.mediaID ? "hosted" : "ready"};
              } else {
                return fileData;
              }
            });
            setState("files", updateFiles);
            refFiles.current = updateFiles;
          }
        } else {
          setState("isLoading", false);
          createNotification(t("something_went_wrong"), "error");
          const updateFiles = refFiles.current.map((fileData: any) => {
            if(fileData.status === "uploaded" || fileData.status === "uploading") {
              return {...fileData, status: fileData.mediaID ? "hosted" : "ready"};
            } else {
              return fileData;
            }
          });
          setState("files", updateFiles);
          refFiles.current = updateFiles;
        }
      } else {
        setState("isLoading", false);
        createNotification(t("something_went_wrong"), "error");
        const updateFiles = refFiles.current.map((fileData: any) => {
          if(fileData.status === "uploaded" || fileData.status === "uploading") {
            return {...fileData, status: fileData.mediaID ? "hosted" : "ready"};
          } else {
            return fileData;
          }
        });
        setState("files", updateFiles);
        refFiles.current = updateFiles;
      }
    });
  };

  const handleDelete = useCallback(() => {
    const deletePost = () => {
      timelineService && timelineService.deletePost(state.postID).then((result: any) => {
        if(result) {
          if(result.status === 204) {
            createNotification(t("post_deleted"), "success");
            dispatch(deleteTimelinePost(state.postID));
            onCloseModal();
          } else {
            createNotification(t("post_not_deleted"), "error");
          }
        } else {
          createNotification(t("post_not_deleted"), "error");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("post_not_deleted"), "error");
      });
    };
    const settings = {
      isOpen: true,
      title: t('post_delete'),
      content: t('post_delete_confirm'),
      onAccept: deletePost,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  }, [dispatch, state.postID, t, timelineService, onCloseModal])

  const activityTypeValue = (state.activityType !== null && state.activityType !== undefined && activityTypes.filter((activity: any) => activity.activityTypeID === state.activityType).length === 1) ? (
    <span className={classes.activityTypeValue}>
      <img src={activityTypes.find((activity: any) => activity.activityTypeID === state.activityType).image} alt={activityTypes.find((activity: any) => activity.activityTypeID === state.activityType).name}/>
      <p style={{color: activityTypes.find((activity: any) => activity.activityTypeID === state.activityType).color}}>
        {activityTypes.find((activity: any) => activity.activityTypeID === state.activityType).name}
      </p>
    </span>
  ) : (
    <span className={classes.activityTypePlaceholder}>
      <SVG src="activity-type"/>
      {t('select_activity_type')}
    </span>
  );

  const activityTypePicker = (
    <BlockPicker items={activityTypes} value={state.activityType} valueCompare="activityTypeID" buttonSelected={false} buttonInactive={true} buttonOpacity={0.2} onChange={handleActivityType}/>
  );

  const handleDrafts = () => {
    const settings = {
      isOpen: true,
      currentDraftID: state.draftID,
      type: 'timeline',
      onDraftLoad: handleLoadDraft,
    };
    dispatch(setDraftsModal(settings));
  };

  const handleSetDraft = (name: any, value: any) => {
    setDraft(name, value);
  };

  const handleUpdateDrafts = useCallback(async (value: any) => {
    if(isSaving.current === false) {
      isSaving.current = true;
      const oldValue = drafts;
      const newValue = updateUserSetting(userData.userSettings, "drafts", ["timeline"], value);
      dispatch(setUserSettings(newValue));
      setState("isDraftSaving", true);
      const result = await saveUserSettings(dispatch, userData, "drafts", ["timeline"], value);
      if(result) {
        setState("isDraftSaving", false);
        isSaving.current = false;
        if(state.lastFocused && state.lastFocused.current) {
          setTimeout(() => {
            if(state.lastFocused && state.lastFocused.current) {
              state.lastFocused.current.focus();
            }
          }, 100);
        }
      } else {
        const updateSettings = updateUserSetting(userData.userSettings, "drafts", ["timeline"], oldValue);
        dispatch(setUserSettings(updateSettings));
        createNotification(t("draft_not_saved"), "error");
        setState("isDraftSaving", false);
        isSaving.current = false;
        if(state.lastFocused && state.lastFocused.current) {
          setTimeout(() => {
            if(state.lastFocused && state.lastFocused.current) {
              state.lastFocused.current.focus();
            }
          }, 100);
        }
      }
    }
  }, [dispatch, drafts, setState, t, userData, state.lastFocused]);

  const handleDeleteDraft = useCallback(() => {
    const newDraftData = drafts.filter((item: any) => item.draftID !== state.draftID);
    handleUpdateDrafts(newDraftData);
  }, [drafts, state.draftID, handleUpdateDrafts]);

  const handleLoadDraft = useCallback((data: any) => {
    setState("isReady", false);
    if(data.title) {
      setState("title", data.title);
    }
    if(data.description) {
      setState("description", data.description);
    }
    if(data.activityType) {
      setState("activityType", data.activityType);
    }
    if(data.selectedChildren) {
      setState("selectedChildren", data.selectedChildren);
    }
    if(data.postType) {
      setState("postType", data.postType);
    }
    if(data.postType === 2) {
      if(data.multipleAnswers) {
        setState("multipleAnswers", data.multipleAnswers);
      }
      if(data.timelimitPoll) {
        setState("timelimitPoll", data.timelimitPoll);
      }
      if(data.pollLimit !== null) {
        setState("pollLimit", data.pollLimit);
      }
      if(data.answers.length !== 0) {
        setState("answers", data.answers);
      }
      if(data.limitAnswers) {
        setState("limitAnswers", data.limitAnswers);
      }
    }
    if(data.modules) {
      setState("modules", { reactions: data.modules.reactions, comments: data.modules.comments });
    }
    setWholeDraft(data);
    setState("draftID", data.draftID);
    setTimeout(()=> {
      setState("isReady", true);
    }, 1);
  }, [setState, setWholeDraft]);

  useEffect(() => {
    if(delayDebounceFn) {
      clearTimeout(delayDebounceFn);
    }
    const debouncetimeout = setTimeout(() => {
      const draftData = filterNotExistingValues(draft);
      if(Object.keys(draftData).length > 0 && isDraftEnabled) {
        const newDraft = {...draftData, draftID: state.draftID, draftDate: moment()};
        if(hasObjectAnyValue(draftData)) {
          if(drafts.filter((item: any) => item.draftID === state.draftID).length === 0) {
            const newDraftData = [...drafts, newDraft];
            handleUpdateDrafts(newDraftData);
          } else {
            const newDraftData = drafts.map((item: any) => {
              if(item.draftID === state.draftID) {
                return newDraft;
              } else {
                return item;
              }
            });
            handleUpdateDrafts(newDraftData);
          }
        } else {
          const newDraftData = drafts.filter((item: any) => item.draftID !== state.draftID);
          handleUpdateDrafts(newDraftData);
        }
      }
    }, 3000);
    setDelayDebounceFn(debouncetimeout);
  }, [draft, drafts, isDraftEnabled, state.draftID, dispatch, setState, handleUpdateDrafts, delayDebounceFn], [draft]);

  const handleIsKeepDraftAfterUse = () => {
    setState("isKeepDraftAfterUse", !state.isKeepDraftAfterUse);
  };

  return state.isReady ? (
    <>
      <LeavePageBlocker when={(isChanged || state.isLoading) && !state.modalIsMinimalized} />
      <Modal 
        open={true}
        onClose={handleClose}
        position={modalPosition}
        disableEscapeKeyDown={true}
        isFullscreen={state.modalIsFullscreen}
        hideBackdrop={state.modalIsMinimalized}
        disableEnforceFocus={!state.modalIsMinimalized && !state.isDialogOpen}
      >
        <div className={classes.root} data-cy={isCypress() ? "postCreateModal" : null} ref={modalRef}>
          <div className={classes.header} ref={headerRef} onClick={handleHeaderClick}>
            <div className={classes.wrapper}>
              <p className={state.modalIsMinimalized ? (state.title.length > 0 ? classes.customTitle : classes.defaultTitle) : classes.defaultTitle}>{state.modalIsMinimalized ? (state.title.length > 0 ? state.title : modalTitle) : modalTitle}</p>
            </div>
            <div className={classes.buttons}>
              {
                !state.modalIsMinimalized ? (
                  <IconButton className={classes.button} onClick={handleInfoModal} data-cy={isCypress() ? "infoModalButton" : null} tooltip={t('help')} tooltipPosition='bottom'>
                    <SVG src="question-mark-circle"/>
                  </IconButton>
                ) : null
              }
              {
                (isDraftEnabled && !state.modalIsMinimalized) ? (
                  <IconButton className={`${classes.button} smallIcon`} onClick={handleDrafts} data-cy={isCypress() ? "draftsButton" : null} tooltip={t('drafts')} tooltipPosition='bottom'>
                    <SVG src="draft"/>
                  </IconButton>
                ) : null
              }
              {
                (!smallDevice && !state.modalIsMinimalized && !state.modalIsFullscreen) ? (
                  <IconButton className={`${classes.button} smallIcon`} onClick={handleMinimalization} data-cy={isCypress() ? "minimalizeButton" : null} tooltip={t('minimalize')} tooltipPosition='bottom'>
                    <MinimizeIcon/>
                  </IconButton>
                ) : null
              }
              {
                (!smallDevice && !state.modalIsMinimalized) ? (
                  <IconButton className={`${classes.button} smallIcon`} onClick={handleFullscreen} data-cy={isCypress() ? "fullscreenButton" : null} tooltip={state.modalIsFullscreen ? t('mode_window') : t('mode_fullscreen')} tooltipPosition='bottom'>
                    {state.modalIsFullscreen ? (<SVG src="fullscreen-exit"/>) : (<SVG src="fullscreen-enter"/>)}
                  </IconButton>
                ) : null
              }
              <CloseButton className={classes.button} onClick={handleClose} dataCy="timesButton"/>
            </div>
          </div>
          {
            (!state.modalIsMinimalized || smallDevice) ? (
              <div className={classes.body}>
                <div className={classes.bodyWrapper}>
                  {
                    !isEdit ? (
                      <ButtonGroup className={classes.buttonGroup} items={postTypes} value={state.postType} onChange={handlePostType} disabled={state.isLoading || state.isDraftSaving}/>
                    ) : null
                  }
                  <Input label={t('title') + '*'} name='title' value={state.title} maxLength={255} showCounter={true} onChange={handleTitle} onInputEnd={handleSetDraft} onFocus={handleOnFocus} autoFocus={state.firstTimeFocus} disabled={state.isLoading || state.isDraftSaving}/>
                  <RichTextEditor uid="postContent" name='description' useName={true} label={t('post_content')} value={state.description} onChange={handleDescription} onFocus={handleOnFocus} onInputEnd={handleSetDraft} onDialogChange={setDialog} disabled={state.isLoading || state.isDraftSaving}/>
                  {
                    state.postType === 2 ? (
                      <>
                        <PollInputGroup label={t('answers')} items={state.answers} showLimits={state.limitAnswers} onChange={handleAnswers} disabled={state.isLoading || state.isDraftSaving}/>
                        {
                          state.timelimitPoll ? (
                            <DatePicker label={t('answers_limit')} presetDate={state.pollLimit} setDate={handlePollLimit} disabled={state.isLoading || state.isDraftSaving}/>
                          ) : null
                        }
                        <div className={classes.switches}>
                          <Switch checked={state.limitAnswers} onChange={handleLimitAnswers} label={t('limit_answers')} disabled={state.isLoading || state.isDraftSaving}/>
                          <Switch checked={state.timelimitPoll} onChange={handleTimelimitPoll} label={t('timelimit_poll')} disabled={state.isLoading || state.isDraftSaving}/>
                          <Switch checked={state.multipleAnswers} onChange={handleMultipleAnswers} label={t('multiple_answers')} disabled={state.isLoading || state.isDraftSaving}/>
                        </div>
                      </>
                    ) : null
                  }
                  <FileInput files={state.files} label={t('media')} onChange={handleFiles} onLoading={handleFileLoading} allowed={allowedFiles[state.postType]} disabled={state.isLoading || state.isDraftSaving} mediaLimit='timeline'/>
                  <Dropdown label={t('select_activity_type') + "*"} isDropdownOpen={state.isDropdownOpen} setIsDropdownOpen={handleDropdownOpen} visibleChildren={activityTypeValue} hiddenChildren={activityTypePicker} disabled={state.isLoading || state.isDraftSaving}/>
                  <ChildrenInput label={t('select_children') + "*"} selectedChildren={state.selectedChildren} isShowChildrenCount={true} isShowChildrenGDPR={true} setSelectedChildren={handleSelectedChildren} onClick={handleChildrenSelect} maxRows={3} disabled={state.isLoading || state.isDraftSaving}/>
                  {
                    state.isChildrenSelectOpen ? (
                      <ChildrenSelect
                        isInModal={true}
                        isModalOpen={true}
                        modalTitle="children"
                        modalOnClose={() => setState("isChildrenSelectOpen", false)}
                        modalOnSave={handleSaveChildrenModal}
                        selectedChildren={state.selectedChildren.map((item: any) => { return { childID: item.childID, classID: item.classID }; })}
                        isChildGDPR={true}
                        isSelectAll={true}
                        isSelectInAllClass={false}
                        isMultipleSelect={true}
                        isAllowArchived={false}
                        isAllowInactiveClasses={false}
                        isAllowArchivedToggle={false}
                        isAllowSearch={true}
                        mode="select"
                        modalAllowCancel={false}
                        modalAllowClose={true}
                        modalAllowClear={true}
                        modalAllowNoChild={false}
                        modalAllowChildrenCount={true}
                      />
                    ) : null
                  }
                  {
                    (schoolsWithAvailableReactions.length > 0 || schoolsWithAvailableComments.length > 0) ? (
                      <>
                        <Label>{t('options')}{(selectedSchools.length > 0 && !isAvailableReactions && !isAvailableComments) ? (<small className={classes.disabled}>{selectedSchools.length === 1 ? t('selected_school_no_available_modules') : t('selected_one_school_no_available_modules')}</small>) : null}</Label>
                        <div className={classes.switches}>
                          {
                            schoolsWithAvailableReactions.length > 0 ? (
                              <Switch checked={(selectedSchools.length > 0 && !isAvailableReactions) ? isAvailableReactions : state.modules.reactions} onChange={handleModulesReactions} label={t('reactions_emoji')} disabled={state.isLoading || (selectedSchools.length > 0 && !isAvailableReactions) || state.isDraftSaving}/>
                            ) : null
                          }
                          {
                            schoolsWithAvailableComments.length > 0 ? (
                              <Switch checked={(selectedSchools.length > 0 && !isAvailableComments) ? isAvailableComments : state.modules.comments} onChange={handleModulesComments} label={t('comments')} disabled={state.isLoading || (selectedSchools.length > 0 && !isAvailableComments) || state.isDraftSaving}/>
                            ) : null
                          }
                        </div>
                      </>
                    ) : null
                  }
                </div>
              </div>
            ) : null
          }
          {
            (!state.modalIsMinimalized || smallDevice) ? (
              <div className={classes.footer} ref={footerRef}>
                {
                  isDraftEnabled ? (
                    <span className={classes.info}>
                      <div className={classes.infoDetail}>
                        <SVG src={state.isDraftSaving ? "save" : "info-circle-outlined"}/>
                        {state.isDraftSaving ? t('timeline_post_autosaving') : t('timeline_post_autosave_enabled')}
                      </div>
                      {
                        Object.keys(filterNotExistingValues(draft)).length > 0 ? (
                          <Switch labelLeft={t('keep_draft_after_use')} onChange={handleIsKeepDraftAfterUse} checked={state.isKeepDraftAfterUse} dataCy="isKeepDraftAfterUse"/>
                        ) : null
                      }
                    </span>
                  ) : null
                }
                <div className={classes.footerButtons}>
                  {
                    (isEdit && !isRepost) ? (
                      <NormalButton className={classes.deleteButton} buttonType="clear" onClick={handleDelete} dataCy="deleteButton">
                        {t('delete')}
                      </NormalButton>
                    ) : null
                  }
                  <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton">
                    {t('cancel')}
                  </NormalButton>
                  {
                    (isEdit && !isRepost) ? (
                      <NormalButton buttonType="primary" onClick={handleCheck} disabled={!isChanged || !isCreatable} dataCy="createButton">
                        {state.isSaved ? t('saved') : (state.isLoading ? t('saving') : t('save'))}
                      </NormalButton>
                    ) : (
                      <NormalButton buttonType="primary" onClick={handleCheck} disabled={!isCreatable} dataCy="saveButton">
                        {state.isSaved ? t('saved') : (state.isLoading ? t('saving') : t('create'))}
                      </NormalButton>
                    )
                  }
                </div>
              </div>
            ) : null
          }
        </div>
      </Modal>
      </>
  ) : null
};

export default PostCreateModal;
