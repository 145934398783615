const IsLocation = (loc: any) => {
  const location = window.location;
  return location.pathname === loc;
};

const IsLocationPart = (loc: any) => {
  const location = window.location;
  return location.pathname.startsWith(`${loc}/`);
};


export {
  IsLocation,
  IsLocationPart,
};