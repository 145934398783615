import CircularProgress from '@mui/material/CircularProgress';
import config from '../../../constants/config';
import DateFormat from '../../../utils/dateFormat';
import FileItem from '../../Items/FileItem';
import moment from '../../../utils/moment';
import React, { useCallback, useRef } from 'react';
import SVG from '../../Images/SvgRenderer';
import { arrayBufferToBase64, base64ToArrayBuffer, base64toBlob, formatFileSize, getFileType, getImageFromClipboard, getSupportedMediaRecorderType, handleDetectCamera, handleDetectScreen } from 'src/utils/useFunctions';
import { Button } from '@mui/material';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { FileDrop } from 'react-file-drop';
import { isDesktop } from 'react-device-detect';
import { processImage } from 'src/utils/useMedia';
import { setCameraModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%', 
    '& > label': {
      display: 'flex',
      flexWrap: 'wrap',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
      alignItems: 'center',
      '& > small': {
        fontSize: '13px',
        color: theme.colors.grey[540],
        fontWeight: 'normal',
        display: 'flex',
        gap: '4px',
        alignItems: 'center',
        marginLeft: 'auto',
        '& > svg': {
          width: '15px',
          height: '15px',
        },
      },
    },
  },
  help: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: '7px',
    '& > small': {
      fontSize: '13px',
      color: theme.colors.grey[540],
      display: 'flex',
      gap: '4px',
      alignItems: 'center',
      marginLeft: 'auto',
      '& > svg': {
        width: '15px',
        height: '15px',
      },
    },
  },
  fileInput: {
    width: '100%',
    maxWidth: 'calc(100% - 2px)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[325],
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    minHeight: "200px",
    display: "flex",
    overflow: "auto",
    flexDirection: 'column',
    position: 'relative',
    '&:focus': {
      outline: "none",
    },
    '&.disabled': {
      color: theme.colors.grey[560],
      backgroundColor: theme.colors.grey[75],
    },
  },
  hidden: {
    display: 'none',
    pointerEvents: 'none',
  },
  filesList: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    position: 'relative',
    width: 'calc(100% - 20px)',
    height: '100%',
    flex: '1 0 100%',
    padding: "10px",
    borderRadius: "inherit",
  },
  fileWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  addFile: {
    backgroundColor: theme.colors.grey[125],
    borderRadius: "10px",
    borderWidth: "1px",
    borderStyle: "dashed",
    borderColor: theme.colors.grey[300],
    width: "80px",
    height: "80px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 10px",
    '& svg': {
      color: theme.colors.grey[540],
      width: '24px',
      height: '24px',
    },
  },
  captionName: {
    maxWidth: '80px',
    fontSize: "13px",
    lineHeight: '13px',
    height: '16px',
    maxHeight: '16px',
    textAlign: 'center',
    whiteSpace: 'break-spaces',
    overflowWrap: 'break-word',
    marginBottom: '0',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(238,238,238,0.5)',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  captions: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '16px',
    gap: '8px',
  },
  caption: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.colors.grey[550],
    fontSize: "12px",
  },
  draganddrop: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    borderWidth: '5px',
    borderStyle: 'dashed',
    borderColor: theme.colors.black,
    backgroundColor: 'rgba(153,153,153,0.9)',
    color: theme.colors.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '36px',
    zIndex: theme.zIndex.dragAndDrop,
  },
}));

type FileInputType = {
  files: any;
  onChange: any;
  onLoading?: any;
  label?: any;
  showHelp?: boolean;
  disabled?: boolean;
  allowed?: any;
  mediaLimit?: 'calendar' | 'communication' | 'timeline';
};

const FileInput: React.FunctionComponent<FileInputType> = ({files, onChange, onLoading, label, showHelp = true, disabled = false, allowed = ["attachment", "image", "video", "photocamera", "videocamera"], mediaLimit = "timeline"}) => {

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const languageData = useAppSelector((state: any) => state.language);
  const userData = useAppSelector((state: any) => state.user);
  const tempFiles = useRef(files);
  const isCanPaste = useRef(false);

  const [state, setState] = useStates({
    isLoading: false,
    isPhotoCameraAvailable: false,
    isVideoCameraAvailable: false,
    isScreenAvailable: false,
    isDragAndDrop: false,
  });

  const attachmentSelector: any = useRef(null);
  const imageSelector: any = useRef(null);
  const videoSelector: any = useRef(null);

  const fileTypes = ["image", "attachment", "video"];

  const fileMaxCount: any = useMemo(() => { return {
    "attachment": userData.mediaLimits[mediaLimit].attachmentCountLimit,
    "image": userData.mediaLimits[mediaLimit].photoCountLimit,
    "video": userData.mediaLimits[mediaLimit].videoCountLimit,
  }}, [userData.mediaLimits, mediaLimit]);
  
  const fileMaxSize: any = useMemo(() => { return {
    "attachment": userData.mediaLimits[mediaLimit].attachmentSizeLimit,
    "image": userData.mediaLimits[mediaLimit].photoSizeLimit,
    "video": userData.mediaLimits[mediaLimit].videoSizeLimit,
  }}, [userData.mediaLimits, mediaLimit]);

  const fileAllowedTypes: any = useMemo(() => { return {
    "attachment": config.UPLOAD_FORMATS_FILES,
    "image": config.UPLOAD_FORMATS_PHOTOS,
    "video": config.UPLOAD_FORMATS_VIDEOS, 
  }}, []);

  const videoMaxLength = userData.mediaLimits[mediaLimit].videoTimeLimit;

  const handleAddImage = () => {
    imageSelector.current.click();
  };
  
  const handleAddAttachment = () => {
    attachmentSelector.current.click();
  };
  
  const handleAddVideo = () => {
    videoSelector.current.click();
  };  

  const handleUpload = (uploadedFiles: any, type: any) => {
    setState("isLoading", true);
    if(onLoading) {
      onLoading(true);
    }
    if(uploadedFiles) {
      let newFiles = Array.from(uploadedFiles);
      if(newFiles.length === 0) {
        createNotification(t("select_atleast_file"), "error");
        setState("isLoading", false);
        if(onLoading) {
          onLoading(false);
        }
      } else {
        if(type === "all") {
          let filesTypes: any = {
            attachment: [],
            image: [],
            video: [],
          };
          newFiles.forEach((file: any) => {
            const fileType = getFileType(file.type);
            filesTypes[fileType] = filesTypes[fileType].concat(file);
          });
          Object.keys(filesTypes).forEach((fileType: any) => {
            if(fileType.length > 0) {
              const maxCount = parseInt(fileMaxCount[fileType]) - files.filter((fileData: any) => fileAllowedTypes[fileType].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length;
              if(filesTypes[fileType].length > maxCount) {
                if(maxCount === 0) {
                  createNotification(t('file_limit_reached'), "error");
                } else {
                  createNotification(t("too_much_files",{count: filesTypes[fileType].length, max: maxCount, fcount: (filesTypes[fileType].length > 5 ? t('files_more').toLowerCase() : filesTypes[fileType].length === 1 ? t('file').toLowerCase() : t('files').toLowerCase()), fmax: (maxCount > 4 ? t('files_more').toLowerCase() : maxCount === 1 ? t('file').toLowerCase() : t('files').toLowerCase())}), "warning");
                }
              }
            }
          });
        } else {
          const maxCount = parseInt(fileMaxCount[type]) - files.filter((fileData: any) => fileAllowedTypes[type].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length;
          if(newFiles.length > maxCount) {
            if(maxCount === 0) {
              createNotification(t('file_limit_reached'), "error");
            } else {
              createNotification(t("too_much_files",{count: newFiles.length, max: maxCount, fcount: (newFiles.length > 5 ? t('files_more').toLowerCase() : newFiles.length === 1 ? t('file').toLowerCase() : t('files').toLowerCase()), fmax: (maxCount > 4 ? t('files_more').toLowerCase() : maxCount === 1 ? t('file').toLowerCase() : t('files').toLowerCase())}), "warning");
            }
          }
        }
        handleAddFile(newFiles, 1, type, files); 
      }
      attachmentSelector.current.value = '';
      imageSelector.current.value = '';
      videoSelector.current.value = '';
    }
  };

  const handleAddFile = useCallback((newFiles: any, key: any, type: any, currentFiles: any) => {
    const tempFile = newFiles[key - 1];
    const fileType = getFileType(tempFile.type);
    const maxCount = parseInt(fileMaxCount[fileType]) - currentFiles.filter((fileData: any) => fileAllowedTypes[fileType].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length;
    if(maxCount > 0) {
      let file: any = {name: tempFile.name, size: tempFile.size, type: tempFile.type, status: "ready", uid: (moment().format("YYYYMMDDHHmmssSSS") + key)};
      if(file.size > parseInt(fileMaxSize[fileType])) {      
        createNotification(t("file_not_added_max_size", {file: file.name}), "error");
        if(key < newFiles.length) {
          handleAddFile(newFiles, key + 1, type, currentFiles); 
        } else {
          setState("isLoading", false);
          if(onLoading) {
            onLoading(false);
          }
        }
      } else {
        if(fileAllowedTypes[fileType].indexOf(file.type) === -1 || allowed.indexOf(fileType) === -1) {
          createNotification(t("file_not_added_not_allowed", {file: file.name}), "error");
          if(key < newFiles.length) {
            handleAddFile(newFiles, key + 1, type, currentFiles); 
          } else {
            setState("isLoading", false);
            if(onLoading) {
              onLoading(false);
            }
          }
        } else {
          const reader = new FileReader();
          reader.readAsArrayBuffer(tempFile);
          reader.onload = async () => {
            if(type === "image") {
              const base64 = arrayBufferToBase64(reader.result);
              const processedImage = await processImage(base64, file.name, file.type);
              const processedImageBase64 = processedImage[0];
              const processedImageFileName = processedImage[1];
              const processedImageFileSize = processedImage[2];
              const processedImageFileType = processedImage[3];
              const blob = base64toBlob(processedImageBase64, processedImageFileType);
              file = {...file, name: processedImageFileName, size: processedImageFileSize, blob: blob};
            } else {
              const blob = base64toBlob(arrayBufferToBase64(reader.result), file.type);
              file = {...file, blob: blob};
            }
            const newData = [...tempFiles.current, file];
            onChange(newData);
            tempFiles.current = newData;
            if(key < newFiles.length) {
              handleAddFile(newFiles, key + 1, type, [...currentFiles, file]); 
            } else {
              setState("isLoading", false);
              if(onLoading) {
                onLoading(false);
              }
            }
          };
          reader.onerror = () => {
            createNotification(t("file_not_added", {file: file.name}), "error");
            if(key < newFiles.length) {
              handleAddFile(newFiles, key + 1, type, currentFiles); 
            } else {
              setState("isLoading", false);
              if(onLoading) {
                onLoading(false);
              }
            }
          }; 
        }
      }
    } else {
      setState("isLoading", false);
      if(onLoading) {
        onLoading(false);
      }
    } 
  }, [allowed, fileAllowedTypes, fileMaxCount, fileMaxSize, onChange, setState, t, onLoading]);

  const handleEditFile = (fileKey: any, imageData?: any) => {
    let newFile: any = null;
    const newFiles = files.map((file: any, key: any) => {
      if(key === fileKey) {
        if(file.status !== "hosted") {
          return {...file, blob: base64toBlob(arrayBufferToBase64(imageData.image), file.type), size: imageData.size};
        } else {
          newFile = {...file, status: "ready", blob: base64toBlob(arrayBufferToBase64(imageData.image), file.type), uid: (moment().format("YYYYMMDDHHmmssSSS") + key), size: imageData.size};
          return {...file, status: "deleted"};
        }
      } else {
        return file;
      }
    });
    const finalFiles = [...newFiles, newFile].filter((item: any) => item !== null);
    onChange(finalFiles);
    tempFiles.current = finalFiles;
  };

  const handleDeleteFile = (fileKey: any) => {
    const newFiles = files.map((file: any, key: any) => {
      if(key === fileKey) {
        if(file.status === "hosted") {
          return {...file, status: "deleted"};
        } else {
          return {...file, status: "canceled", raw: null};
        }
      } else {
        return file;
      }
    });
    onChange(newFiles);
    tempFiles.current = newFiles;
  };

  const handlePastePhoto = useCallback((dataUri: any) => {
    if(isCanPaste.current) {
      isCanPaste.current = false;
      const fileType = "image";
      const maxCount = parseInt(fileMaxCount[fileType]) - tempFiles.current.filter((fileData: any) => fileAllowedTypes[fileType].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length;
      if(maxCount <= 0) {
        createNotification(t('file_limit_reached'), "error");
      } else {
        const dataSplit = dataUri.split(",");
        const base64 = dataSplit[dataSplit.length - 1];
        const data = base64ToArrayBuffer(base64);
        const file = new File([data], 'pastePhoto_' + userData.userObject.userID +'_' + moment().format("YYYY_MM_DD_HH_mm_ss") + '.png', {type: 'image/png'});
        const newFiles = [file];
        handleAddFile(newFiles, 1, fileType, files);
      }
      setTimeout(() => {
        isCanPaste.current = true;
      }, 1000);
    }
  }, [files, handleAddFile, userData.userObject.userID, fileAllowedTypes, fileMaxCount, t, isCanPaste]);

  const handleTakePhoto = (dataUri: any) => {
    const fileType = "image";
    const maxCount = parseInt(fileMaxCount[fileType]) - tempFiles.current.filter((fileData: any) => fileAllowedTypes[fileType].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length;
    if(maxCount <= 0) {
      createNotification(t('file_limit_reached'), "error");
    } else {
      const dataSplit = dataUri.split(",");
      const base64 = dataSplit[dataSplit.length - 1];
      const data = base64ToArrayBuffer(base64);
      const file = new File([data], 'cameraPhoto_' + userData.userObject.userID +'_' + moment().format("YYYY_MM_DD_HH_mm_ss") + '.png', {type: 'image/png'});
      const newFiles = [file];
      handleAddFile(newFiles, 1, fileType, files);
    }
  };
  
  const handleTakeVideo = (dataUri: any) => {
    const fileType = "video";
    const maxCount = parseInt(fileMaxCount[fileType]) - tempFiles.current.filter((fileData: any) => fileAllowedTypes[fileType].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length;
    if(maxCount <= 0) {
      createNotification(t('file_limit_reached'), "error");
    } else {
      const dataSplit = dataUri.split(",");
      const base64 = dataSplit[dataSplit.length - 1];
      const data = base64ToArrayBuffer(base64);
      const file = new File([data], 'cameraVideo_' + userData.userObject.userID +'_' + moment().format("YYYY_MM_DD_HH_mm_ss") + '.' + getSupportedMediaRecorderType(true), {type: getSupportedMediaRecorderType()});
      const newFiles = [file];
      handleAddFile(newFiles, 1, fileType, files);
    }
  };

  const handleTakeScreen = (dataUri: any) => {
    const fileType = "video";
    const maxCount = parseInt(fileMaxCount[fileType]) - tempFiles.current.filter((fileData: any) => fileAllowedTypes[fileType].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length;
    if(maxCount <= 0) {
      createNotification(t('file_limit_reached'), "error");
    } else {
      const dataSplit = dataUri.split(",");
      const base64 = dataSplit[dataSplit.length - 1];
      const data = base64ToArrayBuffer(base64);
      const file = new File([data], 'screenVideo_' + userData.userObject.userID +'_' + moment().format("YYYY_MM_DD_HH_mm_ss") + '.' + getSupportedMediaRecorderType(true), {type: getSupportedMediaRecorderType()});
      const newFiles = [file];
      handleAddFile(newFiles, 1, fileType, files);
    }
  };

  const handleOpenPhotoCamera = () => {
    const settings = {
      isOpen: true,
      mode: 'photo',
      mediaLimit: mediaLimit,
      onSave: handleTakePhoto,
    };
    dispatch(setCameraModal(settings));
  };
  
  const handleOpenVideoCamera = () => {
    const settings = {
      isOpen: true,
      mode: 'video',
      mediaLimit: mediaLimit,
      onSave: handleTakeVideo,
    };
    dispatch(setCameraModal(settings));
  };

  const handleOpenScreenCamera = () => {
    const settings = {
      isOpen: true,
      mode: 'screen',
      mediaLimit: mediaLimit,
      onSave: handleTakeScreen,
    };
    dispatch(setCameraModal(settings));
  };
  
  useEffect(() => {
    handleDetectCamera((hasWebcam: any) => {
      if(hasWebcam) {
        if(MediaRecorder && getSupportedMediaRecorderType() !== "none") {
          setState("isVideoCameraAvailable", true); 
        }
        setState("isPhotoCameraAvailable", true); 
      }
    });  
    if(handleDetectScreen()) {
      setState("isScreenAvailable", true); 
    } else {
      setState("isScreenAvailable", false); 
    }
  }, [setState], []);

  useEffect(() => {
    window.addEventListener('dragenter', () => {
      setState("isDragAndDrop", true);
    }, false);
    window.addEventListener('dragend', () => {
      setState("isDragAndDrop", false);
    }, false);
    if(!disabled && allowed.indexOf("image") !== -1) {
      isCanPaste.current = true;
      window.addEventListener("paste", (e: any) => {
        getImageFromClipboard(e, (imageDataBase64: any) => {
          if(imageDataBase64) {
            if(isCanPaste.current) {
              handlePastePhoto(imageDataBase64);
            }
          }
        });
      }, false);
    }
    return () => {
      window.removeEventListener('dragenter', () => {
        setState("isDragAndDrop", true);
      });
      window.removeEventListener('dragend', () => {
        setState("isDragAndDrop", false);
      });
      window.removeEventListener('paste', () => {
        isCanPaste.current = false;
      });
    };
  }, [setState, handlePastePhoto, allowed, disabled, fileAllowedTypes, fileMaxCount, files], []);

  const handleDragLeave = () => {
    setState("isDragAndDrop", false);
  };

  const handleDrop = (files: any, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setState("isDragAndDrop", false);
    handleUpload(files, "all");
    return false;
  }; 

  const handleFrameDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setState("isDragAndDrop", false);
    return false;
  }; 

  return (
    <>
      {
        state.isDragAndDrop ? (
          <FileDrop onDrop={handleDrop} onFrameDrop={handleFrameDrop}>
            <div className={classes.draganddrop} onDragLeave={handleDragLeave}>
              {t('drag and drop files here')}
            </div>
          </FileDrop>
        ) : null
      }
      <div className={classes.wrapper}>
        {
          label ? (
            <label>
              {label}
              {
                (isDesktop && showHelp) ? (
                  <small>
                    <SVG src="info-circle-outlined"/> {t('fileinput_drag_and_drop_and_paste_tip')}
                  </small>
                ) : null
              }
            </label>
          ) : (
            <>
              {
                (isDesktop && showHelp) ? (
                  <div className={classes.help}>
                    <small>
                      <SVG src="info-circle-outlined"/> {t('fileinput_drag_and_drop_and_paste_tip')}
                    </small>
                  </div>
                ) : null
              }
            </>
          )
        }
        <div className={`${classes.fileInput} ${disabled ? 'disabled' : null}`}>
          <input className={classes.hidden} type="file" ref={attachmentSelector} accept={config.UPLOAD_FORMATS_FILES.join(", ")} onChange={(e: any) => handleUpload(e.target.files, "attachment")} multiple/>
          <input className={classes.hidden} type="file" ref={imageSelector} accept={config.UPLOAD_FORMATS_PHOTOS.join(", ")} onChange={(e: any) => handleUpload(e.target.files, "image")} multiple/>
          <input className={classes.hidden} type="file" ref={videoSelector} accept={config.UPLOAD_FORMATS_VIDEOS.join(", ")} onChange={(e: any) => handleUpload(e.target.files, "video")} multiple/>
          <div className={classes.filesList}>
            {
              files.map((file: any, key: any) => (file.status !== "deleted" && file.status !== "canceled") ? (
                <FileItem key={`k_${key}`} fileInfo={file} handleEditFile={(e: any, photoData: any) => handleEditFile(key, photoData)} handleRemoveFile={() => handleDeleteFile(key)} disabled={disabled} mediaLimit={mediaLimit}/>
              ) : null)
            }
            {
              (!disabled && allowed.indexOf("image") !== -1 && files.filter((fileData: any) => fileAllowedTypes["image"].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length < parseInt(fileMaxCount["image"])) ? (
                <div className={classes.fileWrapper}>
                  <Button className={classes.addFile} onClick={handleAddImage}>
                    <SVG src="image"/>
                  </Button>
                  <span className={classes.captionName}>{t("photo")}</span>
                </div>
              ) : null
            }
            {
              (!disabled && allowed.indexOf("attachment") !== -1 && files.filter((fileData: any) => fileAllowedTypes["attachment"].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length < parseInt(fileMaxCount["attachment"])) ? (
                <div className={classes.fileWrapper}>
                  <Button className={classes.addFile} onClick={handleAddAttachment}>
                    <SVG src="file"/>
                  </Button>
                  <span className={classes.captionName}>{t("document")}</span>
                </div>
              ) : null
            }
            {
              (!disabled && allowed.indexOf("video") !== -1  && files.filter((fileData: any) => fileAllowedTypes["video"].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length < parseInt(fileMaxCount["video"])) ? (
                <div className={classes.fileWrapper}>
                  <Button className={classes.addFile} onClick={handleAddVideo}>
                    <SVG src="video"/>
                  </Button>
                  <span className={classes.captionName}>{t("video")}</span>
                </div>
              ) : null
            }
            {
              (!disabled && allowed.indexOf("photocamera") !== -1  && files.filter((fileData: any) => fileAllowedTypes["image"].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length < parseInt(fileMaxCount["image"]) && state.isPhotoCameraAvailable) ? (
                <div className={classes.fileWrapper}>
                  <Button className={classes.addFile} onClick={handleOpenPhotoCamera}>
                    <SVG src="camera"/>
                  </Button>
                  <span className={classes.captionName}>{t("camera")}</span>
                </div>
              ) : null
            }
            {
              (!disabled && allowed.indexOf("videocamera") !== -1  && files.filter((fileData: any) => fileAllowedTypes["video"].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length < parseInt(fileMaxCount["video"]) && state.isVideoCameraAvailable) ? (
                <div className={classes.fileWrapper}>
                  <Button className={classes.addFile} onClick={handleOpenVideoCamera}>
                    <SVG src="camera-video"/>
                  </Button>
                  <span className={classes.captionName}>{t("camera")}</span>
                </div>
              ) : null
            }
            {
              (!disabled && allowed.indexOf("screen") !== -1  && files.filter((fileData: any) => fileAllowedTypes["video"].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length < parseInt(fileMaxCount["video"]) && state.isScreenAvailable) ? (
                <div className={classes.fileWrapper}>
                  <Button className={classes.addFile} onClick={handleOpenScreenCamera}>
                    <SVG src="screen-record"/>
                  </Button>
                  <span className={classes.captionName}>{t("screen_record")}</span>
                </div>
              ) : null
            }
            {
              state.isLoading ? (
                <div className={classes.loading}>
                  <CircularProgress className={classes.spinner}/>
                </div>
              ) : null
            }
          </div>
        </div>
        <div className={classes.captions}>
        {
          fileTypes.filter((fileType) => allowed.indexOf(fileType) !== -1 && parseInt(fileMaxCount[fileType]) !== 0 && fileMaxSize[fileType] !== 0).map((fileType: any, key: any) => (
            <span className={classes.caption} key={`k_${key}`}>
              {parseInt(fileMaxCount[fileType]) === 1 && t(`maximum_count_${fileType}`, {count: fileMaxCount[fileType]})}
              {parseInt(fileMaxCount[fileType]) > 1 && parseInt(fileMaxCount[fileType]) < 5 && t(`maximum_count_${fileType}s`, {count: fileMaxCount[fileType]})}
              {(parseInt(fileMaxCount[fileType]) > 4 || parseInt(fileMaxCount[fileType]) === 0) && t(`maximum_count_more_${fileType}s`, {count: fileMaxCount[fileType]})}.&nbsp;
              {t('maximum_size_file', {fileSize: formatFileSize(fileMaxSize[fileType])})}.&nbsp;
              {fileType === "video" ? t('maximum_length_file', {maxLength: DateFormat(videoMaxLength * 1000, "minutesseconds", languageData, t)}) : null}
              <span>
                {files.filter((fileData: any) => fileAllowedTypes[fileType].indexOf(fileData.type) !== -1 && fileData.status !== "deleted" && fileData.status !== "canceled").length}/{fileMaxCount[fileType]}
              </span>
            </span>
          ))
        }
        </div>
      </div>
    </>
  );
};

export default FileInput;