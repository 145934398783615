import NormalButton from '../../Buttons/NormalButton';
import React from 'react';
import Sidebar from '../Sidebar';
import { createUseStyles } from 'react-jss';
import { useAppSelector } from '../../../hooks/redux-hooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

interface Props {
  useBackdrop?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    height: '100%',
    maxWidth: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: (props: Props) => {
      if(props.useBackdrop) return 'rgba(0,0,0,0.2)';
      else return 'transparent';
    },
    backdropFilter: (props: Props) => {
      if(props.useBackdrop) return 'saturate(150%) blur(2px)';
      else return '';
    },
    zIndex: theme.zIndex.banner,
  },
  premiumBanner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.colors.white,
    padding: '16px 40px',
    borderRadius: '10px',
    width: '600px',
    maxWidth: 'calc(100% - 80px)',
    gap: '10px',
    boxShadow: theme.shadows[2],
    [theme.breakpoints.down('md')]: {
      borderRadius: '0px',
    },
  },
  title: {
    fontSize: '36px',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
    },
  },
  link: {
    color: theme.colors.primaryBlue[500],
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  service: {
    fontSize: '24px',
  },
  button: {
    marginTop: '16px',
  },
}));

type PremiumBannerType = {
  service: any;
  useBackdrop?: boolean;
};

const PremiumBanner: React.FunctionComponent<PremiumBannerType> = ({ service, useBackdrop = true }) => {

  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useAppSelector((state: any) => state.user); 

  const classes = useStyles({
    useBackdrop: useBackdrop
  });

  const handleGetPremium = () => {
    navigate('/settings/premium');
  };
  
  const openMoreInfo = () => {
    window.open(userData.membership.infopage);
  };
  
  return (
    <div className={classes.root}>
      <div className={classes.premiumBanner}>
        <span className={classes.title}>
          <span>{t('premium')}</span> {t('parent').toLowerCase()}
        </span>
        {
          service === "photoArchive" ? (
            <>
              <span className={classes.service}>{t('photo_archive')}</span>
              <p>{t('premium_photo_archive')}</p>
            </>
          ) : service === "gallery" ? (
            <>
              <span className={classes.service}>{t('gallery')}</span>
              <p>{t('premium_gallery')}</p>
            </>
          ) : null
        }
        <span className={classes.link} onClick={openMoreInfo}>
          {t('premium_more_info')}
        </span>
        <NormalButton className={classes.button} buttonType="primary" onClick={handleGetPremium}>
          {userData.order.status === "issued" ? t("premium_continue") : t("premium_get")}
        </NormalButton>
      </div>
      {
        !useBackdrop ? (
          <Sidebar/>
        ) : null
      }
    </div>
  );
}

export default PremiumBanner;