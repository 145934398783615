import AuthenticatedImage from '../../Items/AuthenticatedImage';
import CloseButton from 'src/components/Buttons/CloseButton';
import DateFormat from '../../../utils/dateFormat';
import Modal from '../../../utils/modal';
import moment from '../../../utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback, useMemo } from 'react';
import SVG from '../../Images/SvgRenderer';
import { createNotification } from '../../../utils/createNotification';
import { createUseStyles } from 'react-jss';
import { deleteExcuseNotesAbsence, deleteExcuseNotesAbsenceGroup } from '../../../store/actions/excusenotes.actions';
import { getSchoolSettings } from 'src/utils/useFunctions';
import { isCypress } from '../../../utils/useCypress';
import { setExcuseNotesDetailModal, setExcuseNotesHandleModal, setSubstitutionsDetailModal } from '../../../store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  absenceApprovalStatusID: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "auto",
    margin: "20px",
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "20px 20px 0 20px",
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  body: {
    marginTop: "20px",
    position: 'relative',
    maxHeight: 'calc(100vh - 300px)',
    overflow: 'auto',
  },
  footer: {
    display: "flex",
    flexDirection: 'column',
    marginTop: "30px",
    gap: '15px',
    padding: "0 20px 20px 20px",
    '& > div': {
      display: 'flex',
      gap: '10px',
      width: '100%',
      justifyContent: "flex-end",
      '& > button > span': {
        zoom: '90%',
        [theme.breakpoints.down('sm')]: {
          zoom: '70%',
        },
      },
    },
    '& > span': {
      fontSize: '14px',
    },
  },
  item: {
    display: 'flex',
    paddingLeft: "25px",
    flexDirection: 'column',
    '& + div': {
      marginTop: '16px',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    color: theme.colors.grey[700],
    '& > svg': {
      width: '18.5px',
      height: '18.5px',
      marginRight: '8px',  
    }, 
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    fontSize: '14px',
    color: '#9B9EBB',
    maxHeight: '300px',
    overflow: 'auto',
    gap: '8px',
    '& > img': {
      width: '20px',
      height: '20px',
      marginRight: '8px',
      borderRadius: '100%',
      padding: '4px', 
    }, 
  },
  footerInfo: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    color: '#9B9EBB',
    paddingLeft: "25px",
    '& > p': {
      marginBottom: '0',
    },
  },
  childPhoto: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: '100%',
    margin: '0px 5px',
    position: 'relative',
    '& > div': {
      backgroundColor: 'transparent',
      borderRadius: '50%',
      minWidth: '50px',
      minHeight: '50px',
      width: '50px',
      height: '50px',
      maxWidth: '50px',
      maxHeight: '50px',
    },
    '& > p': {
      fontSize: '14px',
      marginLeft: '16px',
      maxWidth: '100%',
      textAlign: 'center',
      marginBottom: '0',
      color: '#9B9EBB',
    },
  },
  nickname: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',  
  },
  stick: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '0',
    right: '0',
    gap: '8px', 
    zIndex: '1',
  }, 
  lateExcuse: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '12px 8px',
    borderRadius: '10px 0 0 10px',
    color: theme.colors.systemRed[500],
    backgroundColor: theme.colors.systemRed[100],
    '& img': {
      width: '20px',
      height: '20px',
      marginRight: '5px',
    },
    '& svg': {
      width: '20px',
      height: '20px',
      marginRight: '5px',
      '--circle-color': theme.colors.alwaysWhite,
      '--ellipse-color': theme.colors.systemRed[500],
      '--path-first-color': theme.colors.systemRed[500],
      '--path-last-color': theme.colors.alwaysWhite,
    },
  },
  isGroup: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '12px 8px',
    borderRadius: '10px 0 0 10px',
    marginRight: '0',
    marginLeft: 'auto',
    color: theme.colors.primaryBlue[500],
    backgroundColor: theme.colors.grey[250],
    '& img': {
      width: '20px',
      height: '20px',
      marginRight: '5px',
    },
    '& svg': {
      color: 'inherit',
      width: '20px',
      height: '20px',
      marginRight: '5px',
    },
  },
  approvalStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: '12px',
    padding: '12px 8px',
    borderRadius: '10px 0 0 10px',
    marginRight: '0',
    marginLeft: 'auto',
    backgroundColor: (props: Props) => {
      if(props.absenceApprovalStatusID === 1) return theme.colors.systemGreen[100];
      else if(props.absenceApprovalStatusID === 2) return theme.colors.systemRed[100];
      else if(props.absenceApprovalStatusID === 3) return theme.colors.systemOrange[100];
      else return '';
    },
    color: (props: Props) => {
      if(props.absenceApprovalStatusID === 1) return theme.colors.systemGreen[500];
      else if(props.absenceApprovalStatusID === 2) return theme.colors.systemRed[500];
      else if(props.absenceApprovalStatusID === 3) return theme.colors.systemOrange[500];
      else return '';
    },
    '& img': {
      width: '20px',
      height: '20px',
      marginRight: '5px',
    },
    '& svg': {
      color: 'inherit',
      width: '20px',
      height: '20px',
      marginRight: '5px',
    },
  },
  tag: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
    padding: '2px 8px',
    width: 'fit-content',
    borderRadius: '10px',
    backgroundColor: '#EEEEEE',
    fontWeight: 'bold',
    marginTop: '8px',
    cursor: 'pointer',
  },
}));

const ExcuseNotesDetailModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const configurationData = useAppSelector((state: any) => state.configuration);
  const dataData = useAppSelector((state: any) => state.data);
  const excusenotesData = useAppSelector((state: any) => state.excusenotes);
  const languageData = useAppSelector((state: any) => state.language);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const absenceService = useAppSelector((state: any) => state.services).absenceService;

  const schoolSettings = userData.schoolSettings;

  const [state, setState] = useStates({
    canClick: true,
    isCustom: false,
    customData: [],
    isReady: false,
  });

  const absenceID = modalsData.excuseNotesDetailModal.absenceID;
  const childID = modalsData.excuseNotesDetailModal.childID;
  const type = modalsData.excuseNotesDetailModal.type;
  const readOnly = modalsData.excuseNotesDetailModal.readOnly;
  const list = excusenotesData.list;
  const absenceDataAll = useMemo(() => { return state.isCustom ? state.customData : (list.length > 0 && childID !== null && childID !== undefined) ? (list.filter((data: any) => data.childID === childID).length > 0 ? list.find((data: any) => data.childID === childID).dates : []) : []}, [childID, list, state.customData, state.isCustom]);
  const absenceData = useMemo(() => { return absenceDataAll.length > 0 ? (absenceDataAll.filter((data: any) => data.absenceID === absenceID).length === 0 ? {} : absenceDataAll.find((data: any) => data.absenceID === absenceID)) : {} }, [absenceID, absenceDataAll]);
  const absenceGroup = absenceDataAll.length > 0 ? (absenceDataAll.filter((data: any) => data.absenceGroupID === absenceData.absenceGroupID).sort((a: any, b: any) => { if (moment(a.date).unix() === moment(b.date).unix()) return b.absenceID - a.absenceID; return moment(a.date).unix() - moment(b.date).unix(); })) : [];
  const absenceTypes = configurationData.configuration.absenceTypes;
  const absenceType = absenceTypes.filter((absenceTypeData: any) => absenceTypeData.absenceTypeID === absenceData.absenceTypeID).length === 0 ? absenceTypes[0] : absenceTypes.find((absenceTypeData: any) => absenceTypeData.absenceTypeID === absenceData.absenceTypeID);
  const absenceApprovalStatuses = configurationData.configuration.absenceApprovalStatuses ? configurationData.configuration.absenceApprovalStatuses : [];
  const absenceApprovalStatus = absenceApprovalStatuses.length > 0 ? (absenceApprovalStatuses.filter((absenceApprovalStatusData: any) => absenceApprovalStatusData.absenceApprovalStatusID === absenceData.absenceApprovalStatusID).length === 0 ? absenceApprovalStatuses[0] : absenceApprovalStatuses.find((absenceApprovalStatusData: any) => absenceApprovalStatusData.absenceApprovalStatusID === absenceData.absenceApprovalStatusID)) : null;
  
  const getChildData = (childID: any) => {
    return dataData.children.filter((item: any) => item.childID === childID).length === 0 ? [] : dataData.children.find((item: any) => item.childID === childID);
  };

  const childData = getChildData(absenceData.childID);
  const schoolID = childData.schoolID;
  const enableNotes = Object.keys(absenceData).length > 0 ? (absenceData.note.length !== 0 ? true : (getSchoolSettings(schoolID, "modules", schoolSettings).absence && getSchoolSettings(schoolID, "modules", schoolSettings).absence.allowNotes) ? getSchoolSettings(schoolID, "modules", schoolSettings).absence.allowNotes : false) : false;

  const classes = useStyles({
    absenceApprovalStatusID: absenceData.absenceApprovalStatusID,
  });

  const getAbsenceApprovalStatusIcon = () => {
    if(absenceData.absenceApprovalStatusID === 1) return "absence-approved";
    else if(absenceData.absenceApprovalStatusID === 2) return "absence-disapproved"; 
    else if(absenceData.absenceApprovalStatusID === 3) return "absence-waiting"; 
    else return "";
  };

  const onCloseModal = useCallback(() => {
    const settings = {
      isOpen: false,
      date: null,
      absenceID: null,
      childID: null,
      type: null,
      readOnly: false,
    };
    dispatch(setExcuseNotesDetailModal(settings));
  }, [dispatch]);

  useEffect(() => {
    if(Object.keys(absenceData).length === 0) {
      absenceService && absenceService.findAbsence(absenceID).then((result: any) => {
        if(result) {
          if(result.data) {
            if(result.data.absence) {
              setState("customData", result.data.absence);
              setState("isCustom", true);
              setState("isReady", true);
            } else {
              createNotification(t("excuse_note_failed_load"), "error");
              onCloseModal();
            }
          } else {
            createNotification(t("excuse_note_failed_load"), "error");
            onCloseModal();
          }
        } else {
          createNotification(t("excuse_note_failed_load"), "error");
          onCloseModal();
        }
      }).catch(() => {
        createNotification(t("excuse_note_failed_load"), "error");
        onCloseModal();
      });
    } else {
      setState("isReady", true);
    }
  }, [absenceData, absenceID, absenceService, onCloseModal, setState, t], []);

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    if(state.canClick) {
      setState("canClick", false);
      absenceService && absenceService.deleteAbsence(absenceData.absenceID).then((result: any) => {
        const absences = result.data.absence;
        if(result.status === 200) {
          createNotification(t("excuse_note_deleted"),"success");
          const settings = {
            absenceID: absenceData.absenceID,
            childID: childData.childID,
            absences: absences,
          };
          dispatch(deleteExcuseNotesAbsence(settings));
          setState("canClick", true);
          onCloseModal();      
        } else {
          createNotification(t("excuse_note_not_deleted"),"error");
          setState("canClick", true);
        }
      }).catch(() => {
        createNotification(t("excuse_note_not_deleted"),"error");
        setState("canClick", true);
      });
    }
  };

  const handleDeleteGroup = (e: any) => {
    e.stopPropagation();
    if(state.canClick) {
      setState("canClick", false);
      absenceService && absenceService.deleteAbsenceGroup(absenceData.absenceGroupID).then((result: any) => {
        if(result.status === 204) {
          createNotification(t("excuse_note_group_deleted"),"success");
          const settings = {
            absenceGroupID: absenceData.absenceGroupID,
            childID: childData.childID,
          };
          dispatch(deleteExcuseNotesAbsenceGroup(settings));  
          setState("canClick", true);
          onCloseModal();  
        } else {
          createNotification(t("excuse_note_group_not_deleted"),"error");
          setState("canClick", true);
        }
      }).catch(() => {
        createNotification(t("excuse_note_group_not_deleted"),"error");
        setState("canClick", true);
      });
    }
  };

  const handleEdit = (e: any) => {
    e.stopPropagation();
    const settings = {
      isOpen: true,
      date: absenceData.date,
      child: childData,
      absenceID: absenceData.absenceID,
    };
    dispatch(setExcuseNotesHandleModal(settings));
  };

  const handleEditGroup = (e: any) => {
    e.stopPropagation();
    const settings = {
      isOpen: true,
      date: absenceData.date,
      child: childData,
      absenceID: absenceData.absenceID,
      absenceGroupID: absenceData.absenceGroupID,
    };
    dispatch(setExcuseNotesHandleModal(settings));
  };

  const handleOpenSubstitution = (substitutionID: any) => {
    const settings = {
      isOpen: true,
      substitutionID: parseInt(substitutionID),
      childID: childID,
      readOnly: true,
    };
    dispatch(setSubstitutionsDetailModal(settings));
  };
  
  return state.isReady ? (
    <Modal 
      open={true}
      onClose={onCloseModal}
    >
      {
        Object.keys(absenceData).length > 0 ? (
          <div className={classes.root} data-cy={isCypress() ? "excuseNotesDetailModal" : null}>
            <div className={classes.header}>
              <div className={classes.wrapper}>
                <p data-clarity-unmask="true">
                  {absenceGroup.length > 1 ? t('absence_group_detail') : t('absence_detail')}
                  &nbsp;
                  {
                    absenceGroup.length > 1 ? (
                      <>{DateFormat(moment(absenceGroup[0].date),"default", languageData, t)} - {DateFormat(moment(absenceGroup[absenceGroup.length - 1].date),"default", languageData, t)} </>
                    ) : (
                      <>{DateFormat(moment(absenceData.date),"default", languageData, t)} </>
                    )
                  }</p>
              </div>
              <CloseButton onClick={handleClose} dataCy="timesButton"/>
            </div>
            <div className={classes.body}>
              <div className={classes.item}>
                <span className={classes.title}>
                  <SVG src="user-circle-outlined"/>
                  {t('child')}
                  <div className={classes.stick}>
                    {
                      absenceData.isLate ? (
                        <div className={classes.lateExcuse}>
                          <SVG src="late"/> {t("late_note")}
                        </div>
                      ) : null
                    }
                    {
                      absenceGroup.length > 1 ? (
                        <div className={classes.isGroup}>
                          <SVG src="calendar"/> {t("absence_group")}
                        </div>             
                      ) : null
                    }
                    {
                      (absenceData.absenceApprovalStatusID && absenceData.absenceApprovalStatusID !== 0 && absenceApprovalStatuses.length > 0) ? (
                        <div className={classes.approvalStatus}>
                          <SVG src={getAbsenceApprovalStatusIcon()}/> {absenceApprovalStatus.name}
                        </div> 
                      ) : null
                    }
                  </div>
                </span>
                <div className={classes.childPhoto}>
                  <AuthenticatedImage thumbLink={childData.photo?.thumbLink}/>
                  <p className={classes.nickname}>{childData.displayName}</p>
                </div>
              </div>
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="clock"/>
                  {t('date')}
                </p>
                <p className={classes.detail} data-clarity-unmask="true">
                  {DateFormat(moment(absenceData.date),"default", languageData, t)} &bull; {t(absenceData.absencePartID === 1 ? "whole_day" : absenceData.absencePartID === 2 ? "morning" : "afternoon" )}
                </p>
              </div>
              <div className={classes.item}>
                <p className={classes.title}>
                  <SVG src="info-circle-outlined"/>
                  {t('reason')}
                </p>
                <p className={classes.detail}>
                  <img src={absenceType.image} style={{backgroundColor: absenceType.color}} alt={absenceType.name}/> {absenceType.name}
                </p>
              </div>
              {
                (absenceData.note && enableNotes) ? (        
                  <div className={classes.item}>
                    <p className={classes.title}>
                      <SVG src="question-mark-circle"/>
                      {t('notes')}
                    </p>
                    <p className={classes.detail}>
                      {absenceData.note}
                    </p>
                  </div>
                ) : null
              }
              {
                (absenceData.substitutionID.length > 0 && !readOnly) ? (
                  <div className={classes.item}>
                    <p className={classes.title}>
                      <SVG src="calendar-substitutions"/>
                      {absenceData.substitutionID.length === 1 ? t('excuse_notes_paired_substitution') : t('excuse_notes_paired_substitutions')}
                    </p>
                    <p className={classes.detail}>
                      {
                        absenceData.substitutionID.length === 1 ? (
                          <span className={classes.tag} onClick={() => handleOpenSubstitution(absenceData.substitutionID[0])}>{t('substitution')}</span>
                        ) : (
                          <>
                          {
                            absenceData.substitutionID.map((item: any, key: any) => (
                              <span className={classes.tag} onClick={() => handleOpenSubstitution(item)} key={`k_${key}`}>{t('substitution')} {key + 1}.</span>
                            ))
                          }
                          </>
                        )
                      }
                    </p>
                  </div>
                ) : null
              }
              <div className={classes.footerInfo}>
                <p data-clarity-unmask="true">{t('author')}: {absenceData.authorName}</p>
                <p data-clarity-unmask="true">{t('created')}: {DateFormat(moment(absenceData.createdAt),"daymonthname", languageData, t)} &bull; {DateFormat(moment(absenceData.createdAt),"time", languageData, t)}</p>
              </div>
            </div>
            {
              !readOnly ? (
                <div className={classes.footer}>
                {
                  (absenceData.isEditable && !childData.isArchived && (type === "single" || type === "both")) ? (
                    <div>
                      <NormalButton buttonType="secondary" onClick={handleDelete} disabled={!state.canClick} dataCy="deleteAbsenceButton">
                        {t("delete_absence")}
                      </NormalButton>
                      <NormalButton buttonType="primary" onClick={handleEdit} disabled={!state.canClick} dataCy="editAbsenceButton">
                        {t("edit_absence")}
                      </NormalButton>
                    </div>
                  ) : null
                }
                {
                  (absenceData.isEditable && !childData.isArchived && absenceGroup.length > 1 && (type === "group" || type === "both") && !moment(absenceGroup[0].date).isBefore(moment())) ? (
                    <div>
                      <NormalButton buttonType="secondary" onClick={handleDeleteGroup} disabled={!state.canClick} dataCy="deleteAbsenceGroupButton">
                        {t("delete_absence_group")}
                      </NormalButton>
                      <NormalButton buttonType="primary" onClick={handleEditGroup} disabled={!state.canClick} dataCy="editAbsenceGroupButton">
                        {t("edit_absence_group")}
                      </NormalButton>
                    </div>
                  ) : null
                }
                {
                  !absenceData.isEditable && absenceData.substitutionID.length > 0 ? (
                    <span>{t('excuse_note_not_editable_because_substitution')}</span>
                  ) : null
                }
                </div>
              ) : (
                <div className={classes.footer}/>
              )
            }
          </div>
        ) : (
          <></>
        )
      }
    </Modal>
  ) : null;
};

export default ExcuseNotesDetailModal;
