import CircularProgress from '@mui/material/CircularProgress';
import config from '../../../constants/config';
import React, { useCallback } from 'react';
import { createUseStyles } from 'react-jss';
import { getAppAccessToken } from 'src/utils/useApp';
import { InView } from 'react-intersection-observer';
import { isCypress } from 'src/utils/useCypress';
import { removeParamsFromUrl } from 'src/utils/useFunctions';
import { setImageModal } from '../../../store/actions/modals.actions';
import { setImages } from '../../../store/actions/medias.actions';
import { Skeleton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMemo } from 'src/utils/useMemo';
import { useStates } from 'src/utils/useState';

interface Props {
  isLoaded?: any;
  isLoadedThumb?: any;
  image?: any;
  imageThumb?: any;
  placeholderSize?: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    position: 'relative',
    overflow: 'hidden',
    outline: 'none',
    '&.pointer': {
      cursor: 'pointer',
    }
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  loadingResponsive: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  renderedImage: {
    width: '100%',
    height: '100%',
    backgroundColor: 'inherit',
    backgroundImage: (props: Props) => {
      if(props.isLoadedThumb && !props.isLoaded) return `url(${props.imageThumb})`;
      else return `url(${props.image})`;
    },
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    maxWidth: 'inherit',
    minWidth: 'inherit',
    maxHeight: 'inherit',
    minHeight: 'inherit',
    borderRadius: 'inherit',
    borderStyle: 'inherit',
    borderWidth: 'inherit',
    borderColor: 'inherit',  
  },
  placeholder: {
    display: 'block',
    backgroundColor: theme.colors.grey[125],
    width: (props: Props) => props.placeholderSize.width + 'px',
    height: (props: Props) => props.placeholderSize.height + 'px',
  },
  skeleton: {
    width: '100%',
    height: '100%',
    filter: 'unset !important',
    backgroundColor: 'rgba(0, 0, 0, 0.11) !important',
  },
}));

type ImageRendererType = {
  thumbLink?: any,
  fullsizeLink?: any,
  preferQuality?: 'thumb' | 'fullsize',
  isResponsive?: boolean,
  className?: any,
  isClickable?: boolean,
  useThumb?: boolean,
  useLoader?: boolean,
  onClick?: any,
  onLoad?: any,
  style?: any,
  customAccessToken?: any;
  dataCy?: any;
  ignoreInView?: boolean;
  ignoreIsLoaded?: boolean;
};

const ImageRenderer: React.FunctionComponent<ImageRendererType> = ({ thumbLink = null, fullsizeLink = null, preferQuality = "thumb", isResponsive = true, useThumb = false, className, isClickable = false, useLoader = false, onClick, onLoad, style, customAccessToken, dataCy, ignoreInView, ignoreIsLoaded }) => {

  const [state, setState] = useStates({
    isLoaded: false,
    isLoadedThumb: false,
    isInView: false,
  });

  const dispatch = useAppDispatch();
  const mediasData: any = useAppSelector((state: any) => state.medias); 
  const imagesData: any = useMemo(() => mediasData.images ? mediasData : {images: []}, [mediasData]);
  
  const imageUrl = useMemo(() => preferQuality === "thumb" ? (thumbLink ? thumbLink : (fullsizeLink ? fullsizeLink : config.IMAGE_PLACEHOLDER)) : preferQuality === "fullsize" ? (fullsizeLink ? fullsizeLink : (thumbLink ? thumbLink : config.IMAGE_PLACEHOLDER)) : config.IMAGE_PLACEHOLDER, [fullsizeLink, thumbLink, preferQuality]);
  const imageSrc = useMemo(() => imagesData.images.filter((img: any) => img.url === imageUrl).length === 0 ? imageUrl : imagesData.images.find((img: any) => img.url === imageUrl).image, [imageUrl, imagesData.images]);
  
  const imageThumbUrl = useMemo(() => useThumb ? (thumbLink ? thumbLink : config.IMAGE_PLACEHOLDER) : config.IMAGE_PLACEHOLDER, [thumbLink, useThumb]);
  const imageThumbSrc = useMemo(() => imagesData.images.filter((img: any) => img.url === imageThumbUrl).length === 0 ? imageThumbUrl : imagesData.images.find((img: any) => img.url === imageThumbUrl).image, [imageThumbUrl, imagesData.images]);
  
  const placeholderSize = useMemo(() => imagesData.images.filter((img: any) => img.url === imageUrl).length === 0 ? {width: 0, height: 0} : imagesData.images.find((img: any) => img.url === imageUrl).sizes, [imageUrl, imagesData.images]);

  const accessToken = customAccessToken ? customAccessToken : getAppAccessToken();

  const classes = useStyles({ 
    isLoaded: state.isLoaded,
    isLoadedThumb: state.isLoadedThumb,
    image: imageSrc,
    imageThumb: imageThumbSrc,
    placeholderSize: placeholderSize
  });

  const isLoaded = ignoreIsLoaded ? true : state.isLoaded;

  const loadImage = useCallback((getImage: any, isThumb: any) => {
    if(isThumb) {
      setState("isLoadedThumb", true);
    } else {
      if(onLoad) onLoad(getImage);
      setState("isLoaded", true);
    }
  }, [onLoad, setState]);
  
  const fetchImage = useCallback((src: any, isThumb?: any, saveAs?: any) => {
    const storeAs = removeParamsFromUrl(saveAs ? saveAs : src);
    if(imagesData.images.find((img: any) => img.url === storeAs)) {
      const imageObj = imagesData.images.find((img: any) => img.url === storeAs);
      loadImage(imageObj, isThumb);    
    } else {
      const headers = new Headers();
      if(src && typeof src === "string" && src.includes("twigsee.com")) {
        headers.set('Authorization', `Bearer ${accessToken}`);
      } 
      fetch(src, {headers}).then(async (response) => {
        if(response.status === 200) {
          /*
          if(response.redirected) {
            fetchImage(response.url, isThumb, src);
          } else {
          */
          const binaryData: any = await response.arrayBuffer();
          const bytes = new Uint8Array(binaryData);
          const len = bytes.byteLength;
          let string = '';
          for (let i = 0; i < len; i++) {
            string += String.fromCharCode(bytes[i]);
          }
          if(string === "Error: Could not load image !") {
            fetchImage(config.IMAGE_PLACEHOLDER);
            return;
          }
          const getBase64 = btoa(string);
          const getImage = `data:image/png;base64,${getBase64}`;
          const img: any = new Image();
          img.onload = function() {
            const sizes = {
              width: img.width,
              height: img.height,
            };
            const imageObj = { base64: getBase64, image: getImage, url: storeAs, sizes: sizes };
            dispatch(setImages(imageObj));
            loadImage(imageObj, isThumb);
          }
          img.src = getImage;
          //}
        } else {
          fetchImage(config.IMAGE_PLACEHOLDER);
        }
      }).catch(() => {
        fetchImage(config.IMAGE_PLACEHOLDER);
      });
    }
  }, [dispatch, imagesData.images, loadImage, accessToken]); 
  
  const updateView = (inView: any) => {
    setState("isInView", inView);
  };
  
  const handleClick = () => {
    if(isClickable) {
      const settings = {
        isOpen: true,
        thumbLink: thumbLink,
        fullsizeLink: fullsizeLink,
      };
      dispatch(setImageModal(settings));
    } else {
      if(onClick) {
        onClick();
      }
      return false;
    }
  };
  
  useEffect(() => {
    if(state.isInView) {
      if(useThumb) {
        fetchImage(imageThumbUrl, true);
        fetchImage(imageUrl, false);
      } else {
        fetchImage(imageUrl, false);
      }
    }
  }, [thumbLink, fullsizeLink, preferQuality, fetchImage, imageUrl, imageThumbUrl, useThumb, state.isInView], [thumbLink, fullsizeLink, preferQuality, state.isInView]);

  return ignoreInView ? (
    <InView onChange={(inView) => updateView(inView)} className={`${classes.root} ${className}`} style={style} onClick={handleClick} data-cy={isCypress() ? dataCy : null}>
      {
        (!isResponsive && isLoaded) ? (
          <img src={imageSrc} className={`${className} ${classes.root} ${isClickable && 'pointer'}`} alt="item"/>        
        ) : null
      }
      {
        (isResponsive && isLoaded) ? (                                    
          <div className={classes.renderedImage}/>
        ) : null
      }
    </InView>
  ) : (
    <InView onChange={(inView) => updateView(inView)} className={`${classes.root} ${className}`} style={style} onClick={handleClick} data-cy={isCypress() ? dataCy : null}>
      {
        ((!state.isLoaded && !state.isLoadedThumb) && state.isInView && useLoader) ? (
          <div className={`${isResponsive ? classes.loadingResponsive : classes.loading} ${"loader"}`}>
            <CircularProgress className={classes.spinner}/>
          </div>
        ) : null
      }
      {
        ((!state.isLoaded && !state.isLoadedThumb) && state.isInView && !useLoader) ? (
          <Skeleton className={classes.skeleton} variant="rectangular" animation="wave" component={"div"}/>
        ) : null
      }
      {
        ((state.isLoaded || state.isLoadedThumb) && !isResponsive && state.isInView) ? (
          <img src={imageSrc} className={`${className} ${classes.root} ${isClickable && 'pointer'}`} alt="item"/>        
        ) : null 
      }
      {
        ((state.isLoaded || state.isLoadedThumb) && !isResponsive && !state.isInView) ? (
          <span className={classes.placeholder}/>        
        ) : null 
      }
      {
        ((state.isLoaded || state.isLoadedThumb) && isResponsive && state.isInView) ? (
          <>                                            
            <div className={classes.renderedImage}/>
            {
              (state.isLoadedThumb && !state.isLoaded) ? (
                <span className={`${classes.loadingResponsive} ${"loader"}`}>
                  <CircularProgress className={classes.spinner}/>
                </span>
              ) : null
            }    
          </>
        ) : null
      }
    </InView>
  );
}

export default ImageRenderer;