import CheckboxInput from 'src/components/Forms/CheckboxInput';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import NormalButton from 'src/components/Buttons/NormalButton';
import NotFound from 'src/components/Layouts/NotFound';
import React from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { checkObjectKeyInArray } from 'src/utils/useFunctions';
import { CircularProgress } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { ReactSortable } from 'react-sortablejs';
import { useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';
import Tooltip from 'src/components/Layouts/Tooltip';

const useStyles = createUseStyles((theme: any) => ({
  columnsHandlerWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%',
    gap: '16px',
  },
  columnsWrapper: {
    maxHeight: 'calc(100% - 64px)',
    overflow: 'auto',
    borderRadius: '24px',
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.grey[100],
  },
  groupsToolbar: {
    display: "flex",
    gap: '10px',
    justifyContent: "flex-start",
    backgroundColor: theme.colors.white,
    position: 'sticky',
    top: '0',
    overflow: 'hidden',
    width: 'calc(100% - 48px)',
    minHeight: '42px',
    padding: '9px 24px',
    zIndex: '2',
    flexWrap: 'wrap',
    boxShadow: theme.shadows[2],
  },
  groups: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '9px 24px',
    width: 'calc(100% - 48px)',
    height: 'fit-content',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  groupHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: '8px',
    '& > span': {
      display: 'flex',
      gap: '8px',
      fontWeight: '600',
      alignItems: 'center',
    },
    '& > button': {
      backgroundColor: theme.colors.primaryBlue[500],
      color: theme.colors.white,
      '&:hover': {
        backgroundColor: theme.colors.primaryBlue[600],
        color: theme.colors.white,
      },
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  columns: {
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
    maxWidth: 'calc(100% - 48px)',
    padding: '0 24px',
    [theme.breakpoints.down('md')]: {
      gap: '8px',
    },
  },
  input: {
    width: '300px',
  },
  checkbox: {
    marginLeft: 'auto',
  },
  column: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    padding: '2px 13px 2px 4px',
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: theme.colors.grey[500],
    borderRadius: '12px',
    position: 'relative',
    maxWidth: '100%',
    '& > button': {
      position: 'absolute',
      top: '-12px',
      right: '-8px',
      backgroundColor: theme.colors.systemRed[500],
      color: theme.colors.white,
      '&:hover': {
        backgroundColor: theme.colors.systemRed[400],
        color: theme.colors.white,
      },
      '& > svg': {
        width: '8px',
        height: '8px',
      },
    },
    '& > div': {
      maxWidth: '100%',
    },
    '& label': {
      wordBreak: 'break-word',
      whiteSpace: 'break-spaces',
      maxWidth: '100%',
    },
    [theme.breakpoints.down('md')]: {
      padding: '2px 8px 2px 2px',
      '& > div': {
        '& > span': {
          padding: '0',
        },
        '& > label': {
          fontSize: '12px',
        },
      },
    },
  },
  columnButton: {
    padding: '0px 0px 0px 12px',
    position: 'relative',
    '& svg': {
      width: '16px',
      height: '16px',
    },
    '& > span.MuiButton-icon': {
      padding: '9px 0',
    },
    '& > span:not(.MuiButton-icon):not(.MuiTouchRipple-root)': {
      height: '100%',
      width: '100%',
      '& > span': {
        height: '100%',
        width: '100%',
        padding: '0px 12px 0px 12px',
        borderRadius: '0px 7px 7px 0px',
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
      },
    },
  },
  customColumnButton: {
    padding: '9px 0px 9px 12px',
    position: 'relative',
    '&.disabled': {
      cursor: 'default',
    },
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  customColumnInput: {
    width: '300px',
    '& > div': {
      borderWidth: '0px',
      borderRadius: '0px 7px 7px 0px',
    }, 
  },
  customColumnDeleteButton: {
    position: 'absolute',
    top: '-12px',
    right: '-8px',
    width: '24px',
    height: '24px',
    borderRadius: '100%',
    backgroundColor: theme.colors.systemRed[500],
    color: theme.colors.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.systemRed[400],
      color: theme.colors.white,
    },
    '& > svg': {
      width: '8px',
      height: '8px',
    },
  },
  footer: {
    width: '100%',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    height: '42px',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    minHeight: '50vh',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  sortableColumns: {
    width: 'fit-content',
    minWidth: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    overflow: 'auto',
    height: 'calc(100% - 64px)',
    maxHeight: 'calc(100% - 64px)',
  },
  sortableColumn: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    padding: '4px 8px',
    borderWidth: '1px',
    borderStyle: 'dashed',
    borderColor: theme.colors.grey[500],
    borderRadius: '12px',
    cursor: 'grab',
    '&:active': {
      cursor: 'grabbing',
    },
    minWidth: '50%',
    width: 'fit-content',
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  badge: {
    display: 'inline-flex',
    height: 'fit-content',
    fontStyle: 'unset',
    fontSize: '12px',
    padding: '3px 5px',
    borderRadius: '6px',
    backgroundColor: theme.colors.grey[200],
    color: theme.colors.black,
  },
  settings: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  iconButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px',
    height: '32px',
    backgroundColor: theme.colors.grey[200],
    borderRadius: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.grey[250],
    },
    '& > svg': {
      width: '20px',
      height: '20px',
      color: theme.colors.black,
    },
  },
}));

type ColumnsHandlerType = {
  mode: "edit" | "order";
  columns: any,
  columnsOrdered?: any,
  allColumns?: any;
  handleSetAllColumns?: any;
  onFinish: any;
  onBack: any;
};

const ColumnsHandler: React.FunctionComponent<ColumnsHandlerType> = ({ mode = "edit", allColumns, handleSetAllColumns, columns = [], columnsOrdered = [], onFinish, onBack }) => {

  const { t } = useTranslation();
  const classes = useStyles();
  const exportService = useAppSelector((state: any) => state.services).exportService;

  const oldDesign = true;

  const [state, setState] = useStates({
    columns: (allColumns && allColumns.length !== 0) ? allColumns : [],
    selectedColumns: [],
    customColumns: [],
    selectedCustomColumns: [],
    orderedColumns: columnsOrdered.length === 0 ? columns : columnsOrdered,
    search: "",
  });

  const orderableColumns = state.orderedColumns.filter((item: any) => (item.name && item.active) || item.key).map((item: any, key: any) => {
    if(!item.key) {
      return {...item, key: `webAppCustomColumn${key}`};
    } else {
      return item;
    }
  });

  const allDefaultColumns = state.columns.map((item: any) => item.columns.map((subItem: any) => { return {...subItem, groupName: item.groupName}; }).flat()).flat();
  const customColumns = state.customColumns;
  const customAvailableColumns = customColumns.filter((item: any) => item.name.length !== 0);
  const countOfColumns = allDefaultColumns.length;
  const countOfSelectedColumns = state.selectedColumns.length;
  const countOfCustomColumns = customColumns.length;
  const countOfAvailableCustomColumns = customAvailableColumns.length;
  const countOfSelectedCustomColumns = state.selectedCustomColumns.length;

  const visibleColumns = state.columns.map((item: any) => {
    const columns = state.search.length === 0 ? item.columns : item.columns.filter((subItem: any) => subItem.name.toLowerCase().includes(state.search.toLowerCase()));
    return { ...item, columns: columns};
  }).filter((item: any) => item.columns.length !== 0);

  const saveDefaultColumns = state.selectedColumns;
  const saveCustomColumns = state.customColumns.map((item: any) => { return { name: item.name, active: state.selectedCustomColumns.filter((subItem: any) => subItem.key === item.key).length === 1 }});
  
  const saveColumns = [...saveDefaultColumns, ...saveCustomColumns];

  const handleSearch = (value: any) => {
    setState("search", value);
  };

  const handleChange = () => {
    if(mode === "edit") {
      onFinish(saveColumns);
    } else {
      onFinish(state.orderedColumns);
    }
  };

  const handleBack = () => {
    onBack();
  };

  const handleSelectColumn = (value: any) => {
    if(checkObjectKeyInArray(state.selectedColumns, "key", value.key)) {
      setState("selectedColumns", state.selectedColumns.filter((item: any) => item.key !== value.key));
    } else {
      setState("selectedColumns", [...state.selectedColumns, value]);
    }
  };

  const handleSelectAllColumns = () => {
    if(countOfColumns === countOfSelectedColumns && countOfAvailableCustomColumns === countOfSelectedCustomColumns) {
      setState("selectedColumns", []);
      setState("selectedCustomColumns", []);
    } else {
      setState("selectedColumns", allDefaultColumns);
      setState("selectedCustomColumns", customAvailableColumns);
    }
  };

  const handleSelectAllColumnsInGroup = (columns: any) => {
    if(columns.length === columns.filter((item: any) => state.selectedColumns.includes(item)).length) {
      const newSelectedColumns = state.selectedColumns.filter((column: any) => !columns.some((groupColumn: any) => groupColumn.key === column.key));
      setState("selectedColumns", newSelectedColumns);
    } else {
      const newSelectedColumns = [...state.selectedColumns.filter((column: any) => !columns.some((groupColumn: any) => groupColumn.key === column.key)), ...columns];
      setState("selectedColumns", newSelectedColumns);
    }
  };

  const handleAddCustomColumn = () => {
    const newColumn = {key: `webAppCustomColumn${countOfCustomColumns}`, name: ''};
    setState("customColumns", [...state.customColumns, newColumn]);
  };

  const handleClickCustomColumn = (_: any, __: any, e: any) => {
    e.stopPropagation();
  };

  const handleChangeCustomColumn = (key: any, name: any) => {
    const customColumns = state.customColumns.map((item: any) => {
      if(item.key === key) {
        return {...item, name: name};
      } else {
        return item;
      }
    });
    if(name.length === 0) {
      setState("selectedCustomColumns", state.selectedCustomColumns.filter((item: any) => item.key !== key));
    }
    setState("customColumns", customColumns);
  };

  const handleSelectCustomColumn = (value: any) => {
    if(value.name.length === 0) return;
    if(checkObjectKeyInArray(state.selectedCustomColumns, "key", value.key)) {
      setState("selectedCustomColumns", state.selectedCustomColumns.filter((item: any) => item.key !== value.key));
    } else {
      setState("selectedCustomColumns", [...state.selectedCustomColumns, value]);
    }
  };

  const handleRemoveCustomColumn = (e: any, key: any) => {
    e.stopPropagation();
    const customColumns = state.customColumns.filter((item: any) => item.key !== key);
    setState("selectedCustomColumns", state.selectedCustomColumns.filter((item: any) => item.key !== key));
    setState("customColumns", customColumns);
  };

  const setOrderedColumns = (value: any) => {
    setState("orderedColumns", value);
  };

  const handleMoveColumn = (e: any, key: any, direction: 'up' | 'down') => {
    e.stopPropagation();
    e.preventDefault();
    const currentIndex = orderableColumns.findIndex((item: any) => item.key === key);
    if(currentIndex === -1 || (direction === 'up' && currentIndex === 0) || (direction === 'down' && currentIndex === orderableColumns.length - 1)) {
      return;
    }
    const newSortedColumns = [...orderableColumns];
    const targetIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
    [newSortedColumns[currentIndex], newSortedColumns[targetIndex]] = [newSortedColumns[targetIndex], newSortedColumns[currentIndex]];
    setState("orderedColumns", newSortedColumns);
  };

  useEffect(() => {
    if(state.columns.length === 0) {
      exportService && exportService.exportReportGetColumns("children").then((result: any) => {
        if(result && result.data && result.data.groups) {
          setState("columns", result.data.groups);
          handleSetAllColumns(result.data.groups);
        }
      }).catch((e: any) => {

      });
    }
  }, [exportService, setState, state.columns, handleSetAllColumns], []);

  useEffect(() => {
    if(columns.length > 0 && state.columns.length > 0) {
      const defaultColumns = columns.filter((item: any) => item.key).map((item: any) => {
        const getName = allDefaultColumns.filter((subItem: any) => subItem.key === item.key).length === 1 ? allDefaultColumns.find((subItem: any) => subItem.key === item.key).name : (item.name ? item.name : "");
        return { key: item.key, name: getName };
      });
      const customColumns = columns.filter((item: any) => !item.key).map((item: any, key: any) => {
        return { key: `webAppCustomColumn${key + 1}`, name: item.name };
      });
      const customSelectedColumns = columns.filter((item: any) => !item.key && item.active).map((item: any, key: any) => {
        return { key: `webAppCustomColumn${key + 1}`, name: item.name };
      });
      setState("selectedColumns", defaultColumns);
      setState("customColumns", customColumns);
      setState("selectedCustomColumns", customSelectedColumns);
    }
  }, [columns, setState, allDefaultColumns, state.columns], [columns, state.columns]);

  return state.columns.length === 0 && state.search === "" ? (
    <div className={classes.columnsHandlerWrapper}>
      <div className={classes.loading}>
        <CircularProgress className={classes.spinner}/>
      </div>
    </div>
  ) : (
    <>
      {
        mode === "edit" ? (
          <div className={classes.columnsHandlerWrapper}>
            <div className={classes.columnsWrapper}>
              <div className={classes.groupsToolbar}>
                <Input className={classes.input} prepend={<SVG src="search"/>} type="text" placeholder={`${t('search')}...`} useName={false} onChange={handleSearch}/>
                <CheckboxInput className={classes.checkbox} label={t('press_report_select_all_columns')} checked={countOfColumns === countOfSelectedColumns && countOfAvailableCustomColumns === countOfSelectedCustomColumns} onChange={handleSelectAllColumns}/>
              </div>
              <div className={classes.groups}>
                {
                  visibleColumns.length > 0 ? (
                    <>
                      {
                        visibleColumns.map((group: any, key: any) => {
                          return (
                          <div className={classes.group} key={`k_${key}`}>
                            <div className={classes.groupHeader}>
                              <CheckboxInput checked={group.columns.every((item: any) => state.selectedColumns.some((stateCol: any) => stateCol.key === item.key))} onChange={() => handleSelectAllColumnsInGroup(group.columns)} tooltip={t('press_report_select_all_columns_of_group')} tooltipMaxWidth={400}/>
                              <span>{group.groupName} ({group.columns.filter((item: any) => state.selectedColumns.some((stateCol: any) => stateCol.key === item.key)).length}/{group.columns.length})</span>
                            </div>
                            <div className={classes.columns}>
                            {
                              group.columns.map((item: any, key: any) => oldDesign ? (
                                <div className={classes.column} key={`k_${key}`}>
                                  <CheckboxInput label={item.name} name={item.key} value={item} checked={checkObjectKeyInArray(state.selectedColumns, "key", item.key)} onChange={handleSelectColumn}/>
                                </div>
                              ) : (
                                <NormalButton className={classes.columnButton} buttonType={checkObjectKeyInArray(state.selectedColumns, "key", item.key) ? 'primary' : 'primaryOutlined'} key={`k_${key}`} onClick={() => handleSelectColumn(item)} startIcon={checkObjectKeyInArray(state.selectedColumns, "key", item.key) ? (<SVG src="checkmark-circle"/>) : (<SVG src="circle-outlined"/>)}>
                                  <span>{item.name}</span>
                                </NormalButton>
                              ))
                            }
                            </div>
                          </div>
                        )
                        })
                      }
                    </>
                  ) : (
                    <NotFound text={t('no_columns_found')}/>
                  )
                }
                <div className={classes.group}>
                  <div className={classes.groupHeader}>
                    <span>{t('press_report_custom_columns')}</span>
                    <IconButton onClick={handleAddCustomColumn} tooltip={t('press_report_add_custom_column')} tooltipPosition='bottom' tooltipMaxWidth={400}>
                      <SVG src="plus"/>
                    </IconButton>
                  </div>
                  <div className={classes.columns}>
                    {
                      state.customColumns.map((item: any, key: any) => oldDesign ? (
                        <div className={classes.column}>
                          <CheckboxInput name={item.key} value={item} checked={checkObjectKeyInArray(state.selectedCustomColumns, "key", item.key)} key={`k_${key}`} onChange={handleSelectCustomColumn} disabled={item.name.length === 0}/>
                          <Input className={classes.input} type="text" name={item.key} value={item.name} placeholder={`${t('press_report_custom_column_placeholder')}...`} useName={true} onChange={handleChangeCustomColumn}/>
                          <IconButton onClick={(e: any) => handleRemoveCustomColumn(e, item.key)} tooltip={t('press_report_remove_custom_column')} tooltipPosition='top' tooltipMaxWidth={400}>
                            <SVG src="close"/>
                          </IconButton>
                        </div>
                      ) : (
                        <NormalButton className={`${classes.customColumnButton} ${item.name.length === 0 ? 'disabled' : null}`} buttonType={checkObjectKeyInArray(state.selectedCustomColumns, "key", item.key) ? 'primary' : 'primaryOutlined'} key={`k_${key}`} onClick={() => handleSelectCustomColumn(item)} disableRipple={true} startIcon={checkObjectKeyInArray(state.selectedCustomColumns, "key", item.key) ? (<SVG src="checkmark-circle"/>) : (<SVG src="circle-outlined"/>)}>
                          <Input className={classes.customColumnInput} type="text" name={item.key} value={item.name} placeholder={`${t('press_report_custom_column_placeholder')}...`} useName={true} onClick={handleClickCustomColumn} onChange={handleChangeCustomColumn}/>
                          <Tooltip title={t('press_report_remove_custom_column')} position='top' maxWidth={400}>
                            <div className={classes.customColumnDeleteButton} onClick={(e: any) => handleRemoveCustomColumn(e, item.key)}>
                              <SVG src="close"/>
                            </div>
                          </Tooltip>
                        </NormalButton>
                      ))
                    }
                    {
                      state.customColumns.length === 0 ? (
                        <span>{t('press_report_no_custom_column_found')}</span>
                      ) : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.footer}>
              <NormalButton buttonType='secondary' onClick={handleBack}>{t('back')}</NormalButton>
              <div className={classes.buttons}>
                <NormalButton buttonType='primary' onClick={handleChange} disabled={state.selectedColumns.length === 0 && state.selectedCustomColumns.length === 0}>
                  {t('continue')}
                </NormalButton>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.columnsHandlerWrapper}>
            <ReactSortable className={classes.sortableColumns} list={state.orderedColumns} setList={setOrderedColumns}>
              {
                orderableColumns.map((item: any, key: any) => {
                  const getName = allDefaultColumns.filter((subItem: any) => subItem.key === item.key).length === 1 ? allDefaultColumns.find((subItem: any) => subItem.key === item.key).name : (item.name ? item.name : "");
                  const getGroupName = allDefaultColumns.filter((subItem: any) => subItem.key === item.key).length === 1 ? allDefaultColumns.find((subItem: any) => subItem.key === item.key).groupName : (item.groupName ? item.groupName : "");
                  return (
                    <div className={classes.sortableColumn} key={key}>
                      <div className={classes.info}>
                        {getName}
                        <span className={classes.badge}>
                          {getGroupName}
                        </span>
                      </div>
                      <div className={classes.settings}>
                        {
                          key !== 0 ? (
                            <span className={classes.iconButton} onClick={(e: any) => handleMoveColumn(e, item.key, "up")}>
                              <SVG src="chevron-up"/>
                            </span>
                          ) : null
                        }
                        {
                          (key + 1) !== orderableColumns.length ? (
                            <span className={classes.iconButton} onClick={(e: any) => handleMoveColumn(e, item.key, "down")}>
                              <SVG src="chevron-down"/>
                            </span>
                          ) : null
                        }
                        </div>
                    </div>  
                  ); 
                })
              }
            </ReactSortable>
            <div className={classes.footer}>
              <NormalButton buttonType='secondary' onClick={handleBack}>{t('back')}</NormalButton>
              <div className={classes.buttons}>
                <NormalButton buttonType='primary' onClick={handleChange}>
                  {t('continue')}
                </NormalButton>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
};

export default ColumnsHandler;