import CheckIcon from '@mui/icons-material/Check';
import htmlParse from 'html-react-parser';
import Input from 'src/components/Forms/Input';
import Label from 'src/components/Forms/Label';
import NormalButton from 'src/components/Buttons/NormalButton';
import NotFound from 'src/components/Layouts/NotFound';
import React, { useCallback } from 'react';
import SVG from 'src/components/Images/SvgRenderer';
import { Accordion, AccordionDetails, AccordionSummary, CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from 'src/utils/useCypress';
import { isKey } from 'src/utils/useFunctions';
import { setConfirmModal } from 'src/store/actions/modals.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

const useStyles = createUseStyles((theme: any) => ({
  templateSelectWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100% - 8px)',
    maxWidth: '100%',
    gap: '16px',
    margin: '0 4px',
    maxHeight: '100%',
    height: '100%',
  },
  templatesWrapper: {
    overflow: 'auto',
    borderRadius: '24px',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  groupsToolbar: {
    display: "flex",
    gap: '10px',
    justifyContent: "flex-start",
    backgroundColor: theme.colors.white,
    position: 'sticky',
    top: '0',
    overflow: 'hidden',
    width: 'calc(100% - 48px)',
    minHeight: '42px',
    padding: '9px 24px',
    zIndex: '2',
    flexWrap: 'wrap',
    boxShadow: theme.shadows[2],
  },
  input: {
    width: '100%',
  },
  accordions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
    width: 'calc(100% - 48px)',
    backgroundColor: theme.colors.grey[100],
    padding: '18px 24px',
    flex: '1 1 auto',
  },
  accordionWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: theme.colors.white,
  },
  toggle: {
    width: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'unset',
    backgroundColor: theme.colors.white,
    borderRadius: '0 12px 12px 0',
  },
  radioInput: {
    '& > span': {
      padding: 'unset',
    }
  },
  templateItem: {
    width: '100%',
    backgroundColor: 'transparent',
    boxShadow: theme.shadows[2],
    overflow: 'hidden',
    '&:first-of-type': {
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    }, 
    '&:last-of-type': {
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
    },   
  },
  templateDetail: {
    display: 'flex',
    width: '100%',
    backgroundColor: theme.colors.white,
    padding: '26px 24px',
    alignItems: 'center',
    '& > .MuiAccordionSummary-content': {
      display: 'flex',
      flexDirection: 'column',
      margin: '0',
    },
    '&.nonclickable': {
      cursor: 'auto !important',
    },
    '&.clickable': {
      cursor: 'pointer !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px 12px',
    },
  },
  templateTopBar: {
    display: 'flex',
    alignItems: 'center',
  },
  templateInfo: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 90%',
  },
  templateName: {
    color: theme.colors.grey[700],
    fontWeight: '700',
    fontSize: '18px',
    wordBreak: 'break-all',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
  },
  templateContent: {
    padding: '0 16px 16px',
    backgroundColor: theme.colors.white,
    width: 'calc(100% - 32px)',
    '& > label': {
      fontSize: '16px',
      fontWeight: '700',
    },
  },
  columnsInfo: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '8px',
    '& > span': {
      fontWeight: '500',
      fontSize: '15px',
    },
    '& > p': {
      fontSize: '13px',
    },
  },
  templateCheck: {
    display: "flex",
    position: 'relative',
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
    '& p': {
      position: 'absolute',
      top: '-16px',
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: '10px',
      textTransform: 'uppercase',
      color: theme.colors.primaryBlue[500],
      opacity: '0',
      transition: 'opacity 0.25s',
      paddingRight: '16px',
    },
    '& .checked + p': {
      opacity: '1',
    }
  },
  templateCheckButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: "32px",
    height: "32px",
    backgroundColor: theme.colors.grey[125],
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: "100%",
    marginRight: '16px',
    '& svg': {
      opacity: '0',
      width: '24px',
      height: '24px',
      transition: 'opacity 0.25s',
      color: 'transparent',
    },
    '&.checked': {
      backgroundColor: theme.colors.primaryBlue[500],
      borderColor: theme.colors.primaryBlue[500],
      '& svg': {
        opacity: '1',
        color: theme.colors.white,
      },  
    },
    [theme.breakpoints.down('sm')]: {
      width: "24px",
      height: "24px",
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
  },
  templateExpander: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '10px',
    backgroundColor: theme.colors.grey[125],
    width: '32px',
    height: '32px',
    minWidth: '32px',
    minHeight: '32px',
    maxWidth: '32px',
    maxHeight: '32px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[350],
    borderRadius: '100%',
    '& > svg': {
      color: theme.colors.grey[700],
      transition: 'transform 0.25s',
      width: '24px',
      height: '24px',
    },
    '&.active': {
      '& > svg': {
        transform: 'rotate(-180deg)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      width: '24px',
      height: '24px',
      minWidth: '24px',
      minHeight: '24px',
      maxWidth: '24px',
      maxHeight: '24px',
      '& > svg': {
        width: '16px',
        height: '16px',
      },
    },    
  },
  smallButton: {
    height: '32px',
    minWidth: '0px',
    padding: '5px 12px',
    marginTop: '16px',
    '&:disabled': {
      color: theme.colors.black,
    },
  },
  footer: {
    width: '100%',
    display: 'flex',
    gap: '8px',
    justifyContent: 'space-between',
    height: '42px',
  },
  buttons: {
    width: '100%',
    display: 'flex',
    gap: '8px',
    justifyContent: 'flex-end',
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    minHeight: '50vh',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
}));

type TemplateSelectType = {
  schoolID: any;
  templateID: any;
  templateData: any;
  handleSetAllColumns: any;
  onSelect: any;
  onBack: any;
};

const TemplateSelect: React.FunctionComponent<TemplateSelectType> = ({ schoolID, templateID, templateData, handleSetAllColumns, onSelect, onBack }) => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const exportService = useAppSelector((state: any) => state.services).exportService;

  const [state, setState] = useStates({
    columns: [],
    templates: [],
    noTemplates: false,
    templateID: templateID,
    templateData: templateData,
    search: "",
    expanded: [],
  });

  const allColumns = state.columns.map((item: any) => item.columns.map((subItem: any) => { return {...subItem, groupName: item.groupName}; }).flat()).flat();

  const handleSearch = (value: any) => {
    setState("search", value);
  };

  const handleChange = () => {
    onSelect(state.templateData);
  };

  const handleBack = () => {
    onBack();
  };

  const toggleAccordion = (templateID: any) => {
    if(state.expanded.includes(templateID)) {
      setState("expanded", state.expanded.filter((item: any) => item !== templateID));
    } else {
      setState("expanded", [...state.expanded, templateID]);
    }
  };

  const handleSelectTemplate = (e: any, templateID: any) => {
    e.stopPropagation();
    e.preventDefault();
    if(state.templateID === templateID) {
      setState("templateID", null);
      setState("templateData", null);
    } else {
      const templateData = state.templates.filter((item: any) => item.templateID === templateID).length === 1 ? state.templates.find((item: any) => item.templateID === templateID) : null;
      setState("templateID", templateID);
      setState("templateData", templateData);
    }
  };

  const handleDeleteTemplate = (e: any, templateID: any) => {
    e.stopPropagation();
    e.preventDefault();
    const handleDelete = () => {
      exportService && exportService.exportReportDeleteTemplate(templateID).then((result: any) => {
        if(result && result.status === 204) {
          const templates = state.templates.filter((item: any) => item.templateID !== templateID)
          setState("templates", templates);
          createNotification(t("press_report_deleted_template"), "success");
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("press_report_failed_delete_template"), "error");
      });
    };
    const settings = {
      isOpen: true,
      title:  t('press_report_delete_template'),
      content: t('press_report_delete_template_confirm'),
      onAccept: handleDelete,
      onDecline: null,
    };
    dispatch(setConfirmModal(settings));
  };

  const loadTemplates = useCallback(() => {
    exportService && exportService.exportReportGetTemplate("children", schoolID).then((result: any) => {
      if(result && result.data && result.data.templates) {
        const templates = result.data.templates;
        if(templates.length === 0) {
          setState("noTemplates", true);
        } else {
          setState("templates", templates);
        }
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("press_report_templates_failed"), "error");
    });
  }, [exportService, schoolID, setState, t]);

  useEffect(() => {
    exportService && exportService.exportReportGetColumns("children").then((result: any) => {
      if(result && result.data && result.data.groups) {
        setState("columns", result.data.groups);
        setState("noTemplates", false);
        handleSetAllColumns(result.data.groups)
        loadTemplates();
      }
    }).catch((e: any) => {

    });
  }, [exportService, setState, loadTemplates, handleSetAllColumns], []);

  return (state.templates.length === 0 && !state.noTemplates && state.search === "") ? (
    <div className={classes.templateSelectWrapper}>
      <div className={classes.loading}>
        <CircularProgress className={classes.spinner}/>
      </div>
    </div>
  ) : (
    <div className={classes.templateSelectWrapper}>
      <div className={classes.templatesWrapper}>
        <div className={classes.groupsToolbar}>
          <Input className={classes.input} prepend={<SVG src="search"/>} type="text" placeholder={`${t('search')}...`} useName={false} onChange={handleSearch}/>
        </div>
        <div className={classes.accordions}>
          {
            state.templates.filter((item: any) => item.name.toLowerCase().includes(state.search.toLowerCase())).length === 0 ? (
              <NotFound text={t('no_templates_found')}/>
            ) : state.templates.filter((item: any) => item.name.toLowerCase().includes(state.search.toLowerCase())).map((item: any, key: any) => {
              const isExpanded = state.expanded.includes(item.templateID);
              return (
                <div className={classes.accordionWrapper}>
                  <Accordion expanded={isExpanded} className={classes.templateItem} data-cy={isCypress() ? `template${item.templateID}` : null} key={`k_${key}`}>
                    <AccordionSummary className={classes.templateDetail} onClick={() => toggleAccordion(item.templateID)}>
                      <div className={classes.templateTopBar}>
                        <span className={classes.templateCheck}>
                          <span className={`${classes.templateCheckButton} ${state.templateID === item.templateID ? 'checked' : '' }`}  onClick={(e: any) => handleSelectTemplate(e, item.templateID)} data-cy={isCypress() ? `template${item.tempalteID}Select` : null}>
                            <CheckIcon/>
                          </span>
                          <p>{t("selected")}</p>
                        </span>
                        <span className={classes.templateInfo}>
                          <span className={classes.templateName} data-cy={isCypress() ? 'templateName' : null} data-clarity-unmask="true">
                            {item.name}
                          </span>
                        </span>
                        <span className={`${classes.templateExpander} ${isExpanded ? 'active' : ''}`} data-cy={isCypress() ? 'templateExpander' : null}>
                          <SVG src="chevron-down"/>
                        </span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className={classes.templateContent} data-cy={isCypress() ? `template${item.templateID}Content` : null}>
                      <Label>{t('press_report_title')}</Label>
                      <p>
                        {item.title}
                      </p>
                      <Label>{t('press_report_columns')} ({item.columns.length})</Label>
                      <div className={classes.columnsInfo}>
                        {
                          htmlParse(Object.entries(item.columns.reduce((acc: any, item: any) => {
                            const groupName = !item.key ? t('press_report_custom_columns') : allColumns.find((subItem: any) => subItem.key === item.key)?.groupName || item.groupName;
                            const columnName = allColumns.find((subItem: any) => subItem.key === item.key)?.name || item.name || "";
                            if(!acc[groupName]) { acc[groupName] = []; }
                            acc[groupName].push(columnName);
                            return acc;
                          }, {})).map(([groupName, columnsList]: any) => {
                            const columnsStr = columnsList.join(", ");
                            return `<span>${groupName}</span><p>${columnsStr}</p>`;
                          }).join(""))
                        }
                      </div>
                      {
                        item.isEditable ? (
                          <NormalButton buttonType='clear' className={classes.smallButton} onClick={(e: any) => handleDeleteTemplate(e, item.templateID)}>
                            {t('press_report_delete_template')}
                          </NormalButton>
                        ) : null
                      }
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })
          }
        </div>
      </div>
      <div className={classes.footer}>
        <NormalButton buttonType='secondary' onClick={handleBack}>{t('back')}</NormalButton>
        {
          state.noTemplates ? (
            <NormalButton buttonType="primary" onClick={handleChange} >
              {t('press_report_create_new')}
            </NormalButton>
          ) : (
            <NormalButton buttonType='primary' onClick={handleChange} disabled={state.templateData === null}>
              {t('continue')}
            </NormalButton>
          )
        }
      </div>
    </div>
  );
};

export default TemplateSelect;