import CheckboxInput from 'src/components/Forms/CheckboxInput';
import ChildrenInput from 'src/components/Forms/ChildrenInput';
import ChildrenSelect from 'src/components/Selects/ChildrenSelect';
import CloseButton from 'src/components/Buttons/CloseButton';
import DatePicker from 'src/components/DatePickers/Calendar';
import FileInput from 'src/components/Forms/FileInput';
import IconButton from 'src/components/Buttons/IconButton';
import Input from 'src/components/Forms/Input';
import Label from 'src/components/Forms/Label';
import Modal from '../../../utils/modal';
import moment from 'src/utils/moment';
import NormalButton from '../../Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import RichTextEditor from 'src/components/Forms/RichTextEditor';
import Select from 'src/components/Forms/Select';
import SVG from 'src/components/Images/SvgRenderer';
import Switch from 'src/components/Forms/Switch';
import theme from '../../../ui/theme';
import UsersInput from 'src/components/Forms/UsersInput';
import UsersSelect from 'src/components/Selects/UsersSelect';
import { base64ToArrayBuffer, classToChild, getFileType, isKey, removeDuplicatesJSON } from 'src/utils/useFunctions';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { setCalendarEventHandleModal } from 'src/store/actions/modals.actions';
import { setCalendarEvents, setCalendarIsLoading } from 'src/store/actions/calendar.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMediaQuery } from '@mui/material';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isFullscreen: any;
  bodyHeight: any;
}

const useStyles = createUseStyles((theme: any) => ({
  root: {
    borderRadius: "10px",
    backgroundColor: theme.colors.white,
    width: "800px",
    maxWidth: '90vw',
    overflow: "hidden",
    margin: "20px",
    height: '100%',
    maxHeight: 'calc(100vh - 40px)',
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px 10px 20px",
    maxWidth: 'calc(100% - 40px)',
    '& p': {
      fontWeight: "bold",
      marginBottom: "0",
    },
  },
  buttons: {
    display: 'flex',
    gap: '10px',
    flex: '0 0 fit-content',
  },
  button: {
    padding: '10px',
    width: '40px',
    height: '40px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
    '&.smallIcon': {
      '& > svg': {
        transform: 'scale(0.8)',
      },
    },
  },
  body: {
    display: 'flex',
    justifyContent: 'center',
    height: (props: Props) => {
      if(props.bodyHeight) return props.bodyHeight + 'px';
      else return '100%';
    },
    backgroundImage: theme.colors.gradient,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    overflow: 'auto',
  },
  bodyWrapper: {
    width: (props: Props) => {
      if(props.isFullscreen) return '80%';
      else return 'calc(100% - 40px)';
    },
    padding: (props: Props) => {
      if(props.isFullscreen) return '0 0 20px 0';
      else return '0 20px 20px 20px';
    },
    height: 'calc(100% - 40px)',
    '&::after': {
      content: `''`,
      display: 'flex',
      width: '100%',
      height: '20px',
    },
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "10px 20px",
    gap: '15px',
    width: 'calc(100% - 40px)',
  },
  select: {
    width: 'unset !important',
    flex: 'unset !important',
  },
  datepickers: {
    display: 'flex',
    gap: '8px',
  },
  fullDay: {
    marginLeft: 'auto',
  },
  error: {
    color: theme.colors.systemRed[500],
    fontSize: '14px',
    display: 'flex',
    paddingTop: '8px',
    justifyContent: 'center',
  },
}));

const CalendarEventHandleModal: React.FunctionComponent = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const browserData = useAppSelector((state: any) => state.browser);
  const calendarData = useAppSelector((state: any) => state.calendar);
  const dataData = useAppSelector((state: any) => state.data);
  const modalsData = useAppSelector((state: any) => state.modals);
  const userData = useAppSelector((state: any) => state.user);
  const calendarService = useAppSelector((state: any) => state.services).calendarService;
  const smallDevice = useMediaQuery(theme.breakpoints.down('md'));

  const isEdit = modalsData.calendarEventHandleModal.eventID === null ? false : true;
  const eventID = isEdit ? modalsData.calendarEventHandleModal.eventID : null;
  const eventData = isEdit ? (calendarData.events.filter((item: any) => item.eventID === eventID).length === 1 ? calendarData.events.find((item: any) => item.eventID === eventID) : null) : null;

  const selectedDate = modalsData.calendarEventHandleModal.date;
  const selectedDateTime = modalsData.calendarEventHandleModal.dateTime;

  const modalRef: any = useRef(null);
  const headerRef: any = useRef(null);
  const footerRef: any = useRef(null);

  const getMedias = (media: any) => {
    let tempFiles: any = [];
    if(media.files) {
      media.files.forEach((file: any) => {
        const newFile = {
          ...file,
          type: 'application/pdf',
          status: "hosted",
        };
        tempFiles = [...tempFiles, newFile];
      });
    }
    if(media.photos) {
      media.photos.forEach((file: any) => {
        const newFile = {
          ...file,
          type: 'image/jpeg',
          status: "hosted",
        };
        tempFiles = [...tempFiles, newFile];
      });
    }
    if(media.videos) {
      media.videos.forEach((file: any) => {
        const newFile = {
          ...file,
          type: 'video/mp4',
          status: "hosted",
        };
        tempFiles = [...tempFiles, newFile];
      });
    }
    return tempFiles;
  };

  const curDate: any = calendarData.date;
  const viewMode = calendarData.viewMode;
  const userAccessSchools = userData.userAccessSchools;

  const filterSchoolID = calendarData.filterSchoolID;
  const filterClassID = calendarData.filterClassID;
  const filterChildID = calendarData.filterChildID;
  const filterUserID = calendarData.filterUserID;
  const filterType = calendarData.filterType;

  const listSchools = dataData.schools;
  const listClasses = dataData.classes;
  const listChildren = dataData.children;
  const listUsers = dataData.users;

  const availableSchools = listSchools.filter((item: any) => userAccessSchools.calendar.includes(item.schoolID) && listClasses.filter((subItem: any) => subItem.schoolID === item.schoolID).length > 0).map((item: any) => { return item; });

  const getSchoolData = useCallback((schoolID: any) => {
    return dataData.schools.filter((item: any) => item.schoolID === schoolID).length === 0 ? [] : dataData.schools.find((item: any) => item.schoolID === schoolID);
  }, [dataData.schools]);

  const getIsFullDay = () => {
    if(moment(eventData.dateTimeFrom).isSame(moment(eventData.dateTimeTo), "date")) {
      if(moment(eventData.dateTimeFrom).isSame(moment(eventData.dateTimeTo))) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const eventClasses = isEdit ? removeDuplicatesJSON(eventData.classToChild.map((item: any) => { return { classID: item.classID }; })) : [];
  const eventOnlyClasses = isEdit ? removeDuplicatesJSON(eventData.classToChild.filter((item: any) => !item.childID).map((item: any) => { return { classID: item.classID }; })) : [];
  const eventChildren = isEdit ? removeDuplicatesJSON(eventData.classToChild.filter((item: any) => item.childID).map((item: any) => { return { childID: item.childID, classID: item.classID }; })) : [];
  const allChildren = isEdit ? removeDuplicatesJSON([eventOnlyClasses.map((item: any) => { return listChildren.filter((dataItem: any) => dataItem.classID.indexOf(item.classID) !== -1).map((dataItem: any) => { return { classID: item.classID, childID: dataItem.childID }; }); }).flat(), eventChildren].flat().map((item: any) => { return { classID: item.classID, childID: item.childID }; })) : [];

  const [state, setState] = useStates({
    updateRefs: false,
    bodyHeight: null,
    modalIsFullscreen: smallDevice ? true : false,
    isFullDay: isEdit ? getIsFullDay() : (selectedDateTime ? false : true),
    datetimeStart: isEdit ? moment(eventData.dateTimeFrom) : (selectedDate ? moment(selectedDate).set('hour', 0).set('minute', 0).set('second', 0) : (selectedDateTime ? moment(selectedDateTime) : moment().set('hour', 0).set('minute', 0).set('second', 0))),
    datetimeEnd: isEdit ? moment(eventData.dateTimeTo) : (selectedDate ? moment(selectedDate).set('hour', 0).set('minute', 0).set('second', 0) : (selectedDateTime ? moment(selectedDateTime).add(30, 'minute') : moment().set('hour', 0).set('minute', 0).set('second', 0))),
    name: isEdit ? eventData.name : "",
    description: isEdit ? eventData.description : "",
    currentSchool: isEdit ? getSchoolData(eventData.schoolID) : availableSchools[0],
    currentChildren: isEdit ? allChildren : [],
    currentUsers: isEdit ? (eventData.userID ? eventData.userID.map((item: any) => { return { userID: item, schoolID: eventData.schoolID }}) : []) : [],
    isChildrenSelectOpen: false,
    isUsersSelectOpen: false,
    media: isEdit ? (eventData.media && eventData.media.length > 0) ? getMedias(eventData.media) : [] : [],
    isCreatePostForChildren: false,
    isLoading: false,
  });
  
  const schoolID = state.currentSchool ? state.currentSchool.schoolID : availableSchools[0].schoolID;
  const availableClasses = schoolID === null ? [] : listClasses.filter((item: any) => item.schoolID === schoolID && !item.isArchived && item.active && item.childrenID.length > 0).map((item: any) => { return item; });
  const availableChildren = schoolID === null ? [] : listChildren.filter((item: any) => item.schoolsID.indexOf(schoolID) !== -1).map((item: any) => { return { childID: item.childID, classID: item.classID }; });
  const availableUsers = schoolID === null ? [] : listUsers.filter((item: any) => item.schoolsID.includes(schoolID) && (item.roleType === 2 || item.roleType === 4) && item.userID > 0).map((item: any) => { return { userID: item.userID }; });
  const refMedia = useRef(state.media);
  const allowedFiles = ["image", "attachment"];

  const classes = useStyles({
    isFullscreen: state.modalIsFullscreen,
    bodyHeight: state.bodyHeight,
  });

  const isDisabled = () => {
    const anyUsers = state.currentUsers.length > 0;
    const anyChildren = state.currentChildren.length > 0;
    const validDate = state.isFullDay ? true : moment(state.datetimeStart).isBefore(moment(state.datetimeEnd)) && moment(state.datetimeEnd).isAfter(moment(state.datetimeStart));
    return (!anyUsers && !anyChildren) || !validDate;
  };
  
  const onCloseModal = () => {
    if(state.isLoading) return;
    const settings = {
      isOpen: false,
      date: null,
      dateTime: null,
      eventID: null,
    };
    dispatch(setCalendarEventHandleModal(settings));
  };

  const handleClose = (e: any) => {
    e.stopPropagation();
    onCloseModal();
  };

  const handleFullscreen = () => {
    setState("modalIsFullscreen", !state.modalIsFullscreen);
  };

  const handleChangeSchool = (value: any) => {
    setState("currentSchool", value);
    setState("currentChildren", []);
    setState("currentUsers", []);
    setState("isCreatePostForChildren", false);
  };

  const handleChangeChildren = (value: any) => {
    if(value.length === 0) {
      setState("isCreatePostForChildren", false);
    }
    const newValue = value.map((item: any) => { return { childID: item.childID, classID: item.classID }; });
    setState("currentChildren", newValue);
  };

  const handleChangeUsers = (value: any) => {
    setState("currentUsers", value);
  };

  const handleDatetimeStart = (date: any) => {
    setState("datetimeStart", date);
  };

  const handleDatetimeEnd = (date: any) => {
    setState("datetimeEnd", date);
  };
  
  const handleInputChange = (name: any, value: any) => {
    setState(name, value);
  };

  const handleDescription = (value: any) => {
    setState("description", value);
  };

  const handleMedias = (value: any) => {
    setState("media", value);
    refMedia.current = value;
  };

  const handleIsFullDay = () => {
    if(!state.isFullDay) {
      setState("datetimeStart", moment(state.datetimeStart).set('hour', 0).set('minute', 0).set('second', 0));
      setState("datetimeEnd", moment(state.datetimeEnd).set('hour', 0).set('minute', 0).set('second', 0));
    } else {
      setState("datetimeStart", moment(state.datetimeStart));
      if(moment(state.datetimeStart).isSame(state.datetimeEnd)) {
        setState("datetimeEnd", moment(state.datetimeStart).add(30, 'minute'));
      } else {
        setState("datetimeEnd", moment(state.datetimeEnd));
      }
    }
    setState("isFullDay", !state.isFullDay);
  };

  const handleCreatePostForChildren = () => {
    setState("isCreatePostForChildren", !state.isCreatePostForChildren);
  };

  const getDateFrom =  useCallback(() => {
    let date;
    if(viewMode.value === "day") {
      date = moment(curDate);
    } else if(viewMode.value === "week") {
      date = moment(curDate).isoWeekday(1);
    } else if(viewMode.value === "month") {
      date = moment(moment(curDate).date(1)).subtract(moment(moment(curDate).date(1)).day(), 'days').add(1, 'day');
    } else if(viewMode.value === "year") {
      date = moment(curDate).startOf("year");
    }
    return moment(date).format("YYYY-MM-DD");
  }, [curDate, viewMode]);

  const getDateTo = useCallback(() => {
    let date;
    if(viewMode.value === "day") {
      date = moment(curDate).add(1, 'day');
    } else if(viewMode.value === "week") {
      date = moment(curDate).isoWeekday(7);
    } else if(viewMode.value === "month") {
      date = moment(moment(curDate).date(1)).subtract(moment(moment(curDate).date(1)).day(), 'days').add(42, 'day');
    } else if(viewMode.value === "year") {
      date = moment(curDate).endOf("year");
    }
    return moment(date).format("YYYY-MM-DD");
  }, [curDate, viewMode]);

  const handleSave = (e: any) => {
    if(!state.isFullDay) {
      if(moment(state.datetimeStart).isSameOrAfter(moment(state.datetimeEnd)) || moment(state.datetimeEnd).isSameOrBefore(moment(state.datetimeStart))) {
        createNotification(t('calendar_event_invalid_range'), "error")
        return;
      }
    }
    if(isEdit) {
      handleEdit(e);
    } else {
      handleCreate(e);
    }
  };

  const handleCreate = (e: any) => {
    e.stopPropagation();
    setState("isLoading", true);
    dispatch(setCalendarIsLoading(true));
    const newMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((fileData: any, key: any) => {
      return {id: key, name: fileData.name, type: getFileType(fileData.type), size: fileData.size};
    });
    const payload: any = {
      name: state.name,
      description: state.description,
      dateTimeFrom: moment(state.datetimeStart).format("YYYY-MM-DD HH:mm:ss"),
      dateTimeTo: moment(state.datetimeEnd).format("YYYY-MM-DD HH:mm:ss"),
      type: "event",
      schoolID: state.currentSchool.schoolID,
      classToChild: classToChild(state.currentChildren, availableChildren),
      userID: state.currentUsers.length > 0 ? state.currentUsers.map((item: any) => { return item.userID; }) : [],
      post: state.isCreatePostForChildren,
      active: state.active,
      media: newMedias,
    };
    calendarService && calendarService.createEvent(payload).then((result: any) => {
      if(result) {
        if(result.status === 201) {
          if(result.data) {
            if(result.data.eventID) {
              if(result.data.media) {
                if(result.data.media.length > 0) {
                  const uploadMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((file: any, key: any) => {
                    return {...file, uploadUrl: result.data.media.find((theFile: any) => theFile.id === key).uploadUrl};  
                  })
                  handleUploadMedias(result.data.eventID, uploadMedias);
                } else {
                  handleFinish();
                }
              } else {
                setState("isLoading", false);
                dispatch(setCalendarIsLoading(false));
                createNotification(t("calendar_event_not_added"), "error");
                const updateFiles = refMedia.current.map((fileData: any) => {
                  if(fileData.status === "uploaded" || fileData.status === "uploading") {
                    return {...fileData, status: "ready"};
                  } else {
                    return fileData;
                  }
                });
                setState("media", updateFiles);
                refMedia.current = updateFiles;
              } 
            } else {
              setState("isLoading", false);
              dispatch(setCalendarIsLoading(false));
              createNotification(t("calendar_event_not_added"), "error");
              const updateFiles = refMedia.current.map((fileData: any) => {
                if(fileData.status === "uploaded" || fileData.status === "uploading") {
                  return {...fileData, status: "ready"};
                } else {
                  return fileData;
                }
              });
              setState("media", updateFiles);
              refMedia.current = updateFiles;
            }
          } else {
            setState("isLoading", false);
            dispatch(setCalendarIsLoading(false));
            createNotification(t("calendar_event_not_added"), "error");
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.status === "uploaded" || fileData.status === "uploading") {
                return {...fileData, status: "ready"};
              } else {
                return fileData;
              }
            });
            setState("media", updateFiles);
            refMedia.current = updateFiles;
          }
        } else {
          setState("isLoading", false);
          dispatch(setCalendarIsLoading(false));
          createNotification(t("calendar_event_not_added"), "error");
          const updateFiles = refMedia.current.map((fileData: any) => {
            if(fileData.status === "uploaded" || fileData.status === "uploading") {
              return {...fileData, status: "ready"};
            } else {
              return fileData;
            }
          });
          setState("media", updateFiles);
          refMedia.current = updateFiles;
        }
      } else {
        setState("isLoading", false);
        dispatch(setCalendarIsLoading(false));
        createNotification(t("calendar_event_not_added"), "error");
        const updateFiles = refMedia.current.map((fileData: any) => {
          if(fileData.status === "uploaded" || fileData.status === "uploading") {
            return {...fileData, status: "ready"};
          } else {
            return fileData;
          }
        });
        setState("media", updateFiles);
        refMedia.current = updateFiles;
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("calendar_event_not_added"), "error");
      setState("isLoading", false);
      dispatch(setCalendarIsLoading(false));
      const updateFiles = refMedia.current.map((fileData: any) => {
        if(fileData.status === "uploaded" || fileData.status === "uploading") {
          return {...fileData, status: "ready"};
        } else {
          return fileData;
        }
      });
      setState("media", updateFiles);
      refMedia.current = updateFiles;
    });
  };

  const handleEdit = (e: any) => {
    e.stopPropagation();
    setState("isLoading", true);
    dispatch(setCalendarIsLoading(true));
    const newMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((fileData: any, key: any) => {
      return {id: key, name: fileData.name, type: getFileType(fileData.type), size: fileData.size};
    });
    const deletedMedias = state.media.filter((fileData: any) => fileData.status === "deleted").map((fileData: any) => {
      return fileData.mediaID;
    });
    const payload: any = {
      name: state.name,
      description: state.description,
      dateTimeFrom: moment(state.datetimeStart).format("YYYY-MM-DD HH:mm:ss"),
      dateTimeTo: moment(state.datetimeEnd).format("YYYY-MM-DD HH:mm:ss"),
      type: "event",
      schoolID: state.currentSchool.schoolID,
      classToChild: classToChild(state.currentChildren, availableChildren),
      userID: state.currentUsers.length > 0 ? state.currentUsers.map((item: any) => { return item.userID; }) : [],
      active: state.active,
      media: newMedias,
      deletedMediasID: deletedMedias,
    };
    calendarService && calendarService.editEvent(eventID, payload).then((result: any) => {
      if(result) {
        if(result.status === 201) {
          if(result.data) {
            if(result.data.eventID) {
              if(result.data.media) {
                if(result.data.media.length > 0) {
                  const uploadMedias = state.media.filter((fileData: any) => fileData.status === "ready").map((file: any, key: any) => {
                    return {...file, uploadUrl: result.data.media.find((theFile: any) => theFile.id === key).uploadUrl};  
                  });
                  handleUploadMedias(eventID, uploadMedias);
                } else {
                  handleFinish();
                }
              } else {
                setState("isLoading", false);
                dispatch(setCalendarIsLoading(false));
                createNotification(t("calendar_event_not_updated"), "error");
                const updateFiles = refMedia.current.map((fileData: any) => {
                  if(fileData.status === "uploaded" || fileData.status === "uploading") {
                    return {...fileData, status: "ready"};
                  } else {
                    return fileData;
                  }
                });
                setState("media", updateFiles);
                refMedia.current = updateFiles;
              }
            } else {
              setState("isLoading", false);
              dispatch(setCalendarIsLoading(false));
              createNotification(t("calendar_event_not_updated"), "error");
              const updateFiles = refMedia.current.map((fileData: any) => {
                if(fileData.status === "uploaded" || fileData.status === "uploading") {
                  return {...fileData, status: "ready"};
                } else {
                  return fileData;
                }
              });
              setState("media", updateFiles);
              refMedia.current = updateFiles;
            }
          } else {
            setState("isLoading", false);
            dispatch(setCalendarIsLoading(false));
            createNotification(t("calendar_event_not_updated"), "error");
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.status === "uploaded" || fileData.status === "uploading") {
                return {...fileData, status: "ready"};
              } else {
                return fileData;
              }
            });
            setState("media", updateFiles);
            refMedia.current = updateFiles;
          }
        } else {
          setState("isLoading", false);
          dispatch(setCalendarIsLoading(false));
          createNotification(t("calendar_event_not_updated"), "error");
          const updateFiles = refMedia.current.map((fileData: any) => {
            if(fileData.status === "uploaded" || fileData.status === "uploading") {
              return {...fileData, status: "ready"};
            } else {
              return fileData;
            }
          });
          setState("media", updateFiles);
          refMedia.current = updateFiles;
        }
      } else {
        setState("isLoading", false);
        dispatch(setCalendarIsLoading(false));
        createNotification(t("calendar_event_not_updated"), "error");
        const updateFiles = refMedia.current.map((fileData: any) => {
          if(fileData.status === "uploaded" || fileData.status === "uploading") {
            return {...fileData, status: "ready"};
          } else {
            return fileData;
          }
        });
        setState("media", updateFiles);
        refMedia.current = updateFiles;
      }
    }).catch((e: any) => {
      createNotification(!isKey(e.response.data.message) ? e.response.data.message : t("calendar_event_not_updated"), "error");
      setState("isLoading", false);
      dispatch(setCalendarIsLoading(false));
      const updateFiles = refMedia.current.map((fileData: any) => {
        if(fileData.status === "uploaded" || fileData.status === "uploading") {
          return {...fileData, status: "ready"};
        } else {
          return fileData;
        }
      });
      setState("media", updateFiles);
      refMedia.current = updateFiles;
    });
  };

  const handleUploadMedias = (newEventID: any, sentFiles: any) => {
    const file = sentFiles[0];
    const newFiles = sentFiles.filter((fileData: any) => fileData.uid !== file.uid);
    const updateFiles = refMedia.current.map((fileData: any) => {
      if(fileData.uid === file.uid) {
        return {...fileData, status: "uploading"};
      } else {
        return fileData;
      }
    });
    setState("media", updateFiles);
    refMedia.current = updateFiles;
    const reader = new FileReader();
    reader.onload = function() {
      const dataUrl: any = reader.result;
      const base64 = dataUrl.split(',')[1];
      const arrayBuffer = base64ToArrayBuffer(base64);
      calendarService && calendarService.uploadFile(file.uploadUrl, arrayBuffer).then((result: any) => {
        if(result) {
          if(result.status === 201) {
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.uid === file.uid) {
                return {...fileData, status: "uploaded"};
              } else {
                return fileData;
              }
            });
            setState("media", updateFiles);
            refMedia.current = updateFiles;
            if(newFiles.length === 0) {
              handleFinish(); 
            } else {
              handleUploadMedias(newEventID, newFiles);  
            }
          } else {
            setState("isLoading", false);
            dispatch(setCalendarIsLoading(false));
            createNotification(isEdit ? t("calendar_event_not_updated") : t("calendar_event_not_added"), "error");
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.status === "uploaded" || fileData.status === "uploading") {
                return {...fileData, status: "ready"};
              } else {
                return fileData;
              }
            });
            setState("media", updateFiles);
            refMedia.current = updateFiles;
          }
        } else {
          setState("isLoading", false);
          dispatch(setCalendarIsLoading(false));
          createNotification(isEdit ? t("calendar_event_not_updated") : t("calendar_event_not_added"), "error");
          const updateFiles = refMedia.current.map((fileData: any) => {
            if(fileData.status === "uploaded" || fileData.status === "uploading") {
              return {...fileData, status: "ready"};
            } else {
              return fileData;
            }
          });
          setState("media", updateFiles);
          refMedia.current = updateFiles;
        }
      }).catch((e: any) => {
        createNotification(!isKey(e.response.data.message) ? e.response.data.message : (isEdit ? t('calendar_event_not_updated') : t("calendar_event_not_added")), "error");
      });
    };
    reader.readAsDataURL(file.blob);
  };

  const handleFinish = () => {
    const payload = {
      dateFrom: getDateFrom(),
      dateTo: getDateTo(),
      schoolID: filterSchoolID.length !== 0 ? filterSchoolID.map((item: any) => { return item.schoolID }).join(",") : "",
      classID: filterClassID.length !== 0 ? filterClassID.map((item: any) => { return item.classID }).join(",") : "",
      childID: filterChildID.length !== 0 ? filterChildID.map((item: any) => { return item.childID }).join(",") : "",
      userID: filterUserID.length !== 0 ? filterUserID.map((item: any) => { return item.userID }).join(",") : "",
      type: filterType.length !== 0 ? filterType.map((item: any) => { return item.value }).join(",") : "",
    };
    calendarService && calendarService.listCalendar(payload).then((result: any) => {
      if(result) {
        if(result.data) {
          if(result.data.events) {
            if(isEdit) {
              createNotification(t("calendar_event_updated"), "success");
            } else {
              createNotification(t("calendar_event_added"), "success");
            }
            onCloseModal();
            dispatch(setCalendarEvents(result.data.events));
            dispatch(setCalendarIsLoading(false));
          } else {
            setState("isLoading", false);
            dispatch(setCalendarIsLoading(false));
            createNotification(isEdit ? t("calendar_event_not_updated") : t("calendar_event_not_added"), "error");
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.status === "uploaded" || fileData.status === "uploading") {
                return {...fileData, status: "ready"};
              } else {
                return fileData;
              }
            });
            setState("media", updateFiles);
            refMedia.current = updateFiles;
          }
        } else {
            setState("isLoading", false);
            dispatch(setCalendarIsLoading(false));
            createNotification(isEdit ? t("calendar_event_not_updated") : t("calendar_event_not_added"), "error");
            const updateFiles = refMedia.current.map((fileData: any) => {
              if(fileData.status === "uploaded" || fileData.status === "uploading") {
                return {...fileData, status: "ready"};
              } else {
                return fileData;
              }
            });
            setState("media", updateFiles);
            refMedia.current = updateFiles;
          }
        } else {
          setState("isLoading", false);
          dispatch(setCalendarIsLoading(false));
          createNotification(isEdit ? t("calendar_event_not_updated") : t("calendar_event_not_added"), "error");
          const updateFiles = refMedia.current.map((fileData: any) => {
            if(fileData.status === "uploaded" || fileData.status === "uploading") {
              return {...fileData, status: "ready"};
            } else {
              return fileData;
            }
          });
          setState("media", updateFiles);
          refMedia.current = updateFiles;
        }
    });
  }; 

  useEffect(() => {
    if(modalRef.current && headerRef.current && footerRef.current) {
      const modalHeight = state.modalIsFullscreen ? browserData.height : modalRef.current.clientHeight;   
      const headerHeight = headerRef.current.clientHeight; 
      const footerHeight = footerRef.current.clientHeight; 
      const bodyHeight = modalHeight - headerHeight - footerHeight; 
      setState("bodyHeight", bodyHeight);
    } else {
      if(!state.updateRefs) {
        setState("updateRefs", true);
      }
    }
    if(state.updateRefs) {
      setState("updateRefs", false);
    }
  }, [state.modalIsFullscreen, browserData.height, state.updateRefs, setState], [state.modalIsFullscreen, browserData.height, state.updateRefs]);

  useEffect(() => {
    setState("modalIsFullscreen", smallDevice ? true : false);
  }, [browserData.height, smallDevice, setState], [browserData.height]);

  return (
    <Modal 
      open={true}
      onClose={onCloseModal}
      disableEscapeKeyDown={true}
      isFullscreen={state.modalIsFullscreen}
    >
      <div className={classes.root} data-cy={isCypress() ? "calendarEventHandleModal" : null} ref={modalRef}>
        <div className={classes.header} ref={headerRef}>
          <div className={classes.wrapper}>
            <p>{isEdit ? t('calendar_edit_event') : t('calendar_add_event')}</p>
          </div>
          <div className={classes.buttons}>
            {
              !smallDevice ? (
                <IconButton className={`${classes.button} ${'smallIcon'}`} onClick={handleFullscreen} data-cy={isCypress() ? "fullscreenButton" : null} tooltip={state.modalIsFullscreen ? t('mode_window') : t('mode_fullscreen')} tooltipPosition='bottom'>
                  {state.modalIsFullscreen ? (<SVG src="fullscreen-exit"/>) : (<SVG src="fullscreen-enter"/>)}
                </IconButton>
              ) : null
            }
            <CloseButton onClick={handleClose} dataCy="timesButton" disabled={state.isLoading}/> 
          </div>
        </div>
        <div className={classes.body}>
          <div className={classes.bodyWrapper}>
            <Input label={t('calendar_event_name') + "*"} placeholder={t('calendar_event_name')} name="name" value={state.name} onChange={handleInputChange} disabled={state.isLoading} autoFocus={true}/>
            <div>
              <Label>
                {t('date') + "*"}
                <Switch className={classes.fullDay} checked={state.isFullDay} onChange={handleIsFullDay} label={t('calendar_event_whole_day')} disabled={state.isLoading}/>
              </Label>
              <div className={classes.datepickers}>
                {
                  state.isFullDay ? (
                    <>
                      	<DatePicker presetDate={state.datetimeStart} presetDateRange={{start: state.datetimeStart, end: state.datetimeEnd}} time={false} start setDate={handleDatetimeStart} disabled={state.isLoading}/>
                        <DatePicker presetDate={state.datetimeEnd} presetDateRange={{start: state.datetimeStart, end: state.datetimeEnd}} time={false} end setDate={handleDatetimeEnd} disabled={state.isLoading}/>
                    </>
                  ) : (
                    <>
                      <DatePicker presetDate={state.datetimeStart} presetDateRange={{start: state.datetimeStart, end: state.datetimeEnd}} time={true} start setDate={handleDatetimeStart} disabled={state.isLoading}/>
                      <DatePicker presetDate={state.datetimeEnd} presetDateRange={{start: state.datetimeStart, end: state.datetimeEnd}} time={true} end setDate={handleDatetimeEnd} disabled={state.isLoading}/>
                    </>
                  )
                }
              </div>
              {
                ((moment(state.datetimeStart).isSameOrAfter(moment(state.datetimeEnd)) || moment(state.datetimeEnd).isSameOrBefore(moment(state.datetimeStart))) && !state.isFullDay) ? (
                  <span className={classes.error}>
                    {t('calendar_invalid_timerange')}
                  </span>
                ) : null
              }
            </div>
            <RichTextEditor uid="calendarEventContent" label={t('calendar_event_description')} value={state.description} onChange={handleDescription} disabled={state.isLoading}/>
            <Select label={t('school') + "*"} className={classes.select} inputLabel={t("school")} items={availableSchools} selected={state.currentSchool ? state.currentSchool : availableSchools[0]} setSelected={handleChangeSchool} width={200} allowClear={false} disabled={state.isLoading || listSchools.length === 1}/>
            <ChildrenInput label={t('children')} selectLabel={t('children')} className={classes.select} selectedChildren={state.currentChildren.length > 0 ? state.currentChildren : []} setSelectedChildren={handleChangeChildren} isDeletable={false} onClick={() => setState("isChildrenSelectOpen", true)} disabled={state.isLoading}/>
            {
              state.isChildrenSelectOpen ? (
                <ChildrenSelect
                  selectedChildren={state.currentChildren.map((item: any) => { return { childID: item.childID, classID: item.classID }; })}
                  customSort={eventClasses.map((item: any, key: any) => { return { classID: item.classID, customSortOrder: key }; })}
                  isInModal={true}
                  isModalOpen={true}
                  defaultClasses={availableClasses}
                  defaultChildren={availableChildren}
                  modalTitle="children"
                  mode="select"
                  modalAllowCancel={false}
                  modalAllowClose={true}
                  modalAllowClear={true}
                  isSelectAll={true}
                  isSelectInAllClass={false}
                  isMultipleSelect={true}
                  isAllowArchived={false}
                  isAllowInactiveClasses={false}
                  isAllowArchivedToggle={false}
                  modalOnClose={() => setState("isChildrenSelectOpen", false)}
                  modalOnSave={handleChangeChildren}
                  modalAllowChildrenCount={true}
                />
              ) : null
            }
            <UsersInput label={t('teachers')} selectLabel={t('teachers')} className={classes.select} selectedUsers={state.currentUsers} setSelectedUsers={handleChangeUsers} isDeletable={false} onClick={() => setState("isUsersSelectOpen", true)} disabled={state.isLoading}/>
            {
              state.isUsersSelectOpen ? (
                <UsersSelect
                  selectedUsers={state.currentUsers}
                  isInModal={true}
                  isModalOpen={true}
                  defaultSchools={[{schoolID: schoolID}]}
                  defaultUsers={availableUsers}
                  modalTitle="teachers"
                  mode="select"
                  modalAllowClose={true}
                  modalAllowClear={true}
                  modalAllowCancel={false}
                  modalAllowNoUser={true}
                  isSelectAll={true}
                  isMultipleSelect={true}
                  modalOnClose={() => setState("isUsersSelectOpen", false)}
                  modalOnSave={handleChangeUsers}
                  modalAllowUsersCount={true}
                />
              ) : null
            }
            <FileInput label={t('attachments')} files={state.media} onChange={handleMedias} allowed={allowedFiles} disabled={state.isLoading} mediaLimit='calendar'/>
            {
              !isEdit ? (
                <CheckboxInput label={t('calendar_create_post_for_children')} checked={state.isCreatePostForChildren} onChange={handleCreatePostForChildren} disabled={state.isLoading || state.currentChildren.length === 0}/>
              ) : null
            }
          </div>
        </div>
        <div className={classes.footer} ref={footerRef}>
          <NormalButton buttonType="secondary" onClick={handleClose} dataCy="cancelButton" disabled={state.isLoading}>
            {t("cancel")}
          </NormalButton>
          <NormalButton buttonType="primary" onClick={handleSave} dataCy="saveButton" disabled={state.isLoading || state.name === "" || isDisabled()}>
            {isEdit ? t("save") : t("create")}
          </NormalButton>
        </div>
      </div>
    </Modal>
  );
};

export default CalendarEventHandleModal;
