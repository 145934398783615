import ImageRenderer from 'src/components/Images/ImageRenderer';
import React from 'react';

type AuthenticatedImageType = {
  thumbLink?: any,
  fullsizeLink?: any,
  preferQuality?: 'thumb' | 'fullsize',
  isResponsive?: boolean,
  className?: any,
  isClickable?: boolean,
  useThumb?: boolean,
  useLoader?: boolean,
  onClick?: any,
  onLoad?: any,
  style?: any,
  customAccessToken?: any;
  dataCy?: any;
  ignoreInView?: boolean;
  ignoreIsLoaded?: boolean;
};

const AuthenticatedImage: React.FunctionComponent<AuthenticatedImageType> = ({ thumbLink, fullsizeLink, preferQuality, isResponsive, className, isClickable, useThumb, useLoader, onClick, onLoad, style, customAccessToken, dataCy, ignoreInView, ignoreIsLoaded }) => {
  
  return (
    <ImageRenderer
      thumbLink={thumbLink}
      fullsizeLink={fullsizeLink}
      preferQuality={preferQuality}
      isResponsive={isResponsive}
      className={`${className} ${isClickable && 'pointer'}`}
      onClick={onClick}
      useLoader={useLoader}
      isClickable={isClickable}
      useThumb={useThumb}
      onLoad={onLoad}
      style={style}
      customAccessToken={customAccessToken}
      dataCy={dataCy}
      ignoreInView={ignoreInView}
      ignoreIsLoaded={ignoreIsLoaded}
    />
  );
}

export default AuthenticatedImage;
