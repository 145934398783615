import Button from '@mui/material/Button';
import NormalButton from '../NormalButton';
import Popover from '@mui/material/Popover';
import React from 'react';
import SVG from '../../Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { isCypress } from '../../../utils/useCypress';
import { useState } from 'src/utils/useState';

interface Props {
  position: any;
};

const useStyles = createUseStyles((theme: any) => ({
  root: {
    display: 'flex',
    gap: '5px',
  },
  startButton: {
    backgroundColor: 'rgb(225, 241, 255)',
    color: theme.colors.primaryBlue[500],
    padding: "5px 16px",
    borderRadius: "10px 0 0 10px",
    fontSize: '14px',
    minWidth: '30px',
    whiteSpace: 'nowrap',
    textTransform: 'unset',
    '& > svg': {
      width: '20px',
      height: '20px',
      marginRight: '5px',
    },
  },
  endButton: {
    backgroundColor: 'rgb(225, 241, 255)',
    color: theme.colors.primaryBlue[500],
    padding: "5px 16px",
    borderRadius: "0 10px 10px 0",
    minWidth: '30px',
    textTransform: 'unset',
    '& > svg': {
      width: '20px',
      height: '20px',
      transform: 'rotate(0deg)',
      transition: 'transform 0.25s',
    },
    '&.active': {
      '& > svg': {
        transform: 'rotate(-180deg)', 
      },
    },
  },
  dropdownMenu: {
    '& .MuiPopover-paper': {
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      gap: '10px',
      padding: '8px',
      marginTop: (props: Props) => {
        if(props.position === "bottom") return '16px';
        else return '-16px';
      },
    },
  },
  listButton: {
    boxShadow: "0px 4px 5px rgba(0,0,0,0.1)",
    '& span': {
      '& > svg': {
        width: '24px',
        height: '24px',
      },
    },
  },
}));

type DropdownWithButtonType = {
  className?: any;
  items: any;
  disabled?: boolean;
  position?: 'top' | 'bottom';
  dataCy?: string;
};

const DropdownWithButton: React.FunctionComponent<DropdownWithButtonType> = ({ className, items, disabled = false, position = "bottom", dataCy }) => {

  const classes = useStyles({
    position: position,
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (e: any) => {
    if(!disabled) {
      setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = (e: any) => {
    if(!disabled) {
      setAnchorEl(null);
    }
  };

  return (
    <div className={`${classes.root} ${className}`}>
      <Button type="button" className={classes.startButton} onClick={items[0].onClick} tabIndex={-1} disabled={items[0].disabled} data-cy={isCypress() ? dataCy + "Start" : null}>
        {items[0].icon}
        {items[0].title}  
      </Button>
      <Button type="button" className={`${classes.endButton} ${Boolean(anchorEl) ? 'active' : null}`} onClick={handleClick} tabIndex={-1} data-cy={isCypress() ? dataCy + "Expand" : null}>
        <SVG src={position === "bottom" ? "chevron-down" : "chevron-up"}/> 
      </Button>
      <Popover className={classes.dropdownMenu} open={disabled ? false : Boolean(anchorEl)} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{vertical: position === "bottom" ? 'bottom' : 'top', horizontal: 'right'}} transformOrigin={{vertical: position === "bottom" ? 'top' : 'bottom', horizontal: 'right'}}>
        {
          items.filter((item: any) => item !== null).map((item: any, key: any) => key !== 0 ? (
            <NormalButton className={classes.listButton} type="button" buttonType="white" startIcon={item.icon} disabled={item.disabled} onClick={item.onClick} key={`k_${key}`} tabIndex={-1} dataCy={dataCy + key}>
              {item.title}  
            </NormalButton>
          ) : null)
        }
      </Popover>
    </div>
  );
};

export default DropdownWithButton;