import CircularProgress from '@mui/material/CircularProgress';
import EndOfScroll from 'src/components/Layouts/EndOfScroll';
import moment from '../../../utils/moment';
import PremiumBanner from '../../../components/Layouts/PremiumBanner';
import React, { useCallback, useRef } from 'react';
import RenderGroup from './components/RenderGroup';
import theme from '../../../ui/theme';
import { arrayBufferToBase64, getFiltersKeys, getFiltersKeysArray } from 'src/utils/useFunctions';
import { clearGalleryMedias, setGalleryMedias, setGallerySelected, setGalleryZipCurrentIndex, setGalleryZipState } from '../../../store/actions/gallery.actions';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getUserSetting, saveUserSettings } from 'src/utils/useUser';
import { resetFilterParams, setFilterParams, setFiltersSettings, setIsFilterParamsLoaded, setIsFilterSettingsLoaded } from '../../../store/actions/filters.actions';
import { saveAs } from 'file-saver';
import { setIsFiltersVisible } from 'src/store/actions/layout.actions';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useMediaQuery } from '@mui/material';
import { useStates } from '../../../utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  membershipActive: any;
};

const useStyles = createUseStyles((theme: any) => ({
  galleryPage: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 48px)',
    maxWidth: 'calc(100% - 48px)',
    overflow: (props: Props) => {
      if(!props.membershipActive) return 'hidden';
      else return 'auto';
    },
    padding: '24px 24px',
    flex: '0 0 auto',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '24px 0px',
    },
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    minHeight: '100vh',
    position: 'relative',
  },
  wrapperColumn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  galleryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    gap: '20px',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 240px',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
      display: 'none',
    },
  },
  spinnerAbsolute: {
    position: "absolute",
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    zIndex: '0',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  spinner: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: '16px',
    paddingBottom: '200px',
    '& > svg': {
      color: theme.colors.primaryBlue[500],
    },
  },
  notFound: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '36px',
      fontWeight: 'bold',
    },
    '& > p': {
      marginTop: '24px',
      color: theme.colors.grey[650],
      fontSize: '16px',
      marginBottom: '0',
    },
  },
  viewScreen: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
    zIndex: '-1',
  },
  infoBar: {
    position: 'fixed',
    backgroundColor: theme.colors.primaryBlue[100],
    color: theme.colors.black,
    bottom: '30px',
    left: '50%',
    maxWidth: '90%',
    whiteSpace: 'nowrap',
    transform: 'translateX(-50%)',
    borderRadius: '12px',
    padding: '8px 8px',
    boxShadow: 'rgb(0 0 0 / 8%) 0px 3px 20px',
    zIndex: theme.zIndex.infoBar,
    [theme.breakpoints.down('lg')]: {
      bottom: '60px',
    },
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const filtersData = useAppSelector((state: any) => state.filters);
  const galleryData = useAppSelector((state: any) => state.gallery);
  const userData = useAppSelector((state: any) => state.user); 
  const galleryService = useAppSelector((state: any) => state.services).galleryService;
  const galleryScroll: any = useRef(null);
  const viewScreen: any = useRef(null);
  const windowHandler: any = window;

  const allMedias = galleryData.medias;
  const zipState = galleryData.zipState;
  const zipSelected = galleryData.zipSelected;
  const zipCurrentIndex = galleryData.zipCurrentIndex;

  const downloadFilesData: any = useRef([]);
  const downloadFileIndex: any = useRef(1);

  const isLoading = useRef(false);

  const [state, setState, setStates] = useStates({
    isFirstTime: true,
    isLoadingDefault: true,
    isLoadingMore : false,
    isEndOfScroll: false,
    page: 1,
    lastFilterParams: {},
  });

  const cardSize = {
    xs: 142,
    sm: 142,
    md: 142,
    lg: 232,
    xl: 367,
  };

  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const size = isXl ? cardSize.xl : isLg ? cardSize.lg : isMd ? cardSize.md : isSm ? cardSize.sm : isXs ? cardSize.xs : 100;

  const classes = useStyles({
    membershipActive: userData.membership.active,
  });

  useEffect(() => {
    windowHandler.isGalleryReady = !state.isLoading;
  }, [windowHandler, state.isLoading], [state.isLoading]);

  const saveFilters = useCallback(async (filterParam: any) => {
    if(getUserSetting(userData.userSettings, "addons", ["gallery", "gallery_filters_save"])) {
      const filtersParams = getFiltersKeysArray(filterParam, {'type': 'galleryTypes', 'favorite': 'galleryFavorite', 'hide': 'galleryHide'});
      await saveUserSettings(dispatch, userData, "filters", ["gallery"], filtersParams);
    }
  }, [dispatch, userData]);
  
  const loadMedias = useCallback((defaultMedia?: boolean) => {
    if(state.isLoadingMore) return;
    if(state.isEndOfScroll && !defaultMedia) return;
    if(defaultMedia) {
      setState("isLoadingDefault", true);
    }
    let currentPage = state.page;
    const oldMedias = defaultMedia ? [] : allMedias;
    if(defaultMedia) {
      currentPage = 1;
      setState("isEndOfScroll", false);
    } else {              
      setStates({
        isEndOfScroll: false,
        isLoadingMore: true,
      });
      currentPage++;
    } 
    if(currentPage !== state.page || currentPage === 1) {
      const getColumns = (viewScreen.current === null || viewScreen.current === undefined) ? 5 : Math.floor((viewScreen.current.clientWidth - 56) / (size + 16));
      const getRows = (viewScreen.current === null || viewScreen.current === undefined) ? 5 : (Math.floor((viewScreen.current.clientHeight - 44) / (size + 30)) + 2);      
      const limit = (viewScreen.current === null || viewScreen.current === undefined) ? 50 : (getColumns * getRows);
      const filtersParams = getFiltersKeys(filtersData.filterParams, {'galleryTypeID': 'type', 'galleryFavorite': 'favorite', 'galleryHide': 'hide'});
      const getFiltersParams = {...filtersParams, page: currentPage, limit: limit};
      if(JSON.stringify(state.lastFilterParams) === JSON.stringify(getFiltersParams)) return;
      isLoading.current = true;
      setState("lastFilterParams", getFiltersParams);
      galleryService && galleryService.listMedias(getFiltersParams).then((result: any) => {
        if(result && result.data && result.data.media) {
          const resultMedias = result.data.media;
          if(resultMedias.length === 0) {
            setStates({
              isEndOfScroll: true,
              isLoadingMore: false,
            });
            setTimeout(() => { 
              setState("isLoadingDefault", false);
              isLoading.current = false;
            }, 1);           
          } else {
            const dates: any = Array.from(new Set(result.data.media.map((media: any) => {
              return moment(media.post.created).format("YYYY-MM");
            })));
            const allDates = Object.values(oldMedias).map((item: any) => { return item.date});
            let mediaData = oldMedias;
            dates.forEach((date: any) => {
              const formattedDate = moment(date).format("YYYY-MM");
              if(allDates.indexOf(formattedDate) === -1) {
                const newMedia = {
                  date: formattedDate,
                  medias: resultMedias.filter((media: any) => moment(media.post.created).format("YYYY-MM") === formattedDate)
                };
                mediaData = [...mediaData, newMedia];
              } else {
                mediaData = mediaData.map((media: any) => {
                  if (moment(media.date).format("YYYY-MM") === formattedDate) {
                    const newMedias = resultMedias.filter((item: any) => moment(item.post.created).format("YYYY-MM") === formattedDate);
                    return { ...media, medias: media.medias.concat(newMedias) };
                  } else {
                    return media;
                  }
                });
              }
            });
            dispatch(setGalleryMedias(mediaData));
            setState("page", currentPage);
            setTimeout(() => {  
              setStates({
                isLoadingDefault: false,
                isLoadingMore: false,
              });
              isLoading.current = false;
            }, 1); 
          }
        }
      });
    }
  }, [dispatch, filtersData.filterParams, allMedias, galleryService, setState, setStates, size, state.isEndOfScroll, state.isLoadingMore, state.lastFilterParams, state.page]);
  
  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && isLoading.current === false) {
      loadMedias(true);
    }
  }, [filtersData.filterParams, dispatch, loadMedias, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams, filtersData.isFilterParamsLoaded]);

  const onScroll = useCallback(() => {
    if(galleryScroll.current) {
      const { scrollTop, scrollHeight, clientHeight }: any = galleryScroll.current;
      if(scrollTop + clientHeight > scrollHeight - 100) {
        if((!state.isLoadingMore || !state.isLoading) && !state.isEndOfScroll) {
          //galleryScroll.current.scrollTo({ top: scrollTop - 400});
          loadMedias(false);
        }
      }
    }
  }, [loadMedias, state.isLoadingMore, state.isLoading, state.isEndOfScroll]);

  const handleZip = useCallback(() => {
    if(downloadFilesData.current.length > 0) {
      const zip = require('jszip')();
      downloadFilesData.current.forEach((item: any) => {
        zip.file(item.name, item.base64, {base64: true});
      });
      zip.generateAsync({type:"blob"}).then((content: any) => {
        createNotification(t('gallery_download_zip_success'), 'success');
        setTimeout(() => {
          dispatch(setGalleryZipCurrentIndex(1));
          dispatch(setGalleryZipState("stop"));
          downloadFilesData.current = [];
          downloadFileIndex.current = 1;
          saveAs(content, `twigsee-download-${moment().format("YYYYMMDDHHmmSSS")}.zip`);
        }, 1000);
      });
    } else {
      setTimeout(() => {
        createNotification(t('gallery_download_zip_failed'), 'error');
        dispatch(setGalleryZipCurrentIndex(1));
        dispatch(setGalleryZipState("stop"));
        downloadFilesData.current = [];
        downloadFileIndex.current = 1;
      }, 1000);
    }
  }, [dispatch, t]);

  const handleDownload = useCallback((files: any) => {
    const medias = allMedias.map((item: any) => item.medias.flat()).flat();
    const index = downloadFileIndex.current;
    const isFileExist = medias.filter((media: any) => media.mediaID === files[index - 1]).length === 1;
    downloadFileIndex.current = index + 1;
    dispatch(setGalleryZipCurrentIndex(index + 1));
    if(isFileExist) {
      const file = medias.find((media: any) => media.mediaID === files[index - 1]);
      galleryService && galleryService.downloadFile(file.mediaID).then((result: any) => {
        if(result) {
          if(result.data) {
            const base64 = arrayBufferToBase64(result.data);
            const fileData = {
              base64: base64,
              name: file.name,
            };
            downloadFilesData.current = [...downloadFilesData.current, fileData];
            if(index === files.length) {
              handleZip();
            } else {
              handleDownload(files);
            }
          } else {
            if(index === files.length) {
              handleZip();
            } else {
              handleDownload(files);
            }
          }
        } else {
          if(index === files.length) {
            handleZip();
          } else {
            handleDownload(files);
          }
        }
      }).catch(() => {
        if(index === files.length) {
          handleZip();
        } else {
          handleDownload(files);
        }
      });
    } else {
      if(index === files.length) {
        handleZip();
      } else {
        handleDownload(files);
      }
    }
  }, [galleryService, allMedias, handleZip, dispatch]);
  
  useEffect(() => {
    if(zipState === "start") {
      dispatch(setGalleryZipState("running"));
      handleDownload(zipSelected);
    }
  }, [zipState, zipSelected, dispatch, handleDownload], [zipState]);

  useEffect(() => {
    if(filtersData.isFilterSetupLoaded && !filtersData.isFilterSettingsLoaded && !filtersData.isFilterParamsLoaded) {
      const settings = {
        isAllowedPostID: false,
        isAllowedPaymentID: false,
        isAllowedPostType: false,
        isAllowedGalleryType: true,
        isAllowedActivityType: true,
        isAllowedChildID: true,
        isAllowedEmployeeID: false,
        isAllowedDate: true,
        isAllowedAuthors: false,
        isAllowedPolls: false,
        isAllowedClasses: false,
        isAllowedSchools: false,
        isAllowedTimelineFavorite: false,
        isAllowedGalleryFavorite: true,
        isAllowedGalleryHide: true,
        isAllowedPaymentMethod: false,
        isAllowedPaymentStatus: false,
        isAllowedPaymentType: false,
        isAllowedCurrencyID: false,
        isAllowedArchived: false,
      };
      dispatch(setFiltersSettings(settings));
      setTimeout(() => {
        dispatch(setIsFilterSettingsLoaded(true));
      }, 100);
    } 
  }, [dispatch, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded, filtersData.isFilterParamsLoaded]);

  useEffect(() => {
    if(!filtersData.isFilterParamsLoaded && filtersData.isFilterSettingsLoaded) {
      if(getUserSetting(userData.userSettings, "addons", ["gallery", "gallery_filters_save"])) {
        const customFilters = getUserSetting(userData.userSettings, "filters", ["gallery"]);
        if(Array.isArray(customFilters) && customFilters.length > 0) {
          customFilters.forEach((item: any) => {
            dispatch(setFilterParams({[item.key]: item.value}));
          });
          if(getUserSetting(userData.userSettings, "addons", ["app", "app_filter_autoopening"])) {
            if(!userData.membership.active) return;
            dispatch(setIsFiltersVisible(true));
          }
        } else {
          dispatch(setFilterParams({}));
        }
      } else {
        dispatch(setFilterParams({galleryTypeID: ["photo", "video"]}));
      }
      dispatch(setIsFilterParamsLoaded(true));
    }
  }, [dispatch, userData.userSettings, filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded, saveFilters, userData.membership.active], [filtersData.isFilterParamsLoaded, filtersData.isFilterSettingsLoaded]);

  useEffect(() => {
    dispatch(clearGalleryMedias());
    dispatch(setGallerySelected([]));
    dispatch(setIsFilterParamsLoaded(false));
    dispatch(setIsFilterSettingsLoaded(false));
    return () => {
      dispatch(clearGalleryMedias());
      dispatch(setGallerySelected([]));
      dispatch(setIsFilterParamsLoaded(false));
      dispatch(setIsFilterSettingsLoaded(false));
      dispatch(resetFilterParams());
    }
  }, [dispatch], []);

  useEffect(() => {
    if(filtersData.isFilterParamsLoaded && filtersData.isFilterSetupLoaded && filtersData.isFilterSettingsLoaded && !state.isFirstTime) {
      saveFilters(filtersData.filterParams);
    } else {
      setState("isFirstTime", false);
    }
  }, [saveFilters, setState, state.isFirstTime, filtersData.filterParams, filtersData.isFilterParamsLoaded, filtersData.isFilterSetupLoaded, filtersData.isFilterSettingsLoaded], [filtersData.filterParams]);

  return (
    <>
      {
        !userData.membership.active ? (
          <PremiumBanner service="gallery" useBackdrop={allMedias.length !== 0}/>
        ) : null
      }
      <div className={classes.galleryPage} onScroll={onScroll} ref={galleryScroll}>
        <div className={classes.wrapper}>
          <div className={classes.wrapperColumn}>
            <div className={classes.galleryWrapper}>
              <div className={classes.viewScreen} ref={viewScreen}/>
              {
                zipState === "running" ? (
                  <div className={classes.infoBar}>
                    {t('gallery_download_zip_prepare', { percentage: Math.floor(((zipCurrentIndex - 1) / zipSelected.length) * 100)})}
                  </div>
                ) : null 
              }
              {
                ((isLoading.current  && allMedias.length === 0) || (state.isLoadingDefault && allMedias.length === 0)) ? (
                  <div className={classes.spinnerAbsolute}>
                    <CircularProgress/>
                  </div>
                ) : (
                  <>
                  {
                    allMedias.length === 0 ? (
                      <div className={classes.notFound}>
                        <img src="/resources/images/noresults.png" alt={t('no_results') || ''}/>
                        <span>{t('no_media')}</span>
                        <p>{t('no_results_found')}</p>
                      </div>
                    ) : (
                      <>
                      {
                        allMedias.map((date: any, key: any) => (
                          <RenderGroup date={date.date} key={`k_${key}`}/>
                        ))
                      }
                      {
                        isLoading.current ? (
                          <div className={classes.spinner}>
                            <CircularProgress/>
                          </div>
                        ) : null
                      }
                      {
                        state.isEndOfScroll ? ( 
                          <EndOfScroll text={t("no_more_medias")}/>
                        ) : null
                      }
                      </>
                    )
                  }
                  </>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageTemplate;