import EmojiPicker from 'src/components/Layouts/EmojiPicker';
import React, { useCallback, useRef } from 'react';
import SVG from '../../../components/Images/SvgRenderer';
import { Button } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { getElementID } from '../../../utils/useUUID';
import { Input as InputUnstyled } from '@mui/base/Input';
import { isCypress } from '../../../utils/useCypress';
import { useEffect } from 'src/utils/useEffect';
import { useState, useStates } from 'src/utils/useState';

interface Props {
  type: any;
  append: any;
  prepend: any;
  width: any;
  baseWidth: any;
};

const useStyles = createUseStyles((theme: any) => ({
  inputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: (props: any) => {
      if(Number.isInteger(props.width)) return props.width + 'px';
      else return props.width;
    }, 
    '& > label': {
      display: 'flex',
      color: theme.colors.black,
      fontSize: '14px',
      marginTop: '16px',
      marginBottom: '7px',
      fontWeight: 'bold',
      width: '100%',
    },
  },
  inputBase: {
    display: 'flex',
    flexDirection: 'row',
    width: (props: any) => {
      if(Number.isInteger(props.baseWidth)) return props.baseWidth + 'px';
      else return props.baseWidth;
    }, 
    minHeight: '36px',
    fontSize: '14px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.grey[325],
    borderRadius: '10px',
    boxSizing: 'border-box',
    color: theme.colors.black,
    backgroundColor: theme.colors.white,
    height: '100%',
    '& > button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '0 0 auto',
      backgroundColor: 'transparent',
      color: '#5A5C7B',
      borderWidth: '0',
      borderRadius: '0 10px 10px 0',
      padding: '0px 10px',
      transition: 'all 0.5s',
      minWidth: 'unset',
      outline: 'none',
      '&:hover': {
        backgroundColor: theme.colors.grey[125],
        color: theme.colors.primaryBlue[500],
      },
      '& > svg': {
        color: 'inherit',
        width: '24px',
        height: '24px',
      },
      '&:disabled': {
        color: theme.colors.grey[560],
        backgroundColor: theme.colors.grey[75],
      },
    },
  },
  input: {
    width: 'calc(100% - 20px)',
    flex: '1 1 auto',
    padding: '7.5px 10px',
    borderWidth: '0',
    lineHeight: '21px',
    borderRadius: (props: Props) => {
      if(props.type === "password") return '10px 0px 0px 10px';
      else if(props.prepend && props.append) return '10px 0px 0px 10px';
      else if(props.append) return '10px 0px 0px 10px';
      else if(props.prepend) return '0px 10px 10px 0px';
      else return "inherit";
    },
    backgroundColor: theme.colors.white,
    color: 'inherit',
    fontSize: 'inherit',
    height: '100%',
    outline: 'none',
    boxSizing: 'inherit',
    '&:disabled': {
      color: theme.colors.grey[560],
      backgroundColor: theme.colors.grey[75],
    },
    '&:read-only': {
      color: theme.colors.grey[560],
      backgroundColor: theme.colors.grey[150],
    },
  },
  counter: {
    display: 'flex',
    color: theme.colors.grey[550],
    fontSize: '12px',
    width: 'calc(100% - 8px)',
    justifyContent: 'right',
    marginTop: '4px',
    paddingRight: '8px',
  },
  errorMessage: {
    display: 'flex',
    color: theme.colors.systemRed[500],
    fontSize: '12px',
    width: 'calc(100% - 8px)',
    justifyContent: 'right',
    marginTop: '4px',
    paddingRight: '8px',
  },
  blockPrepend: {
    display: 'flex',
    backgroundColor: theme.colors.grey[200],
    color: theme.colors.black,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px',
    borderRadius: '10px 0px 0px 10px',
    '& > svg': {
      width: '24px',
      height: '24px',
      color: theme.colors.grey[700],
    },
  },
  blockAppend: {
    display: 'flex',
    backgroundColor: theme.colors.grey[200],
    color: theme.colors.black,
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 8px',
    borderRadius: '0px 10px 10px 0px',
    '& > svg': {
      width: '24px',
      height: '24px',
      color: theme.colors.grey[700],
    },
  }, 
  emojiButton: {
    position: 'relative',
    cursor: 'pointer',
    fontFamily: `'Noto Color Emoji', sans-serif`,
    userSelect: 'none',
    '-moz-user-select': 'none',
    '-webkit-user-drag': 'none',
    '-webkit-user-select': 'none',
    '-ms-user-select': 'none',
  },
}));

type InputType = {
  id?: any;
  placeholder?: any;
  label?: any;
  name?: any;
  value?: any;
  type?: any;
  useName?: boolean;
  useForm?: boolean;
  onClick?: any;
  onKeyDown?: any;
  onKeyUp?: any;
  onInput?: any;
  onChange?: any;
  onFocus?: any;
  onBlur?: any;
  onInputEnd?: any;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  tabIndex?: any;
  autoFocus?: boolean;
  autoComplete?: boolean;
  width?: any;
  baseWidth?: any;
  min?: any;
  max?: any;
  step?: any;
  autoRows?: boolean;
  minRows?: any;
  maxRows?: any;
  maxLength?: number;
  inputDelay?: number;
  inputNoDelayOnEmpty?: boolean;
  showCounter?: boolean;
  prepend?: any;
  append?: any;
  readOnly?: boolean;
  required?: boolean;
  errorMessage?: any;
  className?: any;
  classNamePrepend?: any;
  classNameAppend?: any;
  customRef?: any;
  customRefInput?: any;
  dataCy?: any;
  dataClarityMask?: 'true' | 'false';
  dataClarityUnmask?: 'true' | 'false';
};

const Input: React.FunctionComponent<InputType> = ({ id, placeholder, label, name, value, type, useName = true, useForm = true, onClick, onKeyDown, onKeyUp, onInput, onChange, onFocus, onBlur, onInputEnd, disabled, multiline, rows, tabIndex, autoFocus, autoComplete, width = '100%', baseWidth = '100%', min, max, step, maxLength, inputDelay = 3000, inputNoDelayOnEmpty, autoRows, minRows = 1, maxRows = 4, showCounter, prepend, append, readOnly, required, errorMessage, className, classNamePrepend, classNameAppend, customRef, customRefInput, dataCy, dataClarityMask, dataClarityUnmask }) => {

  const defaultInputRef = useRef(null);
  const inputID = id ? id : getElementID();
  const inputRef = customRefInput ? customRefInput : defaultInputRef;

  const [state, setState] = useStates({
    isVisible: false,
    inputValue: value ? value : '',
    emojiPicker: false,
  });

  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const handleClick = (e: any) => {
    if(onClick) {
      if(useName) {
        onClick(name, e.target.value, e, inputRef);
      } else {
        onClick(e.target.value, e, inputRef);
      }
    }
  };

  const handleKeyDown = (e: any) => {
    setState("inputValue", e.target.value);
    if(onKeyDown) {
      if(useName) {
        onKeyDown(name, e.target.value, e, inputRef);
      } else {
        onKeyDown(e.target.value, e, inputRef);
      }
    }
  };

  const handleKeyUp = (e: any) => {
    setState("inputValue", e.target.value);
    if(onKeyUp) {
      if(useName) {
        onKeyUp(name, e.target.value, e, inputRef);
      } else {
        onKeyUp(e.target.value, e, inputRef);
      }
    }
  };

  const handleInput = (e: any) => {
    setState("inputValue", e.target.value);
    if(autoRows) {
      setTimeout(() => {
        updateRowsBasedOnContent();
      }, 0);
    }
    if(onInput) {
      if(useName) {
        onInput(name, e.target.value, e);
      } else {
        onInput(e.target.value, e);
      }
    }
    if(debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    const debouncetimeout = setTimeout(() => {
      if(onInputEnd) {
        if(useName) {
          onInputEnd(name, e.target.value, e);
        } else {
          onInputEnd(e.target.value, e);
        }
      }
    }, inputNoDelayOnEmpty ? (e.target.value.length === 0 ? 1 : inputDelay) : inputDelay);
    setDebounceTimeout(debouncetimeout);
  };

  const handleChange = (e: any) => {
    setState("inputValue", e.target.value );
    if(onChange) {
      if(useName) {
        onChange(name, e.target.value, e, inputRef);
      } else {
        onChange(e.target.value, e, inputRef);
      }
    }
  };

  const handleFocus = (e: any) => {
    if(onFocus) {
      if(useName) {
        onFocus(name, e.target.value, e, inputRef);
      } else {
        onFocus(e.target.value, e, inputRef);
      }
    }
  };

  const handleBlur = (e: any) => {
    if(onBlur) {
      if(useName) {
        onBlur(name, e.target.value, e, inputRef);
      } else {
        onBlur(e.target.value, e, inputRef);
      }
    }
  };

  const handleOnSubmit = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    return;
  };

  const classes = useStyles({
    type: type,
    append: append,
    prepend: prepend,
    width: width,
    baseWidth: baseWidth,
  });

  const handleVisible = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setState("isVisible", !state.isVisible);
  };

  const handleFocusInput = () => {
    if(inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleEmojiPicker = () => {
    setState("emojiPicker", !state.emojiPicker);
  };

  const handleOnEmojiClose = () => {
    setState("emojiPicker", false);
  };

  const handleOnEmojiClick = (emojiData: any) => {
    const value = inputRef.current.value;
    const newValue = value + emojiData.emoji
    inputRef.current.value = newValue;
    setState("inputValue", newValue);
  };

  const getPlaceholderDiv = useCallback(() => {
    const textarea = inputRef.current;
    let tempDiv: any = document.createElement("div");
    tempDiv.style.font = (window && textarea) ? window.getComputedStyle(textarea).font : 0;
    tempDiv.style.width = (textarea ? textarea.clientWidth - 20 : 0) + "px";
    tempDiv.style.maxWidth = (textarea ? textarea.clientWidth - 20 : 0) + "px";
    tempDiv.style.padding = textarea ? textarea.style.padding : 0;
    tempDiv.style.visibility = "hidden";
    tempDiv.style.position = "absolute";
    tempDiv.style.whiteSpace = "break-spaces";
    tempDiv.style.wordBreak = "break-all";
    tempDiv.style.top = 0;
    tempDiv.style.left = -9999;
    tempDiv.textContent = textarea ? textarea.value.replaceAll("\n", "\nn") : "";
    document.body.appendChild(tempDiv);
    const contentHeight = tempDiv.scrollHeight;
    document.body.removeChild(tempDiv);
    return contentHeight;
  }, [inputRef]);

  const updateRowsBasedOnContent = useCallback(() => {
    const lineHeight: any = (window && inputRef && inputRef.current) ? parseInt(window.getComputedStyle(inputRef.current).lineHeight) : 0;
    const currentRows = getPlaceholderDiv() / lineHeight;
    const finalRowCount = currentRows < minRows ? minRows : (currentRows < maxRows ? currentRows : maxRows);
    if(inputRef && inputRef.current) {
      inputRef.current.rows = finalRowCount;
    }
  }, [getPlaceholderDiv, inputRef, maxRows, minRows]);

  useEffect(() => {
    if(!disabled && autoFocus) {
      if(inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [disabled, autoFocus, inputRef]);

  const slotProps = {
    root: {
      className: classes.inputBase
    },
    input: {
      className: classes.input,
      onClick: handleClick,
      onKeyDown: handleKeyDown,
      onKeyUp: handleKeyUp,
      onInput: handleInput,
      onChange: handleChange,
      onFocus: handleFocus,
      onBlur: handleBlur,
      'data-cy': isCypress() ? dataCy : null,
      'data-clarity-mask': dataClarityMask,
      'data-clarity-unmask': dataClarityUnmask,
      min: min,
      max: max,
      step: step,
      rows: rows,
      maxLength: maxLength,
      tabIndex: tabIndex,
      autoComplete: autoComplete ? "on" : "off",
      ref: inputRef,
    },
  };

  useEffect(() => {
    if(value !== undefined) {
      if(inputRef && inputRef.current) {
        inputRef.current.value = value;
      }
    }
    if(autoRows) {
      setTimeout(() => {
        updateRowsBasedOnContent();
      }, 1);
    }
  }, [value, inputRef, autoRows, updateRowsBasedOnContent]);

  const emojiContent = (
    <span className={classes.emojiButton} onClick={handleEmojiPicker}>
      😊
      <EmojiPicker isOpen={state.emojiPicker} setIsClose={handleOnEmojiClose} onEmojiClick={handleOnEmojiClick} />
    </span>
  );

  const inputContent = (
    <>
      {label ? (
        <label htmlFor={inputID}>
          {label}
        </label>
      ) : null}
      <InputUnstyled
        id={inputID}
        type={type === "password" ? (state.isVisible ? "text" : "password") : type}
        placeholder={placeholder}
        name={name}
        defaultValue={value}
        disabled={disabled}
        autoFocus={autoFocus}
        slotProps={slotProps}
        readOnly={readOnly}
        required={required}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        startAdornment={prepend ? <span className={`${classes.blockPrepend} ${classNamePrepend ? classNamePrepend : ''}`}>{prepend === 'emoji' ? emojiContent : prepend}</span> : null}
        endAdornment={type === "password" ? (
          <Button type="button" tabIndex={parseInt(tabIndex) + 1} disabled={disabled} onClick={handleVisible}>
            {state.isVisible ? (<SVG src="eye" />) : (<SVG src="eye-slash" />)}
          </Button>
        ) : (
          append ? <span className={`${classes.blockAppend} ${classNameAppend ? classNameAppend : ''}`}>{append}</span> : null
        )}
        ref={customRef}
      />
      {(maxLength && showCounter) ? (
        <span className={classes.counter}>{state.inputValue.length}/{maxLength}</span>
      ) : null}
      {errorMessage ? (
        <span className={classes.errorMessage}>{errorMessage}</span>
      ) : null}
    </>
  );

  return useForm ? (
    <form className={`${classes.inputWrapper} ${className ? className : null}`} onClick={handleFocusInput} onSubmit={handleOnSubmit} autoComplete={autoComplete ? "on" : "off"}>
      {inputContent}
    </form>
  ) : (
    <div className={`${classes.inputWrapper} ${className ? className : null}`} onClick={handleFocusInput}>
      {inputContent}
    </div>
  );
};

export default Input;